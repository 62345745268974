import React from "react";
import CancelModalPopup from "./modal-popup/cancelModalPopup";
import IndividualProductModalPopup from "./modal-popup/individualProductModalPopup";
import AllCategoryModalPopup from "./modal-popup/allCategoryModalPopup";
import AllBrandModalPopup from "./modal-popup/allBrandModalPopup";
import ZoneListModalPopup from "./modal-popup/zoneListModalPopup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as constants from '../constants';
import CounterInput from "react-counter-input";
import ApiServices from '../services/promotion-service';
import Alert from "./common/alert";

export default class AddRule extends React.Component {

    constructor(props) {
        super(props);        
        this.state = {
            showAlert: false,
            showModalCancel: false,
            ifTheCustomerSelectValue: "",
            noConditionFlag: false,
            reachesAnOrderSubTotalHTMLFlag: "",
			reachesAnOrderSubTotalFlag: false,
            reachesAnOrderSubTotalValue: 0,
            buysProductsFlag: false,
            ifCustomerConditionHTML: "",
            ifCustomerConditionQuantityFlag: false,
            ifCustomerConditionQuantityValue: 1,
            ifCustomerConditionTotalFlag: false,
            ifCustomerConditionTotalValue: 0,
            ifCustomerConditionIncludingProductFlag: false,
            ifTheCustomerIncludingProductsChangeValue:"",      
            individualProductSearchBoxFlag: false,
            allCategorySearchBoxFlag: false,
            allBrandSearchBoxFlag: false,
            popupOpenIndividualProductFlag: false,
            popupOpenAllCategory: false,
            popupOpenAllBrand: false,
            individualProductSelectedLabels:"",
            individualProductSelectedIds:[],
            allCategorySelectedLabels:"",
            allCategorySelectedIds:[],
            allBrandSelectedLabels:"",
            allBrandSelectedIds:[],
            addAnotherInclutionRuleBtnFlag: true,
            addAnotherInclutionRuleSelectFlag: false,
            allBrandSearchBoxAnotherInclutionFlag: false,
            allCategorySearchBoxAnotherInclutionFlag: false,
            allBrandAnotherInclutionSelectedIds: [],
            allBrandAnotherInclutionSelectedLabels: "",
            allCategoryAnotherInclutionSelectedIds: [],
            allCategoryAnotherInclutionSelectedLabels: "",
            popupOpenAllCategoryAnotherInclution: false,
            popupOpenAllBrandAnotherInclution: false,
            addExclutionRuleBtnFlag: false,
            ifTheCustomerIncludingProductsExcludeChangeValue: "",
            addExclutionRuleSelectFlag: false,
            excludingIndividualProductSearchBoxFlag: false,
            excludingAllCategorySearchBoxFlag: false,
            excludingAllBrandSearchBoxFlag: false,
            popupOpenIndividualProductExcludeFlag: false,
            popupOpenAllCategoryExcludeFlag: false,
            popupOpenAllBrandExcludeFlag: false,
            individualProductSelectedExcludeLabels:"",
            individualProductSelectedExcludeIds:[],
            allCategorySelectedExcludeLabels:"",
            allCategorySelectedExcludeIds:[],
            allBrandSelectedExcludeLabels:"",
            allBrandSelectedExcludeIds:[],
            addAnotherExclutionRuleBtnFlag: true,
            addAnotherExclutionRuleSelectFlag: false,
            allBrandSearchBoxAnotherExclutionFlag: false,
            allCategorySearchBoxAnotherExclutionFlag: false,
            popupOpenAllCategoryAnotherExclutionFlag: false,
            allCategoryAnotherExclutionSelectedLabels: "",
            allCategoryAnotherExclutionSelectedIds: [],
            popupOpenAllBrandAnotherExclutionFlag: false,
            allBrandAnotherExclutionSelectedLabels: "",
            allBrandAnotherExclutionSelectedIds: [],
            thenRewardSelectValue: "",
            rewardGiftInTheCartFlag: false,
            rewardGiftInTheCartOnceUnlimited: "",
            rewardGiftCartQuantity: 1,
            rewardFreeShippingFlag: false,
            rewardDiscountOnProductsFlag: false,
            rewardDiscountOnProductOnceOrNTimeSelectVal: "",
            rewardDiscountOnOrderSubtotalFlag: false,
            rewardFixedPriceForOfProductFlag: false,
            rewardFixedPriceForOfProductSelectVal: "",
            rewardFixedPriceProductsByVal: 0,
            rewardIndividualProductGiftInCartSelectedLabels: "",
            rewardIndividualProductGiftInCartSelectedIds: 0,
            popupOpenIndividualProductGiftInCartRewardFlag: false,
            rewardFreeShippingAllZoneFlag: false,
            rewardFreeShippingSelectedZoneFlag: false,
            rewardFreeShippingZoneSelectedIds: [],
            rewardFreeShippingZoneSelectedLabels: "",
            popupOpenFreeShippingZoneRewardFlag: false,
            rewardDiscountOnOrderSubtotalOnceUnlimited:"",
            rewardDiscountOnOrderSubtotalByValPersData: "value",
            rewardDiscountOnOrderSubtotalVal: 0,
            rewardDiscountOnOrderSubtotalPer: 0,
            rewardDiscountOnProductsSelectData: "amount",
            rewardDiscountOnProductsByVal: 0,
            rewardDiscountOnProductsByPer: 0,
            rewardDiscountOnProductsAppliedOnSelectData: "all",
            rewardDisOnProdQuntCount: 1,
            rewardIncludingProductVal: "",
            rewardIndividualProductSearchBoxFlag: false,
            rewardAllCategorySearchBoxFlag: false,
            rewardAllBrandSearchBoxFlag: false,
            popupOpenAllBrandRewardFlag: false,
            allBrandSelectedRewardLabels: "",
            allBrandSelectedRewardIds: [],
            popupOpenAllCategoryRewardFlag: false,
            allCategorySelectedRewardLabels: "",
            allCategorySelectedRewardIds: [],
            popupOpenIndividualProductRewardFlag: false,
            individualProductSelectedRewardLabels: "",
            individualProductSelectedRewardIds: [],
            addAnotherInclutionRuleRewardBtnFlag: true,
            addAnotherInclutionRuleRewardSelectFlag: false,
            allCategorySearchBoxAnotherInclutionRewardFlag: false,
            allBrandSearchBoxAnotherInclutionRewardFlag: false,
            allCategoryAnotherInclutionRewardSelectedLabels: "",
            allCategoryAnotherInclutionRewardSelectedIds: [],
            allBrandAnotherInclutionRewardSelectedLabels: "",            
            allBrandAnotherInclutionRewardSelectedIds: [],
            rewardAddExclutionRuleBtnFlag: false,
            rewardAddExclutionRuleSelectFlag: false,
            rewardIfTheCustomerExcludingProductVal: "",
            rewardExcludingIndividualProductSearchBoxFlag: false,
            rewardExcludingAllCategorySearchBoxFlag: false,
            rewardExcludingAllBrandSearchBoxFlag: false,
            rewardPopupOpenIndividualProductExcludeFlag: false,
            rewardPopupOpenAllCategoryExcludeFlag: false,
            rewardPopupOpenAllBrandExcludeFlag: false,
            rewardIndividualProductSelectedExcludeLabels:"",
            rewardIndividualProductSelectedExcludeIds:[],
            rewardAllCategorySelectedExcludeLabels:"",
            rewardAllCategorySelectedExcludeIds:[],
            rewardAllBrandSelectedExcludeLabels:"",
            rewardAllBrandSelectedExcludeIds:[],
            rewardAddAnotherExclutionRuleBtnFlag: true,
            rewardAddAnotherExclutionRuleSelectFlag: false,
            rewardIfTheCustomerExcludingBrandVal: "",
            rewardAllBrandSearchBoxAnotherExclutionFlag: false,
            rewardAllCategorySearchBoxAnotherExclutionFlag: false,
            rewardPopupOpenAllCategoryAnotherExclutionFlag: false,
            rewardAllCategoryAnotherExclutionSelectedLabels: "",
            rewardAllCategoryAnotherExclutionSelectedIds: [],
            rewardPopupOpenAllBrandAnotherExclutionFlag: false,
            rewardAllBrandAnotherExclutionSelectedLabels: "",
            rewardAllBrandAnotherExclutionSelectedIds: [],
            rewardIncludeItemsConsideredByCondition: false,
            rewardExcludeItemsOnSale: true,
            rewardAsTotal: false,
            rewardFixedPriceProductsIncProduct: true,
            rewardFixedProdQuntCount: 1,
            rewardFixedProdStrategy: "LEAST_EXPENSIVE",
            errorActionFlag: false,  
            errorConditionFlag: false, 
            errorReachesAnOrderSubTotalValue: false,
            errorConditionQuantityTotalFlag: false,
            errorIfCustomerConditionQuantityFlag: false,
            errorIfCustomerConditionTotalFlag: false,
            errorIndividualProductSelectedFlag: false,
            errorIndividualProductSelectedBoxFlag: false,
            errorIndividualProductSelectedExcludeFlag: false,
            errorIndividualProductSelectedExcludeBoxFlag: false,
            errorAllCategorySelectedBoxFlag: false,
            errorAllBrandSelectedBoxFlag: false,
            errorAddAnotherInclutionRuleCategorySelectFlag: false,
            errorAddAnotherInclutionRuleBrandSelectFlag: false,
            errorAllCategoryAnotherSelectedBoxFlag: false,
            errorAllBrandAnotherSelectedBoxFlag: false,
            errorRewardGiftCartQuantityFlag: false,
            errorRewardIndividualProductGiftInCartFlag: false,
            errorRewardFreeShippingZoneFlag: false,
            errorRewardFreeShippingSelectedZoneFlag: false,
            errorRewardDiscountOnOrderSubtotalValPer: false,
            errorRewardDiscountOnProductsSelectAmountPers: false,
            errorRewardDisOnProdQuntCount: false,
            errorRewardFixedPriceProductsByVal: false,
            errorRewardFixedProdQuntCount: false,
            errorRewardIncludingProductValFlag: false,
            errorAllCategorySelectedExcludeBoxFlag: false,
            errorAllBrandSelectedExcludeBoxFlag: false,
            errorAllCategorySelectedExcludeFlag: false,
            errorAllBrandSelectedExcludeFlag: false,
            errorAllBrandAnotherExclutionSelectedFlag: false,
            errorAllCategoryAnotherExclutionSelectedFlag: false,
            errorIndividualProductSelectedRewardFlag: false,
            errorAllCategorySelectedRewardFlag: false,
            errorAllBrandSelectedRewardFlag: false,
            errorAllCategoryAnotherInclutionRewardSelectedFlag: false,
            errorAllBrandAnotherInclutionRewardSelectedFlag: false,
            errorRewardIndividualProductSelectedExcludeFlag: false,
            errorRewardAllBrandSelectedExcludeFlag: false,
            errorRewardAllCategorySelectedExcludeFlag: false,
            errorRewardAllBrandAnotherExclutionSelectedFlag: false,
            errorRewardAllCategoryAnotherExclutionSelectedFlag: false,
            errorRewardIfTheCustomerExcludingProductFlag: false,
        }
    }


    /*
    * add exclution button click
    */
    handleAddExclutionRuleClick = (event) => {
        event.preventDefault();
        this.setState({addExclutionRuleSelectFlag: !this.state.addExclutionRuleSelectFlag})
        this.setState({addExclutionRuleBtnFlag: !this.state.addExclutionRuleBtnFlag})
    }

    //alert message close
    closeAlert = () => {
        this.setState({ showAlert: false, alertMessage: "", type: "" });
    };

    /*
    * exclution change event
    */
    handleIfTheCustomerExcludingProductsChange = (event) => {
        this.setState({ifTheCustomerIncludingProductsExcludeChangeValue : event.target.value});

        this.setState({excludingIndividualProductSearchBoxFlag: false});
        this.setState({excludingAllCategorySearchBoxFlag: false});
        this.setState({excludingAllBrandSearchBoxFlag: false});
        this.setState({addAnotherExclutionRuleBtnFlag: true})
        this.setState({addAnotherExclutionRuleSelectFlag: false})

        switch(event.target.value) {
            case "individual_products" : {
                this.setState({excludingIndividualProductSearchBoxFlag: true});
                break
            }
            case "all_category" : {
                this.setState({excludingAllCategorySearchBoxFlag: true});
                break;
            }
            case "in_brand" : {
                this.setState({excludingAllBrandSearchBoxFlag: true});
                break;
            }
            case "" :
            default : {
                break;
            }
        }
    }
    
    /*
    * modals popup for exclution category or brand or individual product
    */
    modalPopupOpenIndividualProductExclude = () => {
        this.setState({popupOpenIndividualProductExcludeFlag: true});
    }
    modalPopupOpenAllCategoryExclude = () => {
        this.setState({popupOpenAllCategoryExcludeFlag: true});
    }
    modalPopupOpenAllBrandExclude = () => {
        this.setState({popupOpenAllBrandExcludeFlag: true});
    }

    /*
    * modals popup close for exclude category or brand or individual product
    */
    modalPopupCloseIndividualProductExclude = () => {
        this.setState({popupOpenIndividualProductExcludeFlag: false});
    }
    modalPopupCloseAllCategoryExclude = () => {
        this.setState({popupOpenAllCategoryExcludeFlag: false});
    }    
    modalPopupCloseAllBrandExclude = () => {
        this.setState({popupOpenAllBrandExcludeFlag: false});
    }

    /*
    * modals popup close and data set for exclution of category or brand or individual product
    */
    modalPopupApplyIndividualProductExclude = (ids, labels) => {     
        this.setState({popupOpenIndividualProductExcludeFlag: false});
        this.setState({individualProductSelectedExcludeLabels:labels});
        this.setState({individualProductSelectedExcludeIds:ids});
    }
    modalPopupApplyAllCategoryExclude = (ids, labels) => {
        this.setState({popupOpenAllCategoryExcludeFlag: false});
        this.setState({allCategorySelectedExcludeLabels:labels});
        this.setState({allCategorySelectedExcludeIds:ids});
    }
    modalPopupApplyAllBrandExclude = (ids, labels) => {
        this.setState({popupOpenAllBrandExcludeFlag: false});
        this.setState({allBrandSelectedExcludeLabels:labels});
        this.setState({allBrandSelectedExcludeIds:ids});
    }


    /*
    * another exclution remove click
    */
    handleAddExclutionRuleRemoveClick = (event) => {
        event.preventDefault();
        this.setState({addExclutionRuleBtnFlag: !this.state.addExclutionRuleBtnFlag})
        this.setState({addExclutionRuleSelectFlag: !this.state.addExclutionRuleSelectFlag})
        this.setState({excludingIndividualProductSearchBoxFlag: false})
        this.setState({excludingAllCategorySearchBoxFlag: false})
        this.setState({excludingAllBrandSearchBoxFlag: false})
        this.setState({addAnotherExclutionRuleBtnFlag: true})
        this.setState({addAnotherExclutionRuleSelectFlag: false})
    }

    /*
    * another exclution add button click and show select box
    */
    handleAddAnotherExclutionRuleBtnClick = (event) => {
        event.preventDefault();
        this.setState({addAnotherExclutionRuleBtnFlag: false})
        this.setState({addAnotherExclutionRuleSelectFlag: true})
    }

    /*
    * another exclution of category or brand change event on select box
    */
    handleAddAnotherExclutionCategoryChange = (event) => {
        event.preventDefault();
        this.setState({allBrandSearchBoxAnotherExclutionFlag: !this.state.allBrandSearchBoxAnotherExclutionFlag});
    }
    handleAddAnotherExclutionBrandChange = (event) => {
        event.preventDefault();
        this.setState({allCategorySearchBoxAnotherExclutionFlag: !this.state.allCategorySearchBoxAnotherExclutionFlag});
    }

    /*
    * another exclution remove click
    */
    handleAddAnotherExclutionRuleRemoveClick = (event) => {
        event.preventDefault();
        this.setState({addAnotherExclutionRuleBtnFlag: !this.state.addAnotherExclutionRuleBtnFlag})
        this.setState({addAnotherExclutionRuleSelectFlag: !this.state.addAnotherExclutionRuleSelectFlag})
        this.setState({allBrandSearchBoxAnotherExclutionFlag: false})
        this.setState({allCategorySearchBoxAnotherExclutionFlag: false})
    }

    /*
    * modals popup for another exclution of category or brand
    */
    modalPopupOpenAllCategoryAnotherExclution = () => {
        this.setState({popupOpenAllCategoryAnotherExclutionFlag: true});
    }
    modalPopupOpenAllBrandAnotherExclution = () => {
        this.setState({popupOpenAllBrandAnotherExclutionFlag: true});
    }

    /*
    * modals popup close and data set for another inclution of category or brand
    */
    modalPopupApplyAllCategoryAnotherExclution = (ids, labels) => {
        this.setState({popupOpenAllCategoryAnotherExclutionFlag: false});
        this.setState({allCategoryAnotherExclutionSelectedLabels:labels});
        this.setState({allCategoryAnotherExclutionSelectedIds:ids});
    }
    modalPopupApplyAllBrandAnotherExclution = (ids, labels) => {
        this.setState({popupOpenAllBrandAnotherExclutionFlag: false});
        this.setState({allBrandAnotherExclutionSelectedLabels:labels});
        this.setState({allBrandAnotherExclutionSelectedIds:ids});
    }
    /*
    * modals popup close for another exclution of category or brand
    */
    modalPopupCloseAllCategoryAnotherExclution = () => {
        this.setState({popupOpenAllCategoryAnotherExclutionFlag: false});
    }    
    modalPopupCloseAllBrandAnotherExclution = () => {
        this.setState({popupOpenAllBrandAnotherExclutionFlag: false});
    }


    /*
    * another inclution of category or brand change event on select box
    */
    handleAddAnotherInclutionCategoryChange = (event) => {
        event.preventDefault();
        this.setState({errorAddAnotherInclutionRuleCategorySelectFlag: false});
        this.setState({allBrandSearchBoxAnotherInclutionFlag: !this.state.allBrandSearchBoxAnotherInclutionFlag});
    }
    handleAddAnotherInclutionBrandChange = (event) => {
        event.preventDefault();
        this.setState({errorAddAnotherInclutionRuleBrandSelectFlag: false});
        this.setState({allCategorySearchBoxAnotherInclutionFlag: !this.state.allCategorySearchBoxAnotherInclutionFlag});
    }


    /*
    * another inclution add button click and show select box
    */
    handleAddAnotherInclutionRuleBtnClick = (event) => {
        event.preventDefault();
        this.setState({addAnotherInclutionRuleSelectFlag: !this.state.addAnotherInclutionRuleSelectFlag})
        this.setState({addAnotherInclutionRuleBtnFlag: !this.state.addAnotherInclutionRuleBtnFlag})
    }


    /*
    * another inclution remove click
    */
    handleAddAnotherInclutionRuleRemoveClick = (event) => {
        event.preventDefault();
        this.setState({addAnotherInclutionRuleBtnFlag: !this.state.addAnotherInclutionRuleBtnFlag})
        this.setState({addAnotherInclutionRuleSelectFlag: !this.state.addAnotherInclutionRuleSelectFlag})
        this.setState({allBrandSearchBoxAnotherInclutionFlag: false})
        this.setState({allCategorySearchBoxAnotherInclutionFlag: false})
    }


    /*
    * modals popup for another inclution of category or brand
    */
    modalPopupOpenAllCategoryAnotherInclution = () => {
        this.setState({popupOpenAllCategoryAnotherInclution: true});
    }
    modalPopupOpenAllBrandAnotherInclution = () => {
        this.setState({popupOpenAllBrandAnotherInclution: true});
    }


    /*
    * modals popup close for another inclution of category or brand
    */
    modalPopupCloseAllCategoryAnotherInclution = () => {
        this.setState({popupOpenAllCategoryAnotherInclution: false});
    }    
    modalPopupCloseAllBrandAnotherInclution = () => {
        this.setState({popupOpenAllBrandAnotherInclution: false});
    }


    /*
    * modals popup close and data set for another inclution of category or brand
    */
    modalPopupApplyAllCategoryAnotherInclution = (ids, labels) => {
        this.setState({popupOpenAllCategoryAnotherInclution: false});
        this.setState({allCategoryAnotherInclutionSelectedLabels:labels});
        this.setState({allCategoryAnotherInclutionSelectedIds:ids});
    }
    modalPopupApplyAllBrandAnotherInclution = (ids, labels) => {
        this.setState({popupOpenAllBrandAnotherInclution: false});
        this.setState({allBrandAnotherInclutionSelectedLabels:labels});
        this.setState({allBrandAnotherInclutionSelectedIds:ids});
    }


    /*
    * simple first list of change event on select for individual product or brand or category
    */
    handleIfTheCustomerIncludingProductsChange = (event) => {
        this.setState({ifTheCustomerIncludingProductsChangeValue : event.target.value});

        this.setState({errorIndividualProductSelectedFlag: false});

        this.setState({individualProductSearchBoxFlag: false});
        this.setState({allCategorySearchBoxFlag: false});
        this.setState({allBrandSearchBoxFlag: false});
        this.setState({addAnotherInclutionRuleBtnFlag: false});
        this.setState({addAnotherInclutionRuleSelectFlag: false});

        switch(event.target.value) {
            case "individual_products" : {
                this.setState({individualProductSearchBoxFlag: true});
                break
            }
            case "all_products" : {
                break;
            }
            case "all_category" : {
                this.setState({allCategorySearchBoxFlag: true});
                this.setState({addAnotherInclutionRuleBtnFlag: true});
                break;
            }
            case "in_brand" : {
                this.setState({allBrandSearchBoxFlag: true});
                this.setState({addAnotherInclutionRuleBtnFlag: true});
                break;
            }
            case "" :
            default : {                
                break;
            }
        }
    }


    /*
    * modals popup for simple category or brand or individual product
    */
    modalPopupOpenIndividualProduct = () => {
        this.setState({popupOpenIndividualProductFlag: true});
    }
    modalPopupOpenAllCategory = () => {
        this.setState({popupOpenAllCategory: true});
    }
    modalPopupOpenAllBrand = () => {
        this.setState({popupOpenAllBrand: true});
    }


    /*
    * modals popup close for simple category or brand or individual product
    */
    modalPopupCloseIndividualProduct = () => {
        this.setState({popupOpenIndividualProductFlag: false});
    }
    modalPopupCloseAllCategory = () => {
        this.setState({popupOpenAllCategory: false});
    }    
    modalPopupCloseAllBrand = () => {
        this.setState({popupOpenAllBrand: false});
    }


    /*
    * modals popup close and data set for simple category or brand or individual product
    */
    modalPopupApplyIndividualProduct = (ids, labels) => {   
        this.setState({popupOpenIndividualProductFlag: false});
        this.setState({individualProductSelectedLabels:labels});
        this.setState({individualProductSelectedIds:ids});
    }
    modalPopupApplyAllCategory = (ids, labels) => {
        this.setState({popupOpenAllCategory: false});
        this.setState({allCategorySelectedLabels:labels});
        this.setState({allCategorySelectedIds:ids});
    }
    modalPopupApplyAllBrand = (ids, labels) => {
        this.setState({popupOpenAllBrand: false});
        this.setState({allBrandSelectedLabels:labels});
        this.setState({allBrandSelectedIds:ids});
    }


    /*
    * simple first step reching quantity or total values
    */
    handleIfTheCustomerReachingChange = (event) => { 
        this.setState({ifTheCustomerReachingSelectValue: event.target.value});               
        this.clearHandleIfCustomerReachingChange();
        switch(event.target.value) {
            case "reaching_quantity" : {
                this.setState({ifCustomerConditionQuantityFlag: true})                 
                this.setState({ifCustomerConditionIncludingProductFlag: true});
                this.setState({addExclutionRuleBtnFlag: true});                
                break;
            }
            case "reaching_total_value" : {                 
                this.setState({ifCustomerConditionTotalFlag: true}) 
                this.setState({ifCustomerConditionIncludingProductFlag: true});
                this.setState({addExclutionRuleBtnFlag: true});               
                break;
            }
            case "" :
            default : {               
                break;
            }
        }
    }

    clearHandleIfCustomerReachingChange = () => {        
        this.setState({errorConditionQuantityTotalFlag: false});
        this.setState({allBrandSearchBoxFlag: false});
        this.setState({allCategorySearchBoxFlag: false});
        this.setState({individualProductSearchBoxFlag: false}); 
        this.setState({ifCustomerConditionQuantityFlag: false});
        this.setState({ifCustomerConditionTotalFlag: false});
        this.setState({ifCustomerConditionTotalFlag: false}) 
        this.setState({ifCustomerConditionIncludingProductFlag: false});
        this.setState({addExclutionRuleBtnFlag: false});
        this.setState({addExclutionRuleSelectFlag: false});
        this.setState({addAnotherExclutionRuleBtnFlag: false});
        this.setState({addAnotherExclutionRuleSelectFlag: false});
        this.setState({ifTheCustomerIncludingProductsChangeValue: ""});
        this.setState({ifTheCustomerIncludingProductsExcludeChangeValue:""});
    }


    /*
    * simple first begining step if the customer select change to
    * buy product or reaches to order or no condition
    */
    handleIfTheCustomerChange = (event) => { 
        this.setState({ifTheCustomerSelectValue: event.target.value});       
        this.clearFlagsForHandleIfTheCustomerChange()
        switch(event.target.value) {

            case "buys_products" : {                                             
                this.setState({buysProductsFlag: true});                
                this.clearPopupModalFlags();
                break;
            }
            case "reaches_an_order_sub_total" : { 
                this.setState({reachesAnOrderSubTotalHTMLFlag: true});                
                this.setState({reachesAnOrderSubTotalFlag:true})
                this.clearPopupModalFlags(); 
                break;
            }
            case "no_conditions" : {
                let newContent =
                    <div className="col-sm-12 top-bottom-5">
                        <div className="col-sm-12 inline-flex">
                            <div className="dot-svg">
                                <svg fill="currentColor" height="18" stroke="currentColor" strokeWidth="0" viewBox="0 0 18 18" width="18" className="sc-bwzfXH gxVJAp"><title></title><rect fill="#FFE180" height="8" rx="4" transform="matrix(-1.45705e-08 1 1 1.31134e-07 0 0)" width="8"></rect></svg>
                            </div>
                            <div><label className="gray-label">Any orders by the customer will trigger this reward.</label></div>
                        </div>
                    </div> 
                this.setState({noConditionFlag: true});    
                this.setState({ifCustomerConditionHTML: newContent});                
                this.clearPopupModalFlags(); 
                break;
            }
            case "":
            default: {                
                this.clearPopupModalFlags();                
                break;
            } 

        }
    }
    
    clearFlagsForHandleIfTheCustomerChange = () =>{        
        this.setState({ifCustomerConditionHTML: ""})
        this.setState({reachesAnOrderSubTotalHTMLFlag: false});
        this.setState({buysProductsFlag: false});
        this.setState({addExclutionRuleBtnFlag: false});
        this.setState({noConditionFlag:false});
        this.setState({reachesAnOrderSubTotalFlag:false});
        this.setState({ifCustomerConditionQuantityFlag: false});
        this.setState({ifCustomerConditionTotalFlag: false});
        this.setState({ifCustomerConditionIncludingProductFlag:false});
    }

    /*
    * cancel rule modal popup 
    */
    showAlertCancel = (event) => {
        event.preventDefault();
        this.setState({ showModalCancel: true });
    }
    modalPopupCloseCancel = () => {
        this.setState({ showModalCancel: false });
    }
    modalPopupBackCancel = (event) => {
        event.preventDefault();
        this.props.history.push({pathname: '/create-promotion', createPromotionFormData: { ...this.props.location.pageData}})
    }
    

    /*
    * rest popup modal flags to false
    */
    clearPopupModalFlags = () => {
        this.setState({popupOpenIndividualProductExcludeFlag: false});
        this.setState({popupOpenAllCategoryExcludeFlag: false});
        this.setState({popupOpenAllBrandExcludeFlag: false});
        this.setState({addAnotherInclutionRuleSelectFlag: false})
        this.setState({allBrandSearchBoxAnotherInclutionFlag: false})
        this.setState({allCategorySearchBoxAnotherInclutionFlag: false})
		this.setState({addAnotherExclutionRuleBtnFlag: false});
        this.setState({addAnotherExclutionRuleSelectFlag: false})
        this.setState({allBrandSearchBoxAnotherExclutionFlag: false})
        this.setState({allCategorySearchBoxAnotherExclutionFlag: false})
		this.setState({addExclutionRuleBtnFlag: false})
        this.setState({addExclutionRuleSelectFlag: false})
        this.setState({excludingIndividualProductSearchBoxFlag: false})
        this.setState({excludingAllCategorySearchBoxFlag: false})
        this.setState({excludingAllBrandSearchBoxFlag: false})
        this.setState({addAnotherExclutionRuleSelectFlag: false})
        this.setState({errorConditionFlag : false})
    }

    clearThenRewardFlags = () => {
        this.setState({rewardGiftInTheCartFlag: false});
        this.setState({rewardFreeShippingFlag: false});
        this.setState({rewardDiscountOnProductsFlag: false});
        this.setState({rewardDiscountOnOrderSubtotalFlag: false});
        this.setState({rewardFixedPriceForOfProductFlag: false});
        this.setState({rewardFreeShippingAllZoneFlag: false}); 
        this.setState({rewardFreeShippingSelectedZoneFlag: false});
        this.setState({rewardIndividualProductSearchBoxFlag: false});
        this.setState({rewardAllCategorySearchBoxFlag: false}); 
        this.setState({rewardAllBrandSearchBoxFlag: false});
        this.setState({popupOpenIndividualProductRewardFlag: false});
        this.setState({popupOpenAllCategoryRewardFlag: false}); 
        this.setState({popupOpenAllBrandRewardFlag: false});
        this.setState({addAnotherInclutionRuleRewardSelectFlag: false})
        this.setState({allBrandSearchBoxAnotherInclutionRewardFlag: false})
        this.setState({allCategorySearchBoxAnotherInclutionRewardFlag: false});
        this.setState({rewardAddExclutionRuleBtnFlag: false});
        this.setState({rewardAddExclutionRuleSelectFlag: false}); 
        this.setState({errorActionFlag : false});
    }

    /*
    * simple first begining step then reward select change to
    * free shiping or gift etc 
    */
    handleThenReward = (event) => {
        this.setState({thenRewardSelectValue: event.target.value});
        this.setState({rewardDisOnProdQuntCount: 0});
        this.setState({rewardDiscountOnProductsByPer: 0});
        this.setState({rewardDiscountOnProductsByVal: 0});
        switch(event.target.value) {
            case "a_gift_in_their_cart" : {
                this.clearThenRewardFlags()
                this.setState({rewardGiftInTheCartFlag: true});               
                break;
            }
            case "free_shipping" : {
                this.clearThenRewardFlags()
                this.setState({rewardFreeShippingFlag: true}); 
                break;
            }
            case "discount_on_products" : {
                this.clearThenRewardFlags()
                this.setState({rewardDiscountOnProductsFlag: true});
                this.setState({rewardAddExclutionRuleBtnFlag:true}); 
                break;
            }
            case "discount_on_order_subtotal": {
                this.clearThenRewardFlags()
                this.setState({rewardDiscountOnOrderSubtotalFlag: true});
                break;
            }
            case "fixed_price_for_of_products": {
                this.clearThenRewardFlags()
                this.setState({rewardFixedPriceForOfProductFlag: true});
                this.setState({rewardAddExclutionRuleBtnFlag:true}); 
                break;
            }
            case "":
            default: {    
                this.clearThenRewardFlags()            
                break;
            }
        }
    }

    /*
    *   open product search box for reward gift product
    */
    modalPopupOpenIndividualProductGiftInCartReward = (event) => {
        this.setState({popupOpenIndividualProductGiftInCartRewardFlag: true});
    }

    /*
    * modal popup data set reward gift product
    */
    modalPopupApplyIndividualProductGiftInCartReward = (ids, labels) => {
        if(ids.length > 0 ){
            ids = ids.filter(element => {return element !== undefined;});
        }
            
        this.setState({showAlert: false});
        if(ids.length > 1){
            this.setState({
                type: "danger",
                showAlert: true,
                alertMessage: "Select only one product!",
            });
            return false;
        }   
        this.setState({popupOpenIndividualProductGiftInCartRewardFlag: false});
        this.setState({rewardIndividualProductGiftInCartSelectedLabels:labels});
        this.setState({rewardIndividualProductGiftInCartSelectedIds:ids.pop()});
    }

    /*
    * modal popup individual product Gift in Cart Reward - Close
    */
    modalPopupCloseIndividualProductGiftInCartReward = () => {
        this.setState({popupOpenIndividualProductGiftInCartRewardFlag: false});
        this.setState({showAlert: false});
    }

    /*
    * reward free shipping select all zone, selected zones
    */
    rewardFreeShippingSelectChange = (event) => {
        switch(event.target.value) {
            case "all_zones" : {              
                this.setState({rewardFreeShippingAllZoneFlag: true});
                this.setState({rewardFreeShippingSelectedZoneFlag: false});             
                break;
            }
            case "selected_zones" : {
                this.setState({rewardFreeShippingAllZoneFlag: false});              
                this.setState({rewardFreeShippingSelectedZoneFlag: true}); 
                break;
            }
            case "":
            default: { 
                this.setState({rewardFreeShippingAllZoneFlag: false}); 
                this.setState({rewardFreeShippingSelectedZoneFlag: false});                         
                break;
            }
        }
    }

    /*
    *  open modal search box for reward gift freeshipping selected zone
    */
    modalPopupOpenZonesFreeShippingReward = (event) => {
        this.setState({popupOpenFreeShippingZoneRewardFlag: true});
    }

    /*
    * modal popup data set reward gift freeshipping selected zone
    */
    modalPopupApplyZonesFreeShippingReward = (ids, labels) => {     
        this.setState({popupOpenFreeShippingZoneRewardFlag: false});
        this.setState({rewardFreeShippingZoneSelectedLabels:labels});
        this.setState({rewardFreeShippingZoneSelectedIds:ids});
    }

    /*
    * Close modal search box for reward gift freeshipping selected zone 
    */
    modalPopupCloseZonesFreeShippingReward = () => {
        this.setState({popupOpenFreeShippingZoneRewardFlag: false});
    }

    /*
    * reward discount on order subtotal type value or percentage
    */
    rewardDiscountOnOrderSubtotalByValOrPerChange = (event) => {      
        this.setState({rewardDiscountOnOrderSubtotalByValPersData: event.target.value.toLowerCase()});        
        this.setState({rewardDiscountOnOrderSubtotalPer: 0, rewardDiscountOnOrderSubtotalVal: 0});        
    }

    /*
    * reward discount on Products Select change
    */
    rewardDiscountOnProductsSelectChange = (event) => {
        this.setState({rewardDiscountOnProductsSelectData: event.target.value.toLowerCase()});
        this.setState({rewardDiscountOnOrderSubtotalPer: 0, rewardDiscountOnOrderSubtotalVal: 0});
    }

    /*
    * reward discount on Products applied on Select change
    */
    rewardDiscountOnProductsAppliedOnSelectChange = (event) => {
        this.setState({rewardDiscountOnProductsAppliedOnSelectData: event.target.value.toLowerCase()});
    }

    /*
    * Reward Including Product Category Brand Select Change
    */
    handleRewardIncludingProCatBrandChange = (event) => {
        this.setState({rewardIncludingProductVal:event.target.value});

        this.setState({rewardIndividualProductSearchBoxFlag: false});
        this.setState({rewardAllCategorySearchBoxFlag: false});
        this.setState({rewardAllBrandSearchBoxFlag: false});
        this.setState({addAnotherInclutionRuleRewardBtnFlag: true});
        this.setState({addAnotherInclutionRuleRewardSelectFlag:false});

        switch(event.target.value) {
            case "individual_products" : {
                this.setState({rewardIndividualProductSearchBoxFlag: true});                
                break
            }
            case "all_category" : {
                this.setState({rewardAllCategorySearchBoxFlag: true});
                break;
            }
            case "in_brand" : {
                this.setState({rewardAllBrandSearchBoxFlag: true});
                break;
            }
            case "" :
            default : {                
                break;
            }
        }
    }

    /*
    * modals popup for reward category or brand or individual product
    */
    modalPopupOpenIndividualProductReward = () => {
        this.setState({popupOpenIndividualProductRewardFlag: true});
    }
    modalPopupOpenAllCategoryReward = () => {
        this.setState({popupOpenAllCategoryRewardFlag: true});
    }
    modalPopupOpenAllBrandReward = () => {
        this.setState({popupOpenAllBrandRewardFlag: true});
    }

    /*
    * modals popup close for Reward category or brand or individual product
    */
    modalPopupCloseIndividualProductReward = () => {
        this.setState({popupOpenIndividualProductRewardFlag: false});
    }
    modalPopupCloseAllCategoryReward = () => {
        this.setState({popupOpenAllCategoryRewardFlag: false});
    }    
    modalPopupCloseAllBrandReward = () => {
        this.setState({popupOpenAllBrandRewardFlag: false});
    }

    /*
    * modals popup close and data set for exclution of category or brand or individual product
    */
    modalPopupApplyIndividualProductReward = (ids, labels) => {     
        this.setState({popupOpenIndividualProductRewardFlag: false});
        this.setState({individualProductSelectedRewardLabels:labels});
        this.setState({individualProductSelectedRewardIds:ids});
    }
    modalPopupApplyAllCategoryReward = (ids, labels) => {
        this.setState({popupOpenAllCategoryRewardFlag: false});
        this.setState({allCategorySelectedRewardLabels:labels});
        this.setState({allCategorySelectedRewardIds:ids});
    }
    modalPopupApplyAllBrandReward = (ids, labels) => {
        this.setState({popupOpenAllBrandRewardFlag: false});
        this.setState({allBrandSelectedRewardLabels:labels});
        this.setState({allBrandSelectedRewardIds:ids});
    }
    
    /*
    * reward another inclution add button click and show select box
    */
    handleAddAnotherInclutionRuleRewardBtnClick = (event) => {
        event.preventDefault();
        this.setState({addAnotherInclutionRuleRewardSelectFlag: !this.state.addAnotherInclutionRuleRewardSelectFlag})
        this.setState({addAnotherInclutionRuleRewardBtnFlag: !this.state.addAnotherInclutionRuleRewardBtnFlag})
    }

    /*
    * reward another inclution of category or brand change event on select box
    */
    handleAddAnotherInclutionCategoryRewardChange = (event) => {
        event.preventDefault();
        this.setState({allBrandSearchBoxAnotherInclutionRewardFlag: !this.state.allBrandSearchBoxAnotherInclutionRewardFlag});
    }
    handleAddAnotherInclutionBrandRewardChange = (event) => {
        event.preventDefault();
        this.setState({allCategorySearchBoxAnotherInclutionRewardFlag: !this.state.allCategorySearchBoxAnotherInclutionRewardFlag});
    }

    /*
    * another inclution remove click
    */
    handleAddAnotherInclutionRuleRewardRemoveClick = (event) => {
        event.preventDefault();
        this.setState({addAnotherInclutionRuleRewardBtnFlag: true})
        this.setState({addAnotherInclutionRuleRewardSelectFlag: false})
        this.setState({allBrandSearchBoxAnotherInclutionRewardFlag: false})
        this.setState({allCategorySearchBoxAnotherInclutionRewardFlag: false})
    }


    /*
    * modals popup for another inclution of category or brand reward
    */
    modalPopupOpenAllCategoryAnotherInclutionReward = () => {
        this.setState({popupOpenAllCategoryAnotherInclutionReward: true});
    }
    modalPopupOpenAllBrandAnotherInclutionReward = () => {
        this.setState({popupOpenAllBrandAnotherInclutionReward: true});
    }

    /*
    * modals popup close for another inclution of category or brand Reward
    */
    modalPopupCloseAllCategoryAnotherInclutionReward = () => {
        this.setState({popupOpenAllCategoryAnotherInclutionReward: false});
    }    
    modalPopupCloseAllBrandAnotherInclutionReward = () => {
        this.setState({popupOpenAllBrandAnotherInclutionReward: false});
    }

    /*
    * modals popup close and data set for another inclution of category or brand
    */
    modalPopupApplyAllCategoryAnotherInclutionReward = (ids, labels) => {
        this.setState({popupOpenAllCategoryAnotherInclutionReward: false});
        this.setState({allCategoryAnotherInclutionRewardSelectedLabels:labels});
        this.setState({allCategoryAnotherInclutionRewardSelectedIds:ids});
    }
    modalPopupApplyAllBrandAnotherInclutionReward = (ids, labels) => {
        this.setState({popupOpenAllBrandAnotherInclutionReward: false});
        this.setState({allBrandAnotherInclutionRewardSelectedLabels:labels});
        this.setState({allBrandAnotherInclutionRewardSelectedIds:ids});
    }


    /*
    * reward add exclution button click
    */
    rewardHandleAddExclutionRuleClick = (event) => {
        event.preventDefault();
        this.setState({rewardAddExclutionRuleSelectFlag: !this.state.rewardAddExclutionRuleSelectFlag})
        this.setState({rewardAddExclutionRuleBtnFlag: !this.state.rewardAddExclutionRuleBtnFlag})
    }

    /*
    *  reward exclution change event
    */
    rewardHandleIfTheCustomerExcludingProductsChange = (event) => {
        this.setState({rewardIfTheCustomerExcludingProductVal: event.target.value});

        this.setState({rewardExcludingIndividualProductSearchBoxFlag: false});
        this.setState({rewardExcludingAllCategorySearchBoxFlag: false});
        this.setState({rewardExcludingAllBrandSearchBoxFlag: false});
        this.setState({rewardAddAnotherExclutionRuleBtnFlag: true});
        this.setState({rewardAddAnotherExclutionRuleSelectFlag: false});

        switch(event.target.value) {
            case "individual_products" : {
                this.setState({rewardExcludingIndividualProductSearchBoxFlag: true});
                break
            }
            case "all_category" : {
                this.setState({rewardExcludingAllCategorySearchBoxFlag: true});
                break;
            }
            case "in_brand" : {
                this.setState({rewardExcludingAllBrandSearchBoxFlag: true});
                break;
            }
            case "" :
            default : {
                break;
            }
        }
    }
    
    /*
    *  reward modals popup for exclution category or brand or individual product
    */
    rewardModalPopupOpenIndividualProductExclude = () => {
        this.setState({rewardPopupOpenIndividualProductExcludeFlag: true});
    }
    rewardModalPopupOpenAllCategoryExclude = () => {
        this.setState({rewardPopupOpenAllCategoryExcludeFlag: true});
    }
    rewardModalPopupOpenAllBrandExclude = () => {
        this.setState({rewardPopupOpenAllBrandExcludeFlag: true});
    }

    /*
    *  reward modals popup close for exclude category or brand or individual product
    */
    rewardModalPopupCloseIndividualProductExclude = () => {
        this.setState({rewardPopupOpenIndividualProductExcludeFlag: false});
    }
    rewardModalPopupCloseAllCategoryExclude = () => {
        this.setState({rewardPopupOpenAllCategoryExcludeFlag: false});
    }    
    rewardModalPopupCloseAllBrandExclude = () => {
        this.setState({rewardPopupOpenAllBrandExcludeFlag: false});
    }

    /*
    *  reward modals popup close and data set for exclution of category or brand or individual product
    */
    rewardModalPopupApplyIndividualProductExclude = (ids, labels) => {     
        this.setState({rewardPopupOpenIndividualProductExcludeFlag: false});
        this.setState({rewardIndividualProductSelectedExcludeLabels:labels});
        this.setState({rewardIndividualProductSelectedExcludeIds:ids});
    }
    rewardModalPopupApplyAllCategoryExclude = (ids, labels) => {
        this.setState({rewardPopupOpenAllCategoryExcludeFlag: false});
        this.setState({rewardAllCategorySelectedExcludeLabels:labels});
        this.setState({rewardAllCategorySelectedExcludeIds:ids});
    }
    rewardModalPopupApplyAllBrandExclude = (ids, labels) => {
        this.setState({rewardPopupOpenAllBrandExcludeFlag: false});
        this.setState({rewardAllBrandSelectedExcludeLabels:labels});
        this.setState({rewardAllBrandSelectedExcludeIds:ids});
    }


    /*
    *  reward another exclution remove click
    */
    rewardHandleAddExclutionRuleRemoveClick = (event) => {
        event.preventDefault();
        this.setState({rewardIfTheCustomerExcludingProductVal: ""});
        this.setState({rewardAddExclutionRuleBtnFlag: !this.state.rewardAddExclutionRuleBtnFlag})
        this.setState({rewardAddExclutionRuleSelectFlag: !this.state.rewardAddExclutionRuleSelectFlag})
        this.setState({rewardExcludingIndividualProductSearchBoxFlag: false})
        this.setState({rewardExcludingAllCategorySearchBoxFlag: false})
        this.setState({rewardExcludingAllBrandSearchBoxFlag: false})
        this.setState({rewardAddAnotherExclutionRuleBtnFlag: true})
        this.setState({rewardAddAnotherExclutionRuleSelectFlag: false})
    }

    /*
    *  reward another exclution add button click and show select box
    */
    rewardHandleAddAnotherExclutionRuleBtnClick = (event) => {
        event.preventDefault();
        this.setState({rewardAddAnotherExclutionRuleBtnFlag: false})
        this.setState({rewardAddAnotherExclutionRuleSelectFlag: true})
    }

    /*
    *  reward another exclution of category or brand change event on select box
    */
    rewardHandleAddAnotherExclutionCategoryChange = (event) => {
        event.preventDefault();
        this.setState({rewardAllBrandSearchBoxAnotherExclutionFlag: !this.state.rewardAllBrandSearchBoxAnotherExclutionFlag});
    }
    rewardHandleAddAnotherExclutionBrandChange = (event) => {
        event.preventDefault();
        this.setState({rewardAllCategorySearchBoxAnotherExclutionFlag: !this.state.rewardAllCategorySearchBoxAnotherExclutionFlag});
    }

    /*
    *  reward another exclution remove click
    */
    rewardHandleAddAnotherExclutionRuleRemoveClick = (event) => {
        event.preventDefault();
        this.setState({rewardAddAnotherExclutionRuleBtnFlag: !this.state.rewardAddAnotherExclutionRuleBtnFlag})
        this.setState({rewardAddAnotherExclutionRuleSelectFlag: !this.state.rewardAddAnotherExclutionRuleSelectFlag})
        this.setState({rewardAllBrandSearchBoxAnotherExclutionFlag: false})
        this.setState({rewardAllCategorySearchBoxAnotherExclutionFlag: false})
    }

    /*
    *  reward modals popup for another exclution of category or brand
    */
    rewardModalPopupOpenAllCategoryAnotherExclution = () => {
        this.setState({rewardPopupOpenAllCategoryAnotherExclutionFlag: true});
    }
    rewardModalPopupOpenAllBrandAnotherExclution = () => {
        this.setState({rewardPopupOpenAllBrandAnotherExclutionFlag: true});
    }

    /*
    *  reward modals popup close and data set for another inclution of category or brand
    */
    rewardModalPopupApplyAllCategoryAnotherExclution = (ids, labels) => {
        this.setState({rewardPopupOpenAllCategoryAnotherExclutionFlag: false});
        this.setState({rewardAllCategoryAnotherExclutionSelectedLabels:labels});
        this.setState({rewardAllCategoryAnotherExclutionSelectedIds:ids});
    }
    rewardModalPopupApplyAllBrandAnotherExclution = (ids, labels) => {
        this.setState({rewardPopupOpenAllBrandAnotherExclutionFlag: false});
        this.setState({rewardAllBrandAnotherExclutionSelectedLabels:labels});
        this.setState({rewardAllBrandAnotherExclutionSelectedIds:ids});
    }
	
    /*
    * reward modals popup close for another exclution of category or brand
    */
    rewardModalPopupCloseAllCategoryAnotherExclution = () => {
        this.setState({rewardPopupOpenAllCategoryAnotherExclutionFlag: false});
    }    
    rewardModalPopupCloseAllBrandAnotherExclution = () => {
        this.setState({rewardPopupOpenAllBrandAnotherExclutionFlag: false});
    }


    /*
    * validate promotion data, build payload and back to promotion page
    */
    handleAddToPromotion = (event) => {
        event.preventDefault();
        this.clearValidationFlags();
        if(!this.validate1stSelectConditionField()) return false;        

        /**
         * data to be sent back after rule and rewards
         */
        const ruleRewardData =  {
                                    rule : {},
                                    conditionName : "",
                                    rewardName : ""
                                }

        /**
         * condition & reward blank declarion
         */
        const rule = {
            action: {},
            apply_once:	true,
            condition: {},
            stop: false
        }     
        
        if(this.state.noConditionFlag){
            delete rule.condition;
            ruleRewardData.conditionName = "No conditions";
        }
        else if(this.state.reachesAnOrderSubTotalFlag){
            if(!this.validateReachesMinSpend()) return false;
            rule.condition.cart = {"minimum_spend":this.state.reachesAnOrderSubTotalValue};
            ruleRewardData.conditionName = "Reaches an order sub-total of $"+this.state.reachesAnOrderSubTotalValue;
        }
        else if(this.state.buysProductsFlag){
            if(!this.validateReachingQuantityTotalSelect()) return false;
            if(!this.validateReachingQuantityValue()) return false;
            else if(this.state.ifCustomerConditionQuantityFlag) 
            {
                rule.condition.cart = {"minimum_quantity":this.state.ifCustomerConditionQuantityValue};
                ruleRewardData.conditionName = `Buys ${this.state.ifCustomerConditionQuantityValue} Products`;
            }
            if(!this.validateReachingTotalValue()) return false;
            else if(this.state.ifCustomerConditionTotalFlag) 
            {
                rule.condition.cart = {"minimum_spend":this.state.ifCustomerConditionTotalValue};
                ruleRewardData.conditionName = "Buys Products for $"+this.state.ifCustomerConditionTotalValue;
            }
            if(!this.validateIfCustomerConditionIncludingProductSelect()) return false;
            if(!this.validateSimpleIncludingProductInput()) return false;
            else if(this.state.individualProductSearchBoxFlag && this.state.addExclutionRuleBtnFlag && !this.state.excludingIndividualProductSearchBoxFlag)
                rule.condition.cart.items={"products":this.state.individualProductSelectedIds};
            else if(!this.validateAllCategoryInput()) return false;
            else if(this.state.allCategorySearchBoxFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.addExclutionRuleBtnFlag)
                rule.condition.cart.items={"categories":this.state.allCategorySelectedIds};
            else if(!this.validateAllBrandInput()) return false;
            else if(this.state.allBrandSearchBoxFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.addExclutionRuleBtnFlag)
                rule.condition.cart.items={"brands":this.state.allBrandSelectedIds};
            
            else if(this.state.allCategorySearchBoxAnotherInclutionFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.addExclutionRuleBtnFlag && !this.state.excludingIndividualProductSearchBoxFlag)
            {
                if(!this.validateAllCategoryAnotherInput()) return false;
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true})
                    return false;
                }
                rule.condition.cart.items={};
                rule.condition.cart.items.and=[];
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds});
                rule.condition.cart.items.and.push({"categories":this.state.allCategoryAnotherInclutionSelectedIds});
                
            }
            else if(this.state.allBrandSearchBoxAnotherInclutionFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.addExclutionRuleBtnFlag && !this.state.excludingIndividualProductSearchBoxFlag)
            {
                if(!this.validateAllBrandAnotherInput()) return false;
                if(this.state.allCategorySelectedIds.length <= 0 || this.state.allCategorySelectedLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true})
                    return false;
                }

                rule.condition.cart.items={};
                rule.condition.cart.items.and=[];
                rule.condition.cart.items.and.push({"brands":this.state.allBrandAnotherInclutionSelectedIds});
                rule.condition.cart.items.and.push({"categories":this.state.allCategorySelectedIds});
            }
            
            //validating  & adding exclude products and payload prepare
            if(!this.validateIfCustomerConditionIncludingProductExcludeSelect()) return false;
            if(!this.validateExcludeIncludingProductInput()) return false;
            else if(!this.state.addExclutionRuleBtnFlag && this.state.individualProductSearchBoxFlag && this.state.excludingIndividualProductSearchBoxFlag)
            {
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"products":this.state.individualProductSelectedExcludeIds}});
                rule.condition.cart.items.and.push({"products":this.state.individualProductSelectedIds})
            }
            else if(!this.state.addExclutionRuleBtnFlag && this.state.ifTheCustomerIncludingProductsChangeValue==="all_products" && this.state.excludingIndividualProductSearchBoxFlag)
            {
                rule.condition.cart.items = {"not":{"products":this.state.individualProductSelectedExcludeIds}}
            }
            else if(!this.state.addExclutionRuleBtnFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.allCategorySearchBoxFlag && this.state.excludingIndividualProductSearchBoxFlag)
            {
                if(this.state.allCategorySelectedIds.length <= 0 || this.state.allCategorySelectedLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"products":this.state.individualProductSelectedExcludeIds}});
                rule.condition.cart.items.and.push({"categories":this.state.allCategorySelectedIds})
            }
            else if(!this.state.addExclutionRuleBtnFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.allBrandSearchBoxFlag && this.state.excludingIndividualProductSearchBoxFlag)
            {
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true});
                    return false;
                }
                if(this.state.individualProductSelectedExcludeIds.length <= 0 || this.state.individualProductSelectedExcludeLabels === "")
                {
                    this.setState({errorIndividualProductSelectedExcludeBoxFlag: true});
                    return false;
                }
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"products":this.state.individualProductSelectedExcludeIds}});
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds})
            }
            else if(!this.state.addExclutionRuleBtnFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.allCategorySearchBoxFlag && this.state.allBrandSearchBoxAnotherInclutionFlag && this.state.excludingIndividualProductSearchBoxFlag){
                if(this.state.individualProductSelectedExcludeIds.length <= 0 || this.state.individualProductSelectedExcludeLabels === "")
                {
                    this.setState({errorIndividualProductSelectedExcludeBoxFlag: true});
                    return false;
                } 
                if(this.state.allCategorySelectedIds.length <= 0 || this.state.allCategorySelectedLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allBrandAnotherInclutionSelectedIds.length <= 0 || this.state.allBrandAnotherInclutionSelectedLabels === "")
                {
                    this.setState({errorAllBrandAnotherSelectedBoxFlag: true});
                    return false;
                }  
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"products":this.state.individualProductSelectedExcludeIds}});
                rule.condition.cart.items.and.push({"categories":this.state.allCategorySelectedIds})
                rule.condition.cart.items.and.push({"brands":this.state.allBrandAnotherInclutionSelectedIds})
            }
            else if(!this.state.addExclutionRuleBtnFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.allBrandSearchBoxFlag && this.state.allCategorySearchBoxAnotherInclutionFlag && this.state.excludingIndividualProductSearchBoxFlag){
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allCategoryAnotherInclutionSelectedIds.length <= 0 || this.state.allCategoryAnotherInclutionSelectedLabels === "")
                {
                    this.setState({errorAllCategoryAnotherSelectedBoxFlag: true})
                    return false;
                }
                if(this.state.individualProductSelectedExcludeIds.length <= 0 || this.state.individualProductSelectedExcludeLabels === "")
                {
                    this.setState({errorIndividualProductSelectedExcludeBoxFlag: true});
                    return false;
                }

                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"products":this.state.individualProductSelectedExcludeIds}});
                rule.condition.cart.items.and.push({"categories":this.state.allCategoryAnotherInclutionSelectedIds})
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds})
            }
            
            else if(this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && this.state.individualProductSearchBoxFlag && this.state.excludingAllCategorySearchBoxFlag)
            {
                if(this.state.individualProductSelectedIds.length <= 0 || this.state.individualProductSelectedLabels === "")
                {
                    this.setState({errorIndividualProductSelectedBoxFlag: true})
                    return false;
                }
                if(this.state.allCategorySelectedExcludeIds.length <= 0 || this.state.allCategorySelectedExcludeLabels === "")
                {
                    this.setState({errorAllCategorySelectedExcludeBoxFlag: true});
                    return false;
                }

                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"categories":this.state.allCategorySelectedExcludeIds}});
                rule.condition.cart.items.and.push({"products":this.state.individualProductSelectedIds})
            }
            else if(this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && this.state.ifTheCustomerIncludingProductsChangeValue==="all_products" && this.state.excludingAllCategorySearchBoxFlag)
            {
                if(this.state.allCategorySelectedExcludeIds.length <= 0 || this.state.allCategorySelectedExcludeLabels === "")
                {
                    this.setState({errorAllCategorySelectedExcludeBoxFlag: true});
                    return false;
                }
                rule.condition.cart.items = {"not":{"categories":this.state.allCategorySelectedExcludeIds}}
            }
            else if(this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.allCategorySearchBoxFlag && this.state.excludingAllCategorySearchBoxFlag)
            {
                if(this.state.allCategorySelectedIds.length <= 0 || this.state.allCategorySelectedLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allCategorySelectedExcludeIds.length <= 0 || this.state.allCategorySelectedExcludeLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"categories":this.state.allCategorySelectedExcludeIds}});
                rule.condition.cart.items.and.push({"categories":this.state.allCategorySelectedIds})
            }
            else if(this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.allBrandSearchBoxFlag && this.state.excludingAllCategorySearchBoxFlag)
            {
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allCategorySelectedExcludeIds.length <= 0 || this.state.allCategorySelectedExcludeLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"categories":this.state.allCategorySelectedExcludeIds}});
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds})
            }
            else if(this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.allCategorySearchBoxFlag && this.state.allBrandSearchBoxAnotherInclutionFlag && this.state.excludingAllCategorySearchBoxFlag)
            {
                if(this.state.allCategorySelectedIds.length <= 0 || this.state.allCategorySelectedLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allBrandAnotherInclutionSelectedLabels==="" || this.state.allBrandAnotherInclutionSelectedIds.length<=0){
                    this.setState({errorAllBrandAnotherSelectedBoxFlag:true});
                    return false;
                }
                if(this.state.allCategorySelectedExcludeLabels==="" || this.state.allCategorySelectedExcludeIds.length<=0){
                    this.setState({errorAllCategorySelectedExcludeFlag:true});
                    return false;
                }                
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"categories":this.state.allCategorySelectedExcludeIds}});
                rule.condition.cart.items.and.push({"categories":this.state.allCategorySelectedIds})
                rule.condition.cart.items.and.push({"brands":this.state.allBrandAnotherInclutionSelectedIds})
            }
            else if(this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.allBrandSearchBoxFlag && this.state.allCategorySearchBoxAnotherInclutionFlag && this.state.excludingAllBrandSearchBoxFlag)
            {                
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allCategoryAnotherInclutionSelectedLabels==="" || this.state.allCategoryAnotherInclutionSelectedIds.length<=0){
                    this.setState({errorAllCategoryAnotherSelectedBoxFlag:true});
                    return false;
                }
                if(this.state.allBrandSelectedExcludeLabels==="" || this.state.allBrandSelectedExcludeIds.length<=0){
                    this.setState({errorAllBrandSelectedExcludeFlag:true});
                    return false;
                }                
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"brands":this.state.allBrandSelectedExcludeIds}});
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds})
                rule.condition.cart.items.and.push({"categories":this.state.allCategoryAnotherInclutionSelectedIds})
            }
            else if(this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.allBrandSearchBoxFlag && this.state.allCategorySearchBoxAnotherInclutionFlag && this.state.excludingAllCategorySearchBoxFlag)
            {                
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allCategoryAnotherInclutionSelectedIds.length <= 0 || this.state.allCategoryAnotherInclutionSelectedLabels === "")
                {
                    this.setState({errorAllCategoryAnotherSelectedBoxFlag: true})
                    return false;
                }
                if(this.state.allCategorySelectedExcludeLabels=="" || this.state.allCategorySelectedExcludeIds.length<=0){
                    this.setState({errorAllCategorySelectedExcludeFlag:true});
                    return false;
                }
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                rule.condition.cart.items.and.push({"not":{"categories":this.state.allCategorySelectedExcludeIds}});
                rule.condition.cart.items.and.push({"categories":this.state.allCategoryAnotherInclutionSelectedIds})
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds})
            }
            
            else if(!this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && this.state.individualProductSearchBoxFlag && (this.state.excludingAllCategorySearchBoxFlag || this.state.excludingAllBrandSearchBoxFlag))
            {
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];                
                if(this.state.allBrandSearchBoxAnotherExclutionFlag)
                {
                    if(this.state.allCategorySelectedExcludeLabels==="" || this.state.allCategorySelectedExcludeIds.length<=0){
                        this.setState({errorAllCategorySelectedExcludeFlag:true});
                        return false;
                    }
                    if(this.state.allBrandAnotherExclutionSelectedLabels==="" || this.state.allBrandAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllBrandAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategorySelectedExcludeIds},
                                                                        {"brands":this.state.allBrandAnotherExclutionSelectedIds}
                                                                    ]
                                                                }
                                                        });
                }				
                else{
                    if(this.state.allCategoryAnotherExclutionSelectedLabels==="" || this.state.allCategoryAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllCategoryAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    if(this.state.allBrandSelectedExcludeLabels==="" || this.state.allBrandSelectedExcludeIds.length<=0){
                        this.setState({errorAllBrandSelectedExcludeFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategoryAnotherExclutionSelectedIds},
                                                                        {"brands":this.state.allBrandSelectedExcludeIds}
                                                                    ]
                                                                }
                                                        });
                }
                if(this.state.individualProductSelectedIds.length <= 0 || this.state.individualProductSelectedLabels === "")
                {
                    this.setState({errorIndividualProductSelectedBoxFlag: true})
                    return false;
                }
                rule.condition.cart.items.and.push({"products":this.state.individualProductSelectedIds})
            }
            else if(!this.state.addAnotherExclutionRuleBtnFlag &&  !this.state.addExclutionRuleBtnFlag && this.state.ifTheCustomerIncludingProductsChangeValue=="all_products" && (this.state.excludingAllCategorySearchBoxFlag || this.state.excludingAllBrandSearchBoxFlag))
            {
                if(this.state.allBrandSearchBoxAnotherExclutionFlag)
                {   
                    if(this.state.allCategorySelectedExcludeLabels==="" || this.state.allCategorySelectedExcludeIds.length<=0){
                        this.setState({errorAllCategorySelectedExcludeFlag:true});
                        return false;
                    }
                    if(this.state.allBrandAnotherExclutionSelectedLabels==="" || this.state.allBrandAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllBrandAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategorySelectedExcludeIds},
                                                                        {"brands":this.state.allBrandAnotherExclutionSelectedIds}
                                                                    ]
                                                                }
                                                        });
                }
                else
                {   
                    if(this.state.allCategoryAnotherExclutionSelectedLabels==="" || this.state.allCategoryAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllCategoryAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    if(this.state.allBrandSelectedExcludeLabels==="" || this.state.allBrandSelectedExcludeIds.length<=0){
                        this.setState({errorAllBrandSelectedExcludeFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategoryAnotherExclutionSelectedIds},
                                                                        {"brands":this.state.allBrandSelectedExcludeIds}
                                                                    ]
                                                                }
                                                        });
                }
            }
            else if(!this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.allCategorySearchBoxFlag && (this.state.excludingAllCategorySearchBoxFlag || this.state.excludingAllBrandSearchBoxFlag))
            {   
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                if(this.state.allCategorySelectedIds.length <= 0 || this.state.allCategorySelectedLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allBrandSearchBoxAnotherExclutionFlag)
                {   
                    if(this.state.allCategorySelectedExcludeLabels==="" || this.state.allCategorySelectedExcludeIds.length<=0){
                        this.setState({errorAllCategorySelectedExcludeFlag:true});
                        return false;
                    }
                    if(this.state.allBrandAnotherExclutionSelectedLabels==="" || this.state.allBrandAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllBrandAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategorySelectedExcludeIds},
                                                                        {"brands":this.state.allBrandAnotherExclutionSelectedIds}
                                                                    ]
                                                                }
                                                        });
                }				
                else
                {
                    if(this.state.allCategoryAnotherExclutionSelectedLabels==="" || this.state.allCategoryAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllCategoryAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    if(this.state.allBrandSelectedExcludeLabels==="" || this.state.allBrandSelectedExcludeIds.length<=0){
                        this.setState({errorAllBrandSelectedExcludeFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategoryAnotherExclutionSelectedIds},
                                                                        {"brands":this.state.allBrandSelectedExcludeIds}
                                                                    ]
                                                                }
                                                        });
                }
                rule.condition.cart.items.and.push({"categories":this.state.allCategorySelectedIds})
            }
            else if(!this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && this.state.addAnotherInclutionRuleBtnFlag && this.state.allBrandSearchBoxFlag && (this.state.excludingAllCategorySearchBoxFlag || this.state.excludingAllBrandSearchBoxFlag))
            {
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allBrandSearchBoxAnotherExclutionFlag)
                {
                    if(this.state.allCategorySelectedExcludeLabels==="" || this.state.allCategorySelectedExcludeIds.length<=0){
                        this.setState({errorAllCategorySelectedExcludeFlag:true});
                        return false;
                    }
                    if(this.state.allBrandAnotherExclutionSelectedLabels==="" || this.state.allBrandAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllBrandAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategorySelectedExcludeIds},
                                                                        {"brands":this.state.allBrandAnotherExclutionSelectedIds}
                                                                    ]
                                                                }
                                                        });
                }				
                else
                {
                    if(this.state.allCategoryAnotherExclutionSelectedLabels==="" || this.state.allCategoryAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllCategoryAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    if(this.state.allBrandSelectedExcludeLabels==="" || this.state.allBrandSelectedExcludeIds.length<=0){
                        this.setState({errorAllBrandSelectedExcludeFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategoryAnotherExclutionSelectedIds},
                                                                        {"brands":this.state.allBrandSelectedExcludeIds}
                                                                    ]
                                                                }
                                                        });
                }
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds})
            }
            else if(!this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.allCategorySearchBoxFlag && this.state.allBrandSearchBoxAnotherInclutionFlag && (this.state.excludingAllCategorySearchBoxFlag || this.state.excludingAllBrandSearchBoxFlag))
            {
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                if(this.state.allCategorySelectedIds.length <= 0 || this.state.allCategorySelectedLabels === "")
                {
                    this.setState({errorAllCategorySelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allBrandAnotherInclutionSelectedLabels==="" || this.state.allBrandAnotherInclutionSelectedIds.length<=0){
                    this.setState({errorAllBrandAnotherSelectedBoxFlag:true});
                    return false;
                }
                if(this.state.allBrandSearchBoxAnotherExclutionFlag)
                {
                    if(this.state.allCategorySelectedExcludeLabels==="" || this.state.allCategorySelectedExcludeIds.length<=0){
                        this.setState({errorAllCategorySelectedExcludeFlag:true});
                        return false;
                    }
                    if(this.state.allBrandAnotherExclutionSelectedLabels==="" || this.state.allBrandAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllBrandAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategorySelectedExcludeIds},
                                                                        {"brands":this.state.allBrandAnotherExclutionSelectedIds}
                                                                    ]
                                                                }
                                                        });
                }				
                else{
                    if(this.state.allCategoryAnotherExclutionSelectedLabels==="" || this.state.allCategoryAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllCategoryAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    if(this.state.allBrandSelectedExcludeLabels==="" || this.state.allBrandSelectedExcludeIds.length<=0){
                        this.setState({errorAllBrandSelectedExcludeFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategoryAnotherExclutionSelectedIds},
                                                                        {"brands":this.state.allBrandSelectedExcludeIds}
                                                                    ]
                                                                }
                                                        });
                }
                rule.condition.cart.items.and.push({"categories":this.state.allCategorySelectedIds})
                rule.condition.cart.items.and.push({"brands":this.state.allBrandAnotherInclutionSelectedIds})
            }
            else if(!this.state.addAnotherExclutionRuleBtnFlag && !this.state.addExclutionRuleBtnFlag && !this.state.addAnotherInclutionRuleBtnFlag && this.state.allBrandSearchBoxFlag && this.state.allCategorySearchBoxAnotherInclutionFlag && (this.state.excludingAllCategorySearchBoxFlag || this.state.excludingAllBrandSearchBoxFlag))
            {
                rule.condition.cart.items = {}
                rule.condition.cart.items.and = [];
                if(this.state.allBrandSelectedIds.length <= 0 || this.state.allBrandSelectedLabels === "")
                {
                    this.setState({errorAllBrandSelectedBoxFlag: true});
                    return false;
                }
                if(this.state.allCategoryAnotherInclutionSelectedIds.length <= 0 || this.state.allCategoryAnotherInclutionSelectedLabels === "")
                {
                    this.setState({errorAllCategoryAnotherSelectedBoxFlag: true})
                    return false;
                }
                if(this.state.allBrandSearchBoxAnotherExclutionFlag)
                {
                    if(this.state.allCategorySelectedExcludeLabels==="" || this.state.allCategorySelectedExcludeIds.length<=0){
                        this.setState({errorAllCategorySelectedExcludeFlag:true});
                        return false;
                    }
                    if(this.state.allBrandAnotherExclutionSelectedLabels==="" || this.state.allBrandAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllBrandAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategorySelectedExcludeIds},
                                                                        {"brands":this.state.allBrandAnotherExclutionSelectedIds}
                                                                    ]
                                                                }
                                                        });
                }				
                else
                {
                    if(this.state.allBrandSelectedExcludeLabels==="" || this.state.allBrandSelectedExcludeIds.length<=0){
                        this.setState({errorAllBrandSelectedExcludeFlag:true});
                        return false;
                    }
                    if(this.state.allCategoryAnotherExclutionSelectedLabels==="" || this.state.allCategoryAnotherExclutionSelectedIds.length<=0){
                        this.setState({errorAllCategoryAnotherExclutionSelectedFlag:true});
                        return false;
                    }
                    rule.condition.cart.items.and.push({"not":{"and":[
                                                                        {"categories":this.state.allCategoryAnotherExclutionSelectedIds},
                                                                        {"brands":this.state.allBrandSelectedExcludeIds}
                                                                    ]
                                                                }
                                                        });
                }
                rule.condition.cart.items.and.push({"categories":this.state.allCategoryAnotherInclutionSelectedIds})
                rule.condition.cart.items.and.push({"brands":this.state.allBrandSelectedIds})
            }
            
        }

        // validating & prepareing reward 
        if(!this.validate1stSelectRewardField()) return false;
        if(this.state.rewardGiftInTheCartFlag){
            if(!this.validateRewardGiftCartQuantityValue()) return false;            
            if(this.state.rewardGiftInTheCartOnceUnlimited==="unlimited_times")
                rule.apply_once = false;
            if(Number(this.state.rewardIndividualProductGiftInCartSelectedIds) <= 0 
                || this.state.rewardIndividualProductGiftInCartSelectedIds===undefined) 
                {this.setState({errorRewardIndividualProductGiftInCartFlag: true}); return false;}
                
            rule.action.gift_item = {}; 
            rule.action.gift_item.quantity = this.state.rewardGiftCartQuantity;
            rule.action.gift_item.product_id = this.state.rewardIndividualProductGiftInCartSelectedIds;
            ruleRewardData.rewardName = "A gift in their cart";
        }
        else if(this.state.rewardFreeShippingFlag){
            if(!this.state.rewardFreeShippingAllZoneFlag && !this.state. rewardFreeShippingSelectedZoneFlag)
                {this.setState({errorRewardFreeShippingZoneFlag: true}); return false;}
            if(this.state.rewardFreeShippingAllZoneFlag){
                rule.action.shipping = {}; 
                rule.action.shipping.free_shipping = true;
                rule.action.shipping.zone_ids = "*";
            }
            else if(this.state.rewardFreeShippingSelectedZoneFlag){
                if(this.state.rewardFreeShippingZoneSelectedIds.length<=0)
                    {this.setState({errorRewardFreeShippingSelectedZoneFlag: true}); return false;}
                rule.action.shipping = {}; 
                rule.action.shipping.free_shipping = true;
                rule.action.shipping.zone_ids = this.state.rewardFreeShippingZoneSelectedIds;
            }
            ruleRewardData.rewardName = "Free shipping";           
        }        
        else if(this.state.rewardDiscountOnOrderSubtotalFlag){
            
            if(this.state.rewardDiscountOnOrderSubtotalOnceUnlimited==="unlimited_times")
                rule.apply_once = false;            
             
            if(this.state.rewardDiscountOnOrderSubtotalByValPersData==="value"){
                if(Number(this.state.rewardDiscountOnOrderSubtotalVal) <= 0 
                || isNaN(Number(this.state.rewardDiscountOnOrderSubtotalVal))
                || Number(this.state.rewardDiscountOnOrderSubtotalVal) === undefined
                || this.state.rewardDiscountOnOrderSubtotalVal ===""
                ){
                    this.setState({errorRewardDiscountOnOrderSubtotalValPer:true})
                    return false;
                }
                rule.action.cart_value = {}; 
                rule.action.cart_value.discount = {"fixed_amount": this.state.rewardDiscountOnOrderSubtotalVal}; 
                ruleRewardData.rewardName = `$${this.state.rewardDiscountOnOrderSubtotalVal} Discount on order subtotal`;
            }
            else if(this.state.rewardDiscountOnOrderSubtotalByValPersData==="percentage"){
                if(Number(this.state.rewardDiscountOnOrderSubtotalPer) <= 0 
                || isNaN(Number(this.state.rewardDiscountOnOrderSubtotalPer))
                || Number(this.state.rewardDiscountOnOrderSubtotalPer) === undefined
                || this.state.rewardDiscountOnOrderSubtotalPer ===""
                || Number(this.state.rewardDiscountOnOrderSubtotalPer) > 100
                ){
                    this.setState({errorRewardDiscountOnOrderSubtotalValPer:true})
                    return false;
                }
                rule.action.cart_value = {}; 
                rule.action.cart_value.discount = {"percentage_amount": this.state.rewardDiscountOnOrderSubtotalPer};
                ruleRewardData.rewardName = `${this.state.rewardDiscountOnOrderSubtotalPer}% Discount on order subtotal`; 
            } 
        }
        else if(this.state.rewardDiscountOnProductsFlag){
            if(this.state.rewardDiscountOnProductOnceOrNTimeSelectVal==="unlimited_times")
                rule.apply_once = false; 

            rule.action.cart_items = {};
            rule.action.cart_items.add_free_item = false;
            rule.action.cart_items.exclude_items_on_sale = !this.state.rewardExcludeItemsOnSale; 
            rule.action.cart_items.include_items_considered_by_condition = this.state.rewardIncludeItemsConsideredByCondition;
            rule.action.cart_items.as_total = this.state.rewardAsTotal;
            
            if(this.state.rewardDiscountOnProductsSelectData=="amount"){
				if(Number(this.state.rewardDiscountOnProductsByVal) <= 0 
					|| isNaN(Number(this.state.rewardDiscountOnProductsByVal))
					|| Number(this.state.rewardDiscountOnProductsByVal) === undefined
					|| this.state.rewardDiscountOnProductsByVal ===""
				  ){
					this.setState({errorRewardDiscountOnProductsSelectAmountPers:true});
                    return false;
				} 
                rule.action.cart_items.discount = {"fixed_amount": this.state.rewardDiscountOnProductsByVal}; 
                ruleRewardData.rewardName = `$${this.state.rewardDiscountOnProductsByVal} Discount on products`;
				
            }
            else if(this.state.rewardDiscountOnProductsSelectData==="percentage"){
				if(Number(this.state.rewardDiscountOnProductsByPer) <= 0 
					|| isNaN(Number(this.state.rewardDiscountOnProductsByPer))
					|| Number(this.state.rewardDiscountOnProductsByPer) === undefined
					|| this.state.rewardDiscountOnProductsByPer ===""
                    || Number(this.state.rewardDiscountOnProductsByPer) > 100
				  ){
					this.setState({errorRewardDiscountOnProductsSelectAmountPers:true})
                    return false;
				}
                rule.action.cart_items.discount = {"percentage_amount": this.state.rewardDiscountOnProductsByPer};
                ruleRewardData.rewardName = `${this.state.rewardDiscountOnProductsByPer}% Discount on products`; 
				
            }
            if(this.state.rewardDiscountOnProductsAppliedOnSelectData==="upto"){
                if(Number(this.state.rewardDisOnProdQuntCount) <= 0 
					|| isNaN(Number(this.state.rewardDisOnProdQuntCount))
					|| Number(this.state.rewardDisOnProdQuntCount) === undefined
					|| this.state.rewardDisOnProdQuntCount ===""
				  ){
					this.setState({errorRewardDisOnProdQuntCount:true});
                    return false;
				} 
                rule.action.cart_items.quantity = this.state.rewardDisOnProdQuntCount;
            }

            // preparing object for product category and brand options for rewards
            let items = this.setRewardIncExcProdCatBrand();
            
            if(typeof(items) === "boolean" && items === false){
                return false;
            }
            else if(typeof(items) === "boolean" && items === true){
                // for all products
            }
            else if(Object.keys(items).length > 0){
                rule.action.cart_items.items = items;
            }            
        }
        else if(this.state.rewardFixedPriceForOfProductFlag){
            ruleRewardData.rewardName = "Fixed price for # of products";
            if(this.state.rewardFixedPriceForOfProductSelectVal==="unlimited_times")
                rule.apply_once = false; 
            
            rule.action.fixed_price_set = {}; 

            if(Number(this.state.rewardFixedPriceProductsByVal) <= 0 
                || isNaN(Number(this.state.rewardFixedPriceProductsByVal))
                || Number(this.state.rewardFixedPriceProductsByVal) === undefined
                || this.state.rewardFixedPriceProductsByVal ===""
                ){
                this.setState({errorRewardFixedPriceProductsByVal:true});
                return false;
            }
            if(Number(this.state.rewardFixedProdQuntCount) <= 0 
                || isNaN(Number(this.state.rewardFixedProdQuntCount))
                || Number(this.state.rewardFixedProdQuntCount) === undefined
                || this.state.rewardFixedProdQuntCount ===""
                ){
                this.setState({errorRewardFixedProdQuntCount:true});
                return false;
            }
            rule.action.fixed_price_set.fixed_price = this.state.rewardFixedPriceProductsByVal;
            rule.action.fixed_price_set.exclude_items_on_sale = !this.state.rewardFixedPriceProductsIncProduct;
            rule.action.fixed_price_set.quantity = this.state.rewardFixedProdQuntCount;
            rule.action.fixed_price_set.strategy = this.state.rewardFixedProdStrategy
            
            // preparing object for product category and brand options for rewards
            let items = this.setRewardIncExcProdCatBrand();
            
            if(typeof(items) == "boolean" && items === false){
                return false;
            }
            else if(typeof(items) == "boolean" && items === true){
                // for all products
            }
            else if(typeof(items) === "object" && Object.keys(items).length > 0){
                rule.action.fixed_price_set.items = items;
            }
        }

        // after preparing full rule and reward payload
        ruleRewardData.rule = rule;
        

        console.group("Rule & Reward ", ruleRewardData)
        //submiting data to parent page
        this.props.history.push({pathname: '/create-promotion', createPromotionFormData: { ...this.props.location.pageData}, ruleRewardData:ruleRewardData})
    }

    setRewardIncExcProdCatBrand = () => {
        let item = {};
        
        if(this.state.rewardIncludingProductVal === ""){
            this.setState({errorRewardIncludingProductValFlag:true})
            return false;
        }
        else if(this.state.rewardIncludingProductVal === "all_products"){
            if(this.state.rewardAddExclutionRuleBtnFlag) return true;
            if(!this.state.rewardAddExclutionRuleBtnFlag){
                if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                    this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                    return false;
                }
                if(this.state.rewardExcludingIndividualProductSearchBoxFlag){
                    if(this.state.rewardIndividualProductSelectedExcludeIds.length<=0 ||
                        this.state.rewardIndividualProductSelectedExcludeLabels===""){
                            this.setState({errorRewardIndividualProductSelectedExcludeFlag: true});
                            return false;
                    }
                    item.not = {"products":this.state.rewardIndividualProductSelectedExcludeIds};
                    return item;
                }
                else if(!this.state.rewardAllBrandSearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllCategorySearchBoxFlag){
                    if(this.state.rewardAllCategorySelectedExcludeIds.length<=0 ||
                        this.state.rewardAllCategorySelectedExcludeLabels===""){
                            this.setState({errorRewardAllCategorySelectedExcludeFlag: true});
                            return false;
                    }
                    item.not = {"categories":this.state.rewardAllCategorySelectedExcludeIds};
                    return item;
                }
                else if(this.state.rewardExcludingAllBrandSearchBoxFlag 
                    && !this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                    if(this.state.rewardAllBrandSelectedExcludeIds.length<=0 ||
                        this.state.rewardAllBrandSelectedExcludeLabels===""){
                            this.setState({errorRewardAllBrandSelectedExcludeFlag: true});
                            return false;
                    }
                    item.not = {"brands":this.state.rewardAllBrandSelectedExcludeIds};
                    return item;
                }
                else if(this.state.rewardAllBrandSearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllCategorySearchBoxFlag){
                        if(this.state.rewardAllBrandAnotherExclutionSelectedIds.length<=0 ||
                            this.state.rewardAllBrandAnotherExclutionSelectedLabels===""){
                                this.setState({errorRewardAllBrandAnotherExclutionSelectedFlag: true});
                                return false;
                        }
                        if(this.state.rewardAllCategorySelectedExcludeIds.length<=0 ||
                            this.state.rewardAllCategorySelectedExcludeLabels===""){
                                this.setState({errorRewardAllCategorySelectedExcludeFlag: true});
                                return false;
                        }
                    item.not={}
                    item.not.and = []
                    item.not.and.push({"brands" : this.state.rewardAllBrandAnotherExclutionSelectedIds});
                    item.not.and.push({"categories" : this.state.rewardAllCategorySelectedExcludeIds});
                    return item;
                }
                else if(this.state.rewardExcludingAllBrandSearchBoxFlag 
                    && this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                        if(this.state.rewardAllBrandSelectedExcludeIds.length<=0 ||
                            this.state.rewardAllBrandSelectedExcludeLabels===""){
                                this.setState({errorRewardAllBrandSelectedExcludeFlag: true});
                                return false;
                        }
                        if(this.state.rewardAllCategoryAnotherExclutionSelectedIds.length<=0 ||
                            this.state.rewardAllCategoryAnotherExclutionSelectedLabels===""){
                                this.setState({errorRewardAllCategoryAnotherExclutionSelectedFlag: true});
                                return false;
                        }
                    item.not={}
                    item.not.and = []
                    item.not.and.push({"brands" : this.state.rewardAllBrandSelectedExcludeIds});
                    item.not.and.push({"categories" : this.state.rewardAllCategoryAnotherExclutionSelectedIds});
                    return item;
                }
                else return false;
            }
            
            else return false
        }
        if(this.state.rewardIncludingProductVal === "individual_products"){
            if(this.state.individualProductSelectedRewardIds.length <= 0 ||
                this.state.individualProductSelectedRewardLabels === ""){
                    this.setState({errorIndividualProductSelectedRewardFlag:true})
                    return false;
            }
            if(this.state.rewardAddExclutionRuleBtnFlag){
                item.products = this.state.individualProductSelectedRewardIds;
                return item;
            }
            if(!this.state.rewardAddExclutionRuleBtnFlag){
                if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                    this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                    return false;
                }
                if(this.state.rewardExcludingIndividualProductSearchBoxFlag){
                    if(this.state.rewardIndividualProductSelectedExcludeIds.length <= 0 ||
                        this.state.rewardIndividualProductSelectedExcludeLabels === ""){
                            this.setState({errorRewardIndividualProductSelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"not":{"products":this.state.rewardIndividualProductSelectedExcludeIds}});
                    item.and.push({"products":this.state.individualProductSelectedRewardIds});
                    return item;
                }
                else if(!this.state.rewardAllBrandSearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllCategorySearchBoxFlag){
                        if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllCategorySelectedExcludeLabels === ""){
                                this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"not":{"categories":this.state.rewardAllCategorySelectedExcludeIds}});
                    item.and.push({"products":this.state.individualProductSelectedRewardIds});
                    return item;
                }
                else if(this.state.rewardExcludingAllBrandSearchBoxFlag 
                    && !this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                        if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllBrandSelectedExcludeLabels === ""){
                                this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"not":{"brands":this.state.rewardAllBrandSelectedExcludeIds}});
                    item.and.push({"products":this.state.individualProductSelectedRewardIds});
                    return item;
                }
                else if(this.state.rewardAllBrandSearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllCategorySearchBoxFlag){
                        if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllCategorySelectedExcludeLabels === ""){
                                this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                                return false;
                        }
                        if(this.state.rewardAllBrandAnotherExclutionSelectedIds.length <= 0 ||
                            this.state.rewardAllBrandAnotherExclutionSelectedLabels === ""){
                                this.setState({errorRewardAllBrandAnotherExclutionSelectedFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"products":this.state.individualProductSelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"brands" : this.state.rewardAllBrandAnotherExclutionSelectedIds});
                    notTmp.and.push({"categories" : this.state.rewardAllCategorySelectedExcludeIds});
                    item.and.push({"not": notTmp});
                    return item;
                }
                else if(this.state.rewardExcludingAllBrandSearchBoxFlag 
                    && this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                        if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllBrandSelectedExcludeLabels === ""){
                                this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                                return false;
                        }
                        if(this.state.rewardAllCategoryAnotherExclutionSelectedIds.length <= 0 ||
                            this.state.rewardAllCategoryAnotherExclutionSelectedLabels === ""){
                                this.setState({errorRewardAllCategoryAnotherExclutionSelectedFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"products":this.state.individualProductSelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"brands" : this.state.rewardAllBrandSelectedExcludeIds});
                    notTmp.and.push({"categories" : this.state.rewardAllCategoryAnotherExclutionSelectedIds});
                    item.and.push({"not": notTmp});
                    return item;
                }
                else return false;               
            }
            else return false;
        }
        else if(this.state.rewardIncludingProductVal === "all_category"){
            if(this.state.allCategorySelectedRewardIds.length<=0 ||
                this.state.allCategorySelectedRewardLabels===""){
                    this.setState({errorAllCategorySelectedRewardFlag: true});
                    return false;
            }
            
            if(this.state.rewardAddExclutionRuleBtnFlag 
                && this.state.addAnotherInclutionRuleRewardBtnFlag){
                item.categories = this.state.allCategorySelectedRewardIds;
                return item;
            }
            else if(this.state.rewardAddExclutionRuleBtnFlag 
                && !this.state.addAnotherInclutionRuleRewardBtnFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                    if(this.state.allBrandAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allBrandAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllBrandAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                item.and = []
                item.and.push({"categories":this.state.allCategorySelectedRewardIds});
                item.and.push({"brands":this.state.allBrandAnotherInclutionRewardSelectedIds});
                return item;
            }
            else if(!this.state.rewardAddExclutionRuleBtnFlag 
                && this.state.addAnotherInclutionRuleRewardBtnFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                if(this.state.rewardExcludingIndividualProductSearchBoxFlag){
                    if(this.state.rewardIndividualProductSelectedExcludeIds.length <= 0 ||
                        this.state.rewardIndividualProductSelectedExcludeLabels === ""){
                            this.setState({errorRewardIndividualProductSelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"not":{"products":this.state.rewardIndividualProductSelectedExcludeIds}});
                    item.and.push({"categories":this.state.allCategorySelectedRewardIds});
                    return item;
                }
                else if(!this.state.rewardAllBrandSearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllCategorySearchBoxFlag){
                        if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllCategorySelectedExcludeLabels === ""){
                                this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"not":{"categories":this.state.rewardAllCategorySelectedExcludeIds}});
                    item.and.push({"categories":this.state.allCategorySelectedRewardIds});
                    return item;
                }
                else if(this.state.rewardExcludingAllBrandSearchBoxFlag 
                    && !this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                        if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllBrandSelectedExcludeLabels === ""){
                                this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"not":{"brands":this.state.rewardAllBrandSelectedExcludeIds}});
                    item.and.push({"categories":this.state.allCategorySelectedRewardIds});
                    return item;
                }
                else if(this.state.rewardAllBrandSearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllCategorySearchBoxFlag){
                        if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllCategorySelectedExcludeLabels === ""){
                                this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                                return false;
                        }
                        if(this.state.rewardAllBrandAnotherExclutionSelectedIds.length <= 0 ||
                            this.state.rewardAllBrandAnotherExclutionSelectedLabels === ""){
                                this.setState({errorRewardAllBrandAnotherExclutionSelectedFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"categories":this.state.allCategorySelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"brands" : this.state.rewardAllBrandAnotherExclutionSelectedIds});
                    notTmp.and.push({"categories" : this.state.rewardAllCategorySelectedExcludeIds});
                    item.and.push({"not": notTmp});
                    return item;
                }
                else if(this.state.rewardExcludingAllBrandSearchBoxFlag 
                    && this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                        if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllBrandSelectedExcludeLabels === ""){
                                this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                                return false;
                        }
                        if(this.state.rewardAllCategoryAnotherExclutionSelectedIds.length <= 0 ||
                            this.state.rewardAllCategoryAnotherExclutionSelectedLabels === ""){
                                this.setState({errorRewardAllCategoryAnotherExclutionSelectedFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"categories":this.state.allCategorySelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"brands" : this.state.rewardAllBrandSelectedExcludeIds});
                    notTmp.and.push({"categories" : this.state.rewardAllCategoryAnotherExclutionSelectedIds});
                    item.and.push({"not": notTmp});
                    return item;
                }
                else return false;               
            }
            else if(this.state.rewardAllCategorySearchBoxFlag 
                && this.state.allBrandSearchBoxAnotherInclutionRewardFlag
                && !this.state.rewardAddExclutionRuleBtnFlag
                && this.state.rewardExcludingIndividualProductSearchBoxFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                    if(this.state.allBrandAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allBrandAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllBrandAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardIndividualProductSelectedExcludeIds.length <= 0 ||
                        this.state.rewardIndividualProductSelectedExcludeLabels === ""){
                            this.setState({errorRewardIndividualProductSelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"brands" : this.state.allBrandAnotherInclutionRewardSelectedIds});
                    item.and.push({"categories" : this.state.allCategorySelectedRewardIds});
                    item.and.push({"not":{"products":this.state.rewardIndividualProductSelectedExcludeIds}});
                    return item;
            }
            else if(this.state.rewardAllCategorySearchBoxFlag 
                && this.state.allBrandSearchBoxAnotherInclutionRewardFlag
                && !this.state.rewardAddExclutionRuleBtnFlag
                && this.state.rewardExcludingAllCategorySearchBoxFlag
                && !this.state.rewardAllBrandSearchBoxAnotherExclutionFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                    if(this.state.allBrandAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allBrandAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllBrandAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllCategorySelectedExcludeLabels === ""){
                            this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"brands" : this.state.allBrandAnotherInclutionRewardSelectedIds});
                    item.and.push({"categories" : this.state.allCategorySelectedRewardIds});
                    item.and.push({"not":{"categories":this.state.rewardAllCategorySelectedExcludeIds}});
                    return item;
            }
            else if(this.state.rewardAllCategorySearchBoxFlag 
                && this.state.allBrandSearchBoxAnotherInclutionRewardFlag
                && !this.state.rewardAddExclutionRuleBtnFlag
                && this.state.rewardExcludingAllBrandSearchBoxFlag
                && !this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                    if(this.state.allBrandAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allBrandAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllBrandAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllBrandSelectedExcludeLabels === ""){
                            this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"brands" : this.state.allBrandAnotherInclutionRewardSelectedIds});
                    item.and.push({"categories" : this.state.allCategorySelectedRewardIds});
                    item.and.push({"not":{"brands":this.state.rewardAllBrandSelectedExcludeIds}});
                    return item;
            }
            else if(this.state.rewardAllCategorySearchBoxFlag 
                && this.state.allBrandSearchBoxAnotherInclutionRewardFlag
                && this.state.rewardExcludingAllCategorySearchBoxFlag
                && this.state.rewardAllBrandSearchBoxAnotherExclutionFlag){
                    if(this.state.allBrandAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allBrandAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllBrandAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllCategorySelectedExcludeLabels === ""){
                            this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllBrandAnotherExclutionSelectedIds.length <= 0 ||
                        this.state.rewardAllBrandAnotherExclutionSelectedLabels === ""){
                            this.setState({errorRewardAllBrandAnotherExclutionSelectedFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"brands" : this.state.allBrandAnotherInclutionRewardSelectedIds});
                    item.and.push({"categories" : this.state.allCategorySelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"brands" : this.state.rewardAllBrandAnotherExclutionSelectedIds});
                    notTmp.and.push({"categories" : this.state.rewardAllCategorySelectedExcludeIds});
                    item.and.push({"not": notTmp});
                    return item;
            }
            else if(this.state.rewardAllCategorySearchBoxFlag 
                && this.state.allBrandSearchBoxAnotherInclutionRewardFlag
                && this.state.rewardExcludingAllBrandSearchBoxFlag
                && this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                    if(this.state.allBrandAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allBrandAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllBrandAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllBrandSelectedExcludeLabels === ""){
                            this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllCategoryAnotherExclutionSelectedIds.length <= 0 ||
                        this.state.rewardAllCategoryAnotherExclutionSelectedLabels === ""){
                            this.setState({errorRewardAllCategoryAnotherExclutionSelectedFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"brands" : this.state.allBrandAnotherInclutionRewardSelectedIds});
                    item.and.push({"categories" : this.state.allCategorySelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"brands" : this.state.rewardAllBrandSelectedExcludeIds});
                    notTmp.and.push({"categories" : this.state.rewardAllCategoryAnotherExclutionSelectedIds});
                    item.and.push({"not": notTmp});
                    return item;
            }
            else return false;
        }
        else if(this.state.rewardIncludingProductVal === "in_brand"){
            if(this.state.allBrandSelectedRewardIds.length<=0 ||
                this.state.allBrandSelectedRewardLabels===""){
                    this.setState({errorAllBrandSelectedRewardFlag: true});
                    return false;
            }
            
            if(this.state.rewardAddExclutionRuleBtnFlag && 
                this.state.addAnotherInclutionRuleRewardBtnFlag){
                item.brands = this.state.allBrandSelectedRewardIds;
                return item;
            }
            else if(this.state.rewardAddExclutionRuleBtnFlag && 
                !this.state.addAnotherInclutionRuleRewardBtnFlag){
                    if(this.state.allCategoryAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allCategoryAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllCategoryAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                item.and = []
                item.and.push({"categories":this.state.allCategoryAnotherInclutionRewardSelectedIds});
                item.and.push({"brands":this.state.allBrandSelectedRewardIds});
                return item;
            }
            else if(!this.state.rewardAddExclutionRuleBtnFlag 
                && this.state.addAnotherInclutionRuleRewardBtnFlag){
                if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                    this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                    return false;
                }
                if(this.state.rewardExcludingIndividualProductSearchBoxFlag){
                    if(this.state.rewardIndividualProductSelectedExcludeIds.length <= 0 ||
                        this.state.rewardIndividualProductSelectedExcludeLabels === ""){
                            this.setState({errorRewardIndividualProductSelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"not":{"products":this.state.rewardIndividualProductSelectedExcludeIds}});
                    item.and.push({"brands":this.state.allBrandSelectedRewardIds});
                    return item;
                }
                else if(!this.state.rewardAllCategorySearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllBrandSearchBoxFlag){
                        if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllBrandSelectedExcludeLabels === ""){
                                this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"not":{"brands":this.state.rewardAllBrandSelectedExcludeIds}});
                    item.and.push({"brands":this.state.allBrandSelectedRewardIds});
                    return item;
                }
                else if(this.state.rewardExcludingAllCategorySearchBoxFlag 
                    && !this.state.rewardAllBrandSearchBoxAnotherExclutionFlag){
                        if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllCategorySelectedExcludeLabels === ""){
                                this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"not":{"categories":this.state.rewardAllCategorySelectedExcludeIds}});
                    item.and.push({"brands":this.state.allBrandSelectedRewardIds});
                    return item;
                }
                else if(this.state.rewardAllCategorySearchBoxAnotherExclutionFlag 
                    && this.state.rewardExcludingAllBrandSearchBoxFlag){
                        if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllBrandSelectedExcludeLabels === ""){
                                this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                                return false;
                        }
                        if(this.state.rewardAllCategoryAnotherExclutionSelectedIds.length <= 0 ||
                            this.state.rewardAllCategoryAnotherExclutionSelectedLabels === ""){
                                this.setState({errorRewardAllCategoryAnotherExclutionSelectedFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"brands":this.state.allBrandSelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"categories" : this.state.rewardAllCategoryAnotherExclutionSelectedIds});
                    notTmp.and.push({"brands" : this.state.rewardAllBrandSelectedExcludeIds});
                    item.and.push({"not": notTmp});
                    return item;
                }
                else if(this.state.rewardExcludingAllCategorySearchBoxFlag 
                    && this.state.rewardAllBrandSearchBoxAnotherExclutionFlag){
                        if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                            this.state.rewardAllCategorySelectedExcludeLabels === ""){
                                this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                                return false;
                        }
                        if(this.state.rewardAllBrandAnotherExclutionSelectedIds.length <= 0 ||
                            this.state.rewardAllBrandAnotherExclutionSelectedLabels === ""){
                                this.setState({errorRewardAllBrandAnotherExclutionSelectedFlag:true})
                                return false;
                        }
                    item.and=[];
                    item.and.push({"brands":this.state.allBrandSelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"categories" : this.state.rewardAllCategorySelectedExcludeIds});
                    notTmp.and.push({"brands" : this.state.rewardAllBrandAnotherExclutionSelectedIds});
                    item.and.push({"not": notTmp});
                    return item;
                }
                else return false;               
            }
            else if(this.state.rewardAllBrandSearchBoxFlag 
                && this.state.allCategorySearchBoxAnotherInclutionRewardFlag
                && !this.state.rewardAddExclutionRuleBtnFlag
                && this.state.rewardExcludingIndividualProductSearchBoxFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                    if(this.state.allCategoryAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allCategoryAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllCategoryAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardIndividualProductSelectedExcludeIds.length <= 0 ||
                        this.state.rewardIndividualProductSelectedExcludeLabels === ""){
                            this.setState({errorRewardIndividualProductSelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"categories" : this.state.allCategoryAnotherInclutionRewardSelectedIds});
                    item.and.push({"brands" : this.state.allBrandSelectedRewardIds});
                    item.and.push({"not":{"products":this.state.rewardIndividualProductSelectedExcludeIds}});
                    return item;
            }
            else if(this.state.rewardAllBrandSearchBoxFlag 
                && this.state.allCategorySearchBoxAnotherInclutionRewardFlag
                && !this.state.rewardAddExclutionRuleBtnFlag
                && this.state.rewardExcludingAllBrandSearchBoxFlag
                && !this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                    if(this.state.allCategoryAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allCategoryAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllCategoryAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllBrandSelectedExcludeLabels === ""){
                            this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"categories" : this.state.allCategoryAnotherInclutionRewardSelectedIds});
                    item.and.push({"brands" : this.state.allBrandSelectedRewardIds});
                    item.and.push({"not":{"brands":this.state.rewardAllBrandSelectedExcludeIds}});
                    return item;
            }
            else if(this.state.rewardAllBrandSearchBoxFlag 
                && this.state.allCategorySearchBoxAnotherInclutionRewardFlag
                && !this.state.rewardAddExclutionRuleBtnFlag
                && this.state.rewardExcludingAllCategorySearchBoxFlag
                && !this.state.rewardAllBrandSearchBoxAnotherExclutionFlag){
                    if(this.state.rewardIfTheCustomerExcludingProductVal===""){
                        this.setState({errorRewardIfTheCustomerExcludingProductFlag: true})
                        return false;
                    }
                    if(this.state.allCategoryAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allCategoryAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllCategoryAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllCategorySelectedExcludeLabels === ""){
                            this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"categories" : this.state.allCategoryAnotherInclutionRewardSelectedIds});
                    item.and.push({"brands" : this.state.allBrandSelectedRewardIds});
                    item.and.push({"not":{"categories":this.state.rewardAllCategorySelectedExcludeIds}});
                    return item;
            }
            else if(this.state.rewardAllBrandSearchBoxFlag 
                && this.state.allCategorySearchBoxAnotherInclutionRewardFlag
                && this.state.rewardExcludingAllBrandSearchBoxFlag
                && this.state.rewardAllCategorySearchBoxAnotherExclutionFlag){
                    if(this.state.allCategoryAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allCategoryAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllCategoryAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllBrandSelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllBrandSelectedExcludeLabels === ""){
                            this.setState({errorRewardAllBrandSelectedExcludeFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllCategoryAnotherExclutionSelectedIds.length <= 0 ||
                        this.state.rewardAllCategoryAnotherExclutionSelectedLabels === ""){
                            this.setState({errorRewardAllCategoryAnotherExclutionSelectedFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"categories" : this.state.allCategoryAnotherInclutionRewardSelectedIds});
                    item.and.push({"brands" : this.state.allBrandSelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"categories" : this.state.rewardAllCategoryAnotherExclutionSelectedIds});
                    notTmp.and.push({"brands" : this.state.rewardAllBrandSelectedExcludeIds});
                    item.and.push({"not": notTmp});
                    return item;
            }
            else if(this.state.rewardAllBrandSearchBoxFlag 
                && this.state.allCategorySearchBoxAnotherInclutionRewardFlag
                && this.state.rewardExcludingAllCategorySearchBoxFlag
                && this.state.rewardAllBrandSearchBoxAnotherExclutionFlag){
                    if(this.state.allCategoryAnotherInclutionRewardSelectedIds.length <= 0 ||
                        this.state.allCategoryAnotherInclutionRewardSelectedLabels === ""){
                            this.setState({errorAllCategoryAnotherInclutionRewardSelectedFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllCategorySelectedExcludeIds.length <= 0 ||
                        this.state.rewardAllCategorySelectedExcludeLabels === ""){
                            this.setState({errorRewardAllCategorySelectedExcludeFlag:true})
                            return false;
                    }
                    if(this.state.rewardAllBrandAnotherExclutionSelectedIds.length <= 0 ||
                        this.state.rewardAllBrandAnotherExclutionSelectedLabels === ""){
                            this.setState({errorRewardAllBrandAnotherExclutionSelectedFlag:true})
                            return false;
                    }
                    item.and=[];
                    item.and.push({"categories" : this.state.allCategoryAnotherInclutionRewardSelectedIds});
                    item.and.push({"brands" : this.state.allBrandSelectedRewardIds});
                    let notTmp = {};
                    notTmp.and = [];
                    notTmp.and.push({"categories" : this.state.rewardAllCategorySelectedExcludeIds});
                    notTmp.and.push({"brands" : this.state.rewardAllBrandAnotherExclutionSelectedIds});
                    item.and.push({"not": notTmp});
                    return item;
            }
            else return false;
        }
    }

    /* 
    * validations
    */
    validateRewardGiftCartQuantityValue = () => {
        if(Number(this.state.rewardGiftCartQuantity) <= 0 
            || isNaN(Number(this.state.rewardGiftCartQuantity))
            || Number(this.state.rewardGiftCartQuantity) === undefined
        ){
            this.setState({errorRewardGiftCartQuantityFlag: true})
            return false;
        }
        return true
    }
    validate1stSelectConditionField = () => {        
        if(this.state.ifTheCustomerSelectValue === "")
        {
            this.setState({errorConditionFlag : true});
            return false;
        }        
        return true
    }
    validate1stSelectRewardField = () => {        
        if(this.state.thenRewardSelectValue === "")
        {
            this.setState({errorActionFlag : true});
            return false;
        }        
        return true
    }
    validateReachesMinSpend = () => {
        if(Number(this.state.reachesAnOrderSubTotalValue) <= 0 
            || isNaN(Number(this.state.reachesAnOrderSubTotalValue))
            || Number(this.state.reachesAnOrderSubTotalValue) === undefined) {
            this.setState({errorReachesAnOrderSubTotalValue: true})
            return false;
        }
        return true
    }
    validateReachingQuantityTotalSelect = () => {
        if(!this.state.ifCustomerConditionQuantityFlag && !this.state.ifCustomerConditionTotalFlag)
        {
            this.setState({errorConditionQuantityTotalFlag : true});
            return false;
        }        
        return true
    }
    validateReachingQuantityValue = () => {
        if(this.state.ifCustomerConditionQuantityFlag 
            &&(Number(this.state.ifCustomerConditionQuantityValue) <= 0 
            || isNaN(Number(this.state.ifCustomerConditionQuantityValue))
            || Number(this.state.ifCustomerConditionQuantityValue) === undefined)
        ) {
            this.setState({errorIfCustomerConditionQuantityFlag: true})
            return false;
        }
        return true
    }
    validateReachingTotalValue = () => {
        if(this.state.ifCustomerConditionTotalFlag 
            &&(Number(this.state.ifCustomerConditionTotalValue) <= 0 
            || isNaN(Number(this.state.ifCustomerConditionTotalValue))
            || Number(this.state.ifCustomerConditionTotalValue) === undefined)
        ) {
            this.setState({errorIfCustomerConditionTotalFlag: true})
            return false;
        }
        return true
    }
    validateIfCustomerConditionIncludingProductSelect = () => {
        if(this.state.ifTheCustomerIncludingProductsChangeValue === ""){
                this.setState({errorIndividualProductSelectedFlag: true})
                return false;
            }
        return true
    }
    validateSimpleIncludingProductInput = () => {
        if(this.state.individualProductSearchBoxFlag 
            && (this.state.individualProductSelectedLabels===""
                || this.state.individualProductSelectedIds.length<=0)){
                this.setState({errorIndividualProductSelectedBoxFlag: true})
                return false;
            }
        return true;
    }
    validateAllCategoryInput = () => {
        if(this.state.allCategorySearchBoxFlag 
            && (this.state.allCategorySelectedLabels===""
                || this.state.allCategorySelectedIds.length<=0)){
                this.setState({errorAllCategorySelectedBoxFlag: true})
                return false;
            }
        return true
    }
    validateAllBrandInput = () => {
        if(this.state.allBrandSearchBoxFlag 
            && (this.state.allBrandSelectedLabels===""
                || this.state.allBrandSelectedIds.length<=0)){
                this.setState({errorAllBrandSelectedBoxFlag: true})
                return false;
            }
        return true
    }    
    validateAllCategoryAnotherInput = () => {
        if(this.state.allCategorySearchBoxAnotherInclutionFlag 
            && (this.state.allCategoryAnotherInclutionSelectedLabels===""
                || this.state.allCategoryAnotherInclutionSelectedIds.length<=0)){
                this.setState({errorAllCategoryAnotherSelectedBoxFlag: true})
                return false;
            }
        return true
    }
    validateAllBrandAnotherInput = () => {
        if(this.state.allBrandSearchBoxAnotherInclutionFlag 
            && (this.state.allBrandAnotherInclutionSelectedLabels===""
                || this.state.allBrandAnotherInclutionSelectedIds.length<=0)){
                this.setState({errorAllBrandAnotherSelectedBoxFlag: true})
                return false;
            }
        return true
    }
    validateIfCustomerConditionIncludingProductExcludeSelect = () => {
        if(!this.state.addExclutionRuleBtnFlag 
            && this.state.ifTheCustomerIncludingProductsExcludeChangeValue === ""){
                this.setState({errorIndividualProductSelectedExcludeFlag: true})
                return false;
            }
        return true
    }
    validateExcludeIncludingProductInput = () => {
        if(!this.state.addExclutionRuleBtnFlag
            && this.state.excludingIndividualProductSearchBoxFlag 
            && (this.state.individualProductSelectedExcludeLabels===""
                || this.state.individualProductSelectedExcludeIds.length<=0)){
                this.setState({errorIndividualProductSelectedExcludeBoxFlag: true})
                return false;
            }
        return true
    }
    validateExcludeAllCategoryInput = () => {
        if(!this.state.addExclutionRuleBtnFlag
            && this.state.excludingAllCategorySearchBoxFlag 
            && (this.state.allCategorySelectedExcludeLabels===""
                || this.state.allCategorySelectedExcludeIds.length<=0)){
                this.setState({errorAllCategorySelectedExcludeBoxFlag: true})
                return false;
            }
        return true
    }
    validateExcludeAllBrandInput = () => {
        if(!this.state.addExclutionRuleBtnFlag
            && this.state.excludingAllBrandSearchBoxFlag 
            && (this.state.allBrandSelectedExcludeLabels===""
                || this.state.allBrandSelectedExcludeIds.length<=0)){
                this.setState({errorAllBrandSelectedExcludeBoxFlag: true})
                return false;
            }
        return true
    }

    /**
     * clear all validation flags
     */
    clearValidationFlags = () => {
        this.setState({errorActionFlag: false});
        this.setState({errorConditionFlag: false});
        this.setState({errorReachesAnOrderSubTotalValue: false});
        this.setState({errorConditionQuantityTotalFlag: false});
        this.setState({errorIfCustomerConditionQuantityFlag: false});
        this.setState({errorIfCustomerConditionTotalFlag: false});
        this.setState({errorIndividualProductSelectedFlag: false});
        this.setState({errorIndividualProductSelectedBoxFlag: false});        
        this.setState({errorIndividualProductSelectedExcludeFlag: false})
        this.setState({errorIndividualProductSelectedExcludeBoxFlag: false});
        this.setState({errorAllCategorySelectedBoxFlag: false});
        this.setState({errorAllBrandSelectedBoxFlag: false});
        this.setState({errorAddAnotherInclutionRuleCategorySelectFlag: false});
        this.setState({errorAddAnotherInclutionRuleBrandSelectFlag: false});
        this.setState({errorAllCategoryAnotherSelectedBoxFlag: false});
        this.setState({errorAllBrandAnotherSelectedBoxFlag: false});
        this.setState({errorRewardGiftCartQuantityFlag: false});
        this.setState({errorRewardIndividualProductGiftInCartFlag: false});
        this.setState({errorRewardFreeShippingZoneFlag: false});
        this.setState({errorRewardFreeShippingSelectedZoneFlag: false});
        this.setState({errorRewardDiscountOnOrderSubtotalValPer: false});
        this.setState({errorRewardDiscountOnProductsSelectAmountPers:false});
        this.setState({errorRewardDisOnProdQuntCount: false});
        this.setState({errorRewardDisOnProdQuntCount: false});
        this.setState({errorRewardFixedPriceProductsByVal: false});
        this.setState({errorRewardFixedProdQuntCount: false});
        this.setState({errorRewardIncludingProductValFlag: false});
        this.setState({showAlert: false});
        this.setState({
            errorAllCategorySelectedExcludeBoxFlag: false,
            errorAllBrandSelectedExcludeBoxFlag: false,
            errorAllCategorySelectedExcludeFlag: false,
            errorAllBrandSelectedExcludeFlag: false,
            errorAllBrandAnotherExclutionSelectedFlag: false,
            errorAllCategoryAnotherExclutionSelectedFlag: false,
            errorIndividualProductSelectedRewardFlag: false,
            errorAllCategorySelectedRewardFlag: false,
            errorAllBrandSelectedRewardFlag: false,
            errorAllCategoryAnotherInclutionRewardSelectedFlag: false,
            errorAllBrandAnotherInclutionRewardSelectedFlag: false,
            errorRewardIndividualProductSelectedExcludeFlag: false,
            errorRewardAllBrandSelectedExcludeFlag: false,
            errorRewardAllCategorySelectedExcludeFlag: false,
            errorRewardAllBrandAnotherExclutionSelectedFlag: false,
            errorRewardAllCategoryAnotherExclutionSelectedFlag: false,
            errorRewardIfTheCustomerExcludingProductFlag: false,
        })
    };

    /*
    * loading data from api on component load
    */
    componentDidMount() {
        ApiServices.getProducts();
        ApiServices.getCategories();
        ApiServices.getBrands();
        ApiServices.getZones();        
    }

    render() {

        // dot icon svg 
        const dotSvg =  <div className="dot-svg">
                            <svg fill="currentColor" height="18" stroke="currentColor" strokeWidth="0" viewBox="0 0 18 18" width="18" className="sc-bwzfXH gxVJAp"><title></title><rect fill="#FFE180" height="8" rx="4" transform="matrix(-1.45705e-08 1 1 1.31134e-07 0 0)" width="8"></rect></svg>
                        </div>

        // serarch icon for every search bar 
        const searchIcon = <FontAwesomeIcon className="form-control-feedback" icon={faSearch} color="blue"/>;
        
        // cancel rule confirmation modal popup
        const cancelModalPop = this.state.showModalCancel ? 
                                <div className={this.state.showModalCancel ? "showModal" : "hideModal"}>
                                    <CancelModalPopup  
                                        showModal={this.state.showModalCancel}                               
                                        title="Model Popup" yesClick={this.modalPopupBackCancel} 
                                        noClick={this.modalPopupCloseCancel}>
                                    </CancelModalPopup>
                                </div> : "";

        //simple individual product modal popup
        const individualProductModalPop = this.state.popupOpenIndividualProductFlag ? 
                                            <div className={this.state.popupOpenIndividualProductFlag ? "showModal" : "hideModal"}>
                                                <IndividualProductModalPopup  
                                                    showModal={this.state.popupOpenIndividualProductFlag}                               
                                                    title="Model Popup Individual Products" 
                                                    applyClick={this.modalPopupApplyIndividualProduct} 
                                                    cancelClick={this.modalPopupCloseIndividualProduct}
                                                    preSelectedIds={this.state.individualProductSelectedIds}
                                                    preSelectedLabels={this.state.individualProductSelectedLabels}>
                                                </IndividualProductModalPopup>
                                            </div> : ""; 
        
        //simple category modal popup
        const allCategoryModalPop = this.state.popupOpenAllCategory ? 
                                            <div className={this.state.popupOpenAllCategory ? "showModal" : "hideModal"}>
                                                <AllCategoryModalPopup  
                                                    showModal={this.state.popupOpenAllCategory}                               
                                                    title="Model Popup All Brand" 
                                                    applyClick={this.modalPopupApplyAllCategory} 
                                                    cancelClick={this.modalPopupCloseAllCategory}
                                                    preSelectedIds={this.state.allCategorySelectedIds}
                                                    preSelectedLabels={this.state.allCategorySelectedLabels}>
                                                </AllCategoryModalPopup>
                                            </div> : "";

        //simple brand modal popup
        const allBrandModalPop = this.state.popupOpenAllBrand ? 
                                            <div className={this.state.popupOpenAllBrand ? "showModal" : "hideModal"}>
                                                <AllBrandModalPopup  
                                                    showModal={this.state.popupOpenAllBrand}                               
                                                    title="Model Popup All Category" 
                                                    applyClick={this.modalPopupApplyAllBrand} 
                                                    cancelClick={this.modalPopupCloseAllBrand}
                                                    preSelectedIds={this.state.allBrandSelectedIds}
                                                    preSelectedLabels={this.state.allBrandSelectedLabels}>
                                                </AllBrandModalPopup>
                                            </div> : "";
        
        //another inclution category modal popup
        const allCategoryAnotherInclutionModalPop = this.state.popupOpenAllCategoryAnotherInclution ? 
                                                    <div className={this.state.popupOpenAllCategoryAnotherInclution ? "showModal" : "hideModal"}>
                                                        <AllCategoryModalPopup  
                                                            showModal={this.state.popupOpenAllCategoryAnotherInclution}                               
                                                            title="Model Popup All Brand Another Inclution" 
                                                            applyClick={this.modalPopupApplyAllCategoryAnotherInclution} 
                                                            cancelClick={this.modalPopupCloseAllCategoryAnotherInclution}
                                                            preSelectedIds={this.state.allCategoryAnotherInclutionSelectedIds}
                                                            preSelectedLabels={this.state.allCategoryAnotherInclutionSelectedLabels}>
                                                        </AllCategoryModalPopup>
                                                    </div> : "";

        //another inclution brand modal popup
        const allBrandAnotherInclutionModalPop = this.state.popupOpenAllBrandAnotherInclution ? 
                                                    <div className={this.state.popupOpenAllBrandAnotherInclution ? "showModal" : "hideModal"}>
                                                        <AllBrandModalPopup  
                                                            showModal={this.state.popupOpenAllBrandAnotherInclution}                               
                                                            title="Model Popup All Category Another Inclution" 
                                                            applyClick={this.modalPopupApplyAllBrandAnotherInclution} 
                                                            cancelClick={this.modalPopupCloseAllBrandAnotherInclution}
                                                            preSelectedIds={this.state.allBrandAnotherInclutionSelectedIds}
                                                            preSelectedLabels={this.state.allBrandAnotherInclutionSelectedLabels}>
                                                        </AllBrandModalPopup>
                                                    </div> : "";                                    
        
        //exclude individual product modal popup
        const individualProductModalPopExclude = this.state.popupOpenIndividualProductExcludeFlag ? 
                                                    <div className={this.state.popupOpenIndividualProductExcludeFlag ? "showModal" : "hideModal"}>
                                                        <IndividualProductModalPopup  
                                                            showModal={this.state.popupOpenIndividualProductExcludeFlag}                               
                                                            title="Model Popup Individual Products" 
                                                            applyClick={this.modalPopupApplyIndividualProductExclude} 
                                                            cancelClick={this.modalPopupCloseIndividualProductExclude}
                                                            preSelectedIds={this.state.individualProductSelectedExcludeIds}
                                                            preSelectedLabels={this.state.individualProductSelectedExcludeLabels}>
                                                        </IndividualProductModalPopup>
                                                    </div> : ""; 
        
        //exclude category modal popup
        const allCategoryModalPopExclude = this.state.popupOpenAllCategoryExcludeFlag ? 
                                            <div className={this.state.popupOpenAllCategoryExcludeFlag ? "showModal" : "hideModal"}>
                                                <AllCategoryModalPopup  
                                                    showModal={this.state.popupOpenAllCategoryExcludeFlag}                               
                                                    title="Model Popup All Brand" 
                                                    applyClick={this.modalPopupApplyAllCategoryExclude} 
                                                    cancelClick={this.modalPopupCloseAllCategoryExclude}
                                                    preSelectedIds={this.state.allCategorySelectedExcludeIds}
                                                    preSelectedLabels={this.state.allCategorySelectedExcludeLabels}>
                                                </AllCategoryModalPopup>
                                            </div> : "";

        //exclude brand modal popup
        const allBrandModalPopExclude = this.state.popupOpenAllBrandExcludeFlag ? 
                                            <div className={this.state.popupOpenAllBrandExcludeFlag ? "showModal" : "hideModal"}>
                                                <AllBrandModalPopup  
                                                    showModal={this.state.popupOpenAllBrandExcludeFlag}                               
                                                    title="Model Popup All Category" 
                                                    applyClick={this.modalPopupApplyAllBrandExclude} 
                                                    cancelClick={this.modalPopupCloseAllBrandExclude}
                                                    preSelectedIds={this.state.allBrandSelectedExcludeIds}
                                                    preSelectedLabels={this.state.allBrandSelectedExcludeLabels}>
                                                </AllBrandModalPopup>
                                            </div> : "";

        //another exclution category modal popup
        const allCategoryAnotherExclutionModalPop = this.state.popupOpenAllCategoryAnotherExclutionFlag ? 
                                                    <div className={this.state.popupOpenAllCategoryAnotherExclutionFlag ? "showModal" : "hideModal"}>
                                                        <AllCategoryModalPopup  
                                                            showModal={this.state.popupOpenAllCategoryAnotherExclutionFlag}                               
                                                            title="Model Popup All Brand Another Exclution" 
                                                            applyClick={this.modalPopupApplyAllCategoryAnotherExclution} 
                                                            cancelClick={this.modalPopupCloseAllCategoryAnotherExclution}
                                                            preSelectedIds={this.state.allCategoryAnotherExclutionSelectedIds}
                                                            preSelectedLabels={this.state.allCategoryAnotherExclutionSelectedLabels}>
                                                        </AllCategoryModalPopup>
                                                    </div> : "";

        // another inclution brand modal popup
        const allBrandAnotherExclutionModalPop = this.state.popupOpenAllBrandAnotherExclutionFlag ? 
                                                    <div className={this.state.popupOpenAllBrandAnotherExclutionFlag ? "showModal" : "hideModal"}>
                                                        <AllBrandModalPopup  
                                                            showModal={this.state.popupOpenAllBrandAnotherExclutionFlag}                               
                                                            title="Model Popup All Category Another Exclution" 
                                                            applyClick={this.modalPopupApplyAllBrandAnotherExclution} 
                                                            cancelClick={this.modalPopupCloseAllBrandAnotherExclution}
                                                            preSelectedIds={this.state.allBrandAnotherExclutionSelectedIds}
                                                            preSelectedLabels={this.state.allBrandAnotherExclutionSelectedLabels}>
                                                        </AllBrandModalPopup>
                                                    </div> : "";                                             
     
        // reward gift product in cart
        const rewardGiftCartInidividualProductModalPop = this.state.popupOpenIndividualProductGiftInCartRewardFlag ? 
                                                         <div className={this.state.popupOpenIndividualProductGiftInCartRewardFlag ? "showModal" : "hideModal"}>
                                                            <IndividualProductModalPopup  
                                                                showModal={this.state.popupOpenIndividualProductGiftInCartRewardFlag}                               
                                                                title="Model Popup Individual Products" 
                                                                applyClick={this.modalPopupApplyIndividualProductGiftInCartReward} 
                                                                cancelClick={this.modalPopupCloseIndividualProductGiftInCartReward}
                                                                preSelectedIds={(this.state.rewardIndividualProductGiftInCartSelectedIds===0 || this.state.rewardIndividualProductGiftInCartSelectedIds === undefined) ? []:[this.state.rewardIndividualProductGiftInCartSelectedIds]}
                                                                preSelectedLabels={this.state.rewardIndividualProductGiftInCartSelectedLabels}>
                                                            </IndividualProductModalPopup>
                                                        </div>:""   

        // reward free shipping to selected zones
        const rewardFreeShippingZoneModalPop = this.state.popupOpenFreeShippingZoneRewardFlag ? 
                                                         <div className={this.state.popupOpenFreeShippingZoneRewardFlag ? "showModal" : "hideModal"}>
                                                            <ZoneListModalPopup  
                                                                showModal={this.state.popupOpenFreeShippingZoneRewardFlag}                               
                                                                title="Model Popup Selected Zone" 
                                                                applyClick={this.modalPopupApplyZonesFreeShippingReward} 
                                                                cancelClick={this.modalPopupCloseZonesFreeShippingReward}
                                                                preSelectedIds={this.state.rewardFreeShippingZoneSelectedIds}
                                                                preSelectedLabels={this.state.rewardFreeShippingZoneSelectedLabels}>
                                                            </ZoneListModalPopup>
                                                        </div>:""   


        //simple search bar html for individual product
        const searchBarIndividualProduct = this.state.individualProductSearchBoxFlag?                            
                                            <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                {searchIcon}
                                                <input type="text" autoComplete="off" name="individualProductSearchBar" id="individualProductSearchBar" value={this.state.individualProductSelectedLabels}
                                                    className={this.state.errorIndividualProductSelectedBoxFlag?"form-control error":"form-control"} placeholder="Search" onClick={this.modalPopupOpenIndividualProduct}/>
                                            </div> : "";
        
        //simple search bar html for category
        const searchBarAllCategory = this.state.allCategorySearchBoxFlag?                            
                                        <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                            {searchIcon}
                                            <input type="text" autoComplete="off" name="allCategorySearchBar" id="allCategorySearchBar" value={this.state.allCategorySelectedLabels}
                                                className={this.state.errorAllCategorySelectedBoxFlag?"form-control error":"form-control"} placeholder="Search" onClick={this.modalPopupOpenAllCategory}/>
                                        </div> : "";

        //simple search bar html for brand
        const searchBarAllBrand = this.state.allBrandSearchBoxFlag?                            
                                        <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                            {searchIcon}
                                            <input type="text" autoComplete="off" name="allBrandSearchBar" id="allBrandSearchBar" value={this.state.allBrandSelectedLabels}
                                                className={this.state.errorAllBrandSelectedBoxFlag?"form-control error":"form-control"} placeholder="Search" onClick={this.modalPopupOpenAllBrand}/>
                                        </div> : "";
        
        //another inclution search bar html for brand
        const searchBarAnotherInclutionAllCategory = this.state.allCategorySearchBoxAnotherInclutionFlag?                            
                                                        <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                            {searchIcon}
                                                            <input type="text" autoComplete="off" name="allCategorySearchBarAnotherInclution" id="allCategorySearchBarAnotherInclution" value={this.state.allCategoryAnotherInclutionSelectedLabels}
                                                                className={this.state.errorAllCategoryAnotherSelectedBoxFlag?"form-control error":"form-control"} placeholder="Search" onClick={this.modalPopupOpenAllCategoryAnotherInclution}/>
                                                        </div> : <div className="col-md-5"></div>;
        
        //another inclution search bar html for brand
        const searchBarAnotherInclutionAllBrand = this.state.allBrandSearchBoxAnotherInclutionFlag?                            
                                                    <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="allBrandSearchBarAnotherInclution" id="allBrandSearchBarAnotherInclution" value={this.state.allBrandAnotherInclutionSelectedLabels}
                                                            className={this.state.errorAllBrandAnotherSelectedBoxFlag?"form-control error":"form-control"} placeholder="Search" onClick={this.modalPopupOpenAllBrandAnotherInclution}/>
                                                    </div> : <div className="col-md-5"></div>;

        //simple ifCustomerConditionQuantityHTML
        const ifCustomerConditionQuantityHTML = this.state.ifCustomerConditionQuantityFlag?
                                                <div className="inline-flex rule-counter-wrap">
                                                    <div className={this.state.errorIfCustomerConditionQuantityFlag ? "counter-day rule-counter error" : "counter-day rule-counter"}>
                                                        <CounterInput
                                                            min={1}
                                                            max={100}
                                                            onCountChange={count => {this.setState({ifCustomerConditionQuantityValue: count});
                                                                                    this.setState({errorIfCustomerConditionQuantityFlag: false});
                                                                                    }
                                                                                } 
                                                            count={this.state.ifCustomerConditionQuantityValue}
                                                        />
                                                    </div>
                                                    <div><label className="gray-label">products</label></div>
                                                </div>:""
        
        //simple ifCustomerConditionTotalHTML
        const ifCustomerConditionTotalHTML = this.state.ifCustomerConditionTotalFlag?
                                                <div className="inline-flex rule-counter-wrap">
                                                    <div className={this.state.errorIfCustomerConditionTotalFlag?"error":""}>
                                                        <div className="col-sm-2 input_container input-group">   
                                                            <div className="input-group-prepend">
                                                                <span className="form-control input-group-text currency-inputt-dollor-symbol" id="reachingTotalValueDollorSymbol">$</span>
                                                            </div>                      
                                                            <input type="text" 
                                                                className="form-control currency-input"
                                                                id="reachingTotalValue" 
                                                                name="reachingTotalValue" 
                                                                placeholder="0"
                                                                autoComplete="off"
                                                                onChange={event=>{this.setState({ifCustomerConditionTotalValue:event.target.value})}}
                                                            />                                                                        
                                                        </div>
                                                    </div>
                                                    <div><label className="gray-label">spent on products</label></div>
                                                </div>:""
                                                

        //simple including product html have select box
        const ifCustomerConditionIncludingProductHTML = this.state.ifCustomerConditionIncludingProductFlag ?
                                                        <>
                                                        <div className="col-sm-12 inline-flex top-pad-12 wrap-inc-prod">
                                                            {dotSvg}
                                                            <div><label className="gray-label break-sentance">Including products</label></div>
                                                            <div>
                                                                <select id="ifTheCustomerIncludingProduct"
                                                                    className={this.state.errorIndividualProductSelectedFlag?"control-label select-type error":"control-label select-type"}
                                                                    name="ifTheCustomerIncludingProduct" onChange={this.handleIfTheCustomerIncludingProductsChange}>
                                                                        <option value="">Please select a value</option>
                                                                        <option value="individual_products">Individual Products</option>
                                                                        <option value="all_products">All Products</option>  
                                                                        <option value="all_category">In category</option>  
                                                                        <option value="in_brand">In brand</option>                                   
                                                                </select> 
                                                            </div>
                                                            {searchBarIndividualProduct || searchBarAllCategory || searchBarAllBrand}
                                                        </div>
                                                        {
                                                            (searchBarAllCategory !=="" || searchBarAllBrand !=="") &&  this.state.addAnotherInclutionRuleBtnFlag
                                                                &&  
                                                                    <div className="btn-group top-pad-15 top-bottom-5 margin-50-left">
                                                                        <button className="btn btn-light" onClick={this.handleAddAnotherInclutionRuleBtnClick}>
                                                                            <span className="click-text"><svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" color="primary" className="base__StyledIcon-a9u0e1-0 hduNwj"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg> Add another inclusion rule</span>
                                                                        </button>
                                                                    </div>                                                                    
                                                        }
                                                        {searchBarAllCategory !=="" && this.state.addAnotherInclutionRuleSelectFlag && 
                                                            <div className="col-sm-12 inline-flex another-inclution">
                                                                <div className="col-sm-1"></div>
                                                                <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                                <div>
                                                                    <select id="ifTheCustomerIncludingBrand"
                                                                        className={this.state.errorAddAnotherInclutionRuleBrandSelectFlag?"control-label select-type error":"control-label select-type"} 
                                                                        name="ifTheCustomerIncludingBrand" 
                                                                        onChange={this.handleAddAnotherInclutionCategoryChange}>
                                                                            <option value="">Please select a value</option>                                                                             
                                                                            <option value="in_brand">In brand</option>                                   
                                                                    </select>
                                                                </div>
                                                                {searchBarAnotherInclutionAllBrand}
                                                                <div className="col-sm-2 delete-row-section">
                                                                    <span className="delete-row-wrap">
                                                                        <button className="delete-btn-icon btn" onClick={this.handleAddAnotherInclutionRuleRemoveClick}>
                                                                            <span className="delete-icon">
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                            </span>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {searchBarAllBrand !=="" && this.state.addAnotherInclutionRuleSelectFlag && 
                                                            <div className="col-sm-12 inline-flex another-inclution">
                                                                <div className="col-sm-1"></div>
                                                                <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                                <div>
                                                                    <select id="ifTheCustomerAnotherIncludingCategory"
                                                                        className={this.state.errorAddAnotherInclutionRuleCategorySelectFlag?"control-label select-type error":"control-label select-type"}  
                                                                        name="ifTheCustomerAnotherIncludingCategory" 
                                                                        onChange={this.handleAddAnotherInclutionBrandChange}>
                                                                            <option value="">Please select a value</option>                                                                             
                                                                            <option value="all_category">In category</option>                                   
                                                                    </select>
                                                                </div>
                                                                {searchBarAnotherInclutionAllCategory}
                                                                <div className="col-sm-2 delete-row-section">
                                                                    <span className="delete-row-wrap">
                                                                        <button className="delete-btn-icon btn" onClick={this.handleAddAnotherInclutionRuleRemoveClick}>
                                                                            <span className="delete-icon">
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                            </span>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }</>: ""

        // simple reaches AnOrder SubTotal HTML   
        const reachesAnOrderSubTotalHTML = this.state.reachesAnOrderSubTotalHTMLFlag ?
                                            <div className="col-sm-12 inline-flex">
                                                <div className="dot-svg">
                                                    <svg fill="currentColor" height="18" stroke="currentColor" strokeWidth="0" viewBox="0 0 18 18" width="18" className="sc-bwzfXH gxVJAp"><title></title><rect fill="#FFE180" height="8" rx="4" transform="matrix(-1.45705e-08 1 1 1.31134e-07 0 0)" width="8"></rect></svg>
                                                </div>
                                                <div><label className="gray-label">Spending at least</label></div>
                                                <div className={this.state.errorReachesAnOrderSubTotalValue ? "error" : ""}>
                                                    <div className="col-sm-2 input_container input-group">   
                                                        <div className="input-group-prepend">
                                                            <span className="form-control input-group-text currency-inputt-dollor-symbol" id="spendingAtLeastDollorSymbol">$</span>
                                                        </div>                      
                                                        <input type="text" 
                                                            className="form-control currency-input"
                                                            id="spendingAtLeast" 
                                                            name="spendingAtLeast" 
                                                            placeholder="0"
                                                            autoComplete="off"
                                                            onChange={(event)=>this.setState({reachesAnOrderSubTotalValue: event.target.value})}
                                                        />                                                                        
                                                    </div>
                                                </div>
                                                <div><label className="gray-label">on the order</label></div>
                                            </div>                    
                                            :""                                                    

        //simple reaching html have select box
        const buysProductsHTML = this.state.buysProductsFlag ?
                                    <div className="col-sm-12 top-bottom-5">
                                        <div className="col-sm-12 inline-flex">
                                            <div className="inline-flex">
                                                {dotSvg}
                                                <div><label className="gray-label">Reaching a</label></div>
                                                <div className={this.state.errorConditionQuantityTotalFlag?"error":""}>
                                                    <select id="ifTheCustomerReaching"
                                                        className="control-label select-type" 
                                                        name="ifTheCustomerReaching" onChange={this.handleIfTheCustomerReachingChange}>
                                                            <option value="" >Add a condition</option>
                                                            <option value="reaching_quantity">Quantity</option>
                                                            <option value="reaching_total_value" >Total Value</option>                                   
                                                    </select> 
                                                </div>
                                            </div>
                                            {ifCustomerConditionQuantityHTML || ifCustomerConditionTotalHTML}
                                        </div>
                                        {ifCustomerConditionIncludingProductHTML}
                                    </div> : "";
    
        //exclude search bar html for individual product
        const searchBarIndividualProductExclude = this.state.excludingIndividualProductSearchBoxFlag?                            
                                                    <div className="form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="excludeIndividualProductSearchBar" id="excludeIndividualProductSearchBar" value={this.state.individualProductSelectedExcludeLabels}
                                                            className={this.state.errorIndividualProductSelectedExcludeBoxFlag?"form-control error":"form-control"} placeholder="Search" onClick={this.modalPopupOpenIndividualProductExclude}/>
                                                    </div> : "";

        //exclude search bar html for category
        const searchBarAllCategoryExclude = this.state.excludingAllCategorySearchBoxFlag?                            
                                                <div className="form-group has-search div-left-pad indivisual-search-box">
                                                    {searchIcon}
                                                    <input type="text" autoComplete="off" name="excludeAllCategorySearchBar" id="excludeAllCategorySearchBar" 
                                                        value={this.state.allCategorySelectedExcludeLabels}
                                                        className={this.state.errorAllCategorySelectedExcludeFlag?"form-control error" :"form-control"}
                                                        placeholder="Search" onClick={this.modalPopupOpenAllCategoryExclude}/>
                                                </div> : "";

        //exclude search bar html for brand
        const searchBarAllBrandExclude = this.state.excludingAllBrandSearchBoxFlag?                            
                                            <div className="form-group has-search div-left-pad indivisual-search-box">
                                                {searchIcon}
                                                <input type="text" autoComplete="off" name="excludeAllBrandSearchBar" id="excludeAllBrandSearchBar" 
                                                    value={this.state.allBrandSelectedExcludeLabels}
                                                    className={this.state.errorAllBrandSelectedExcludeFlag?"form-control error" :"form-control"}
                                                    placeholder="Search" onClick={this.modalPopupOpenAllBrandExclude}/>
                                            </div> : "";

        //another Exclution search bar html for brand
        const searchBarAnotherExclutionAllBrand = this.state.allBrandSearchBoxAnotherExclutionFlag?                            
                                                    <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="allBrandSearchBarAnotherExclution" id="allBrandSearchBarAnotherExclution" 
                                                            value={this.state.allBrandAnotherExclutionSelectedLabels}
                                                            className={this.state.errorAllBrandAnotherExclutionSelectedFlag?"form-control error":"form-control"}
                                                            placeholder="Search" onClick={this.modalPopupOpenAllBrandAnotherExclution}/>
                                                    </div> : <div className="col-md-5"></div>;
        
        //another Exclution search bar html for Category
        const searchBarAnotherExclutionAllCategory = this.state.allCategorySearchBoxAnotherExclutionFlag?                            
                                                    <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="allCategorySearchBarAnotherExclution" id="allCategorySearchBarAnotherExclution" 
                                                            value={this.state.allCategoryAnotherExclutionSelectedLabels}
                                                            className={this.state.errorAllCategoryAnotherExclutionSelectedFlag?"form-control error":"form-control"} 
                                                            placeholder="Search" onClick={this.modalPopupOpenAllCategoryAnotherExclution}/>
                                                    </div> : <div className="col-md-5"></div>;

        //reward gift in cart search bar html for individual product
        const rewardSearchBarIndividualProductGiftInCart =                            
                                            <div className="col-sm-12 form-group has-search div-left-pad indivisual-search-box">
                                                {searchIcon}
                                                <input type="text" autoComplete="off" name="rewardIndividualProductGiftInCartSearchBox"
                                                     id="rewardIndividualProductGiftInCartSearchBox" 
                                                     value={this.state.rewardIndividualProductGiftInCartSelectedLabels}
                                                    className={this.state.errorRewardIndividualProductGiftInCartFlag?"form-control error":"form-control"} placeholder="Search to add gift product"
                                                    onClick={this.modalPopupOpenIndividualProductGiftInCartReward}/>
                                            </div>
       
        // exclude first select box
        const addExclutionRuleSelectHTML = this.state.addExclutionRuleSelectFlag ?
                                            <>
                                                <div className="col-sm-12 inline-flex top-pad-12 wrap-inc-prod">
                                                    {dotSvg}
                                                    <div><label className="gray-label break-sentance">Excluding products</label></div>
                                                    <div>
                                                        <select id="ifTheCustomerExcludingProduct"
                                                            className={this.state.errorIndividualProductSelectedExcludeFlag?"control-label select-type error":"control-label select-type"}                                                            
                                                            name="ifTheCustomerExcludingProduct" onChange={this.handleIfTheCustomerExcludingProductsChange}>
                                                                <option value="">Please select a value</option>
                                                                <option value="individual_products">Individual Products</option>
                                                                <option value="all_category">In category</option>  
                                                                <option value="in_brand">In brand</option>                                   
                                                        </select> 
                                                    </div>
                                                    <div className="col-md-5">
                                                        {searchBarIndividualProductExclude || searchBarAllCategoryExclude || searchBarAllBrandExclude}
                                                    </div>                                                    
                                                    <div className="col-sm-2 delete-row-section">
                                                        <span className="delete-row-wrap">
                                                            <button className="delete-btn-icon btn" onClick={this.handleAddExclutionRuleRemoveClick}>
                                                                <span className="delete-icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    (searchBarAllCategoryExclude !=="" || searchBarAllBrandExclude !=="") &&  
                                                    this.state.addAnotherExclutionRuleBtnFlag &&  
                                                        <div className="btn-group top-pad-15 top-bottom-5 margin-50-left">
                                                            <button className="btn btn-light" onClick={this.handleAddAnotherExclutionRuleBtnClick}>
                                                                <span className="click-text"><svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" color="primary" className="base__StyledIcon-a9u0e1-0 hduNwj"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg> Add another exclution rule</span>
                                                            </button>
                                                        </div>                                                                    
                                                }
                                                {
                                                    searchBarAllCategoryExclude !=="" && this.state.addAnotherExclutionRuleSelectFlag && 
                                                        <div className="col-sm-12 inline-flex another-inclution">
                                                            <div className="col-sm-1"></div>
                                                            <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                            <div>
                                                                <select id="ifTheCustomerExcludingBrand"
                                                                    className="control-label select-type" 
                                                                    name="ifTheCustomerExcludingBrand" 
                                                                    onChange={this.handleAddAnotherExclutionCategoryChange}>
                                                                        <option value="">Please select a value</option>                                                                             
                                                                        <option value="in_brand">In brand</option>                                   
                                                                </select>
                                                            </div>
                                                            {searchBarAnotherExclutionAllBrand}
                                                            <div className="col-sm-2 delete-row-section">
                                                                <span className="delete-row-wrap">
                                                                    <button className="delete-btn-icon btn" onClick={this.handleAddAnotherExclutionRuleRemoveClick}>
                                                                        <span className="delete-icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                        </span>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                    searchBarAllBrandExclude !=="" && this.state.addAnotherExclutionRuleSelectFlag && 
                                                        <div className="col-sm-12 inline-flex another-inclution">
                                                            <div className="col-sm-1"></div>
                                                            <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                            <div>
                                                                <select id="ifTheCustomerExcludingBrand"
                                                                    className="control-label select-type" 
                                                                    name="ifTheCustomerExcludingBrand" 
                                                                    onChange={this.handleAddAnotherExclutionBrandChange}>
                                                                        <option value="">Please select a value</option>                                                                             
                                                                        <option value="in_category">In category</option>                                   
                                                                </select>
                                                            </div>
                                                            {searchBarAnotherExclutionAllCategory}
                                                            <div className="col-sm-2 delete-row-section">
                                                                <span className="delete-row-wrap">
                                                                    <button className="delete-btn-icon btn" onClick={this.handleAddAnotherExclutionRuleRemoveClick}>
                                                                        <span className="delete-icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                        </span>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                             :""

        // reward gift in the cart part1
        const rewardGiftInTheCartHTML1 = this.state.rewardGiftInTheCartFlag ?
                                        <>
                                            <div className="reward-once-select-div">
                                                <select id="rewardGiftInTheCartOnceOrNTimeSelect"
                                                className="control-label select-type" 
                                                name="rewardGiftInTheCartOnceOrNTimeSelect"
                                                onChange={event=>this.setState({rewardGiftInTheCartOnceUnlimited:event.target.value})}>
                                                    <option value="once" >Once</option>
                                                    <option value="unlimited_times" >Unlimited times</option>                                                
                                                </select>
                                            </div>
                                            <div><label className="gray-label break-sentance mid-gray-label">per cart</label></div> 
                                        </>
                                        :""

        // reward gift in the cart part2
        const rewardGiftInTheCartHTML2 = this.state.rewardGiftInTheCartFlag ?
                                        <>
                                            <div className="col-sm-12 inline-flex top-pad-15">                                            
                                                {dotSvg}
                                                <div><label className="gray-label">Include</label></div>
                                                <div className={this.state.errorRewardGiftCartQuantityFlag?"quantity-counter counter-day rule-counter error":"quantity-counter counter-day rule-counter"}>
                                                    <CounterInput
                                                        min={1}
                                                        max={100}
                                                        onCountChange={count => {this.setState({rewardGiftCartQuantity:count});this.setState({errorRewardGiftCartQuantityFlag:false})}} 
                                                        count={this.state.rewardGiftCartQuantity}
                                                    />
                                                </div>
                                                <div className="col-sm-5">
                                                    {rewardSearchBarIndividualProductGiftInCart}
                                                </div>
                                                <div><label className="gray-label last-wide-gray-label">in the customer's cart for free.</label></div>                                            
                                            </div>       
                                        </>
                                        :"" 
        
        // reward free shipping
        const rewardFreeShippingHTML = this.state.rewardFreeShippingFlag ?
                                        <>
                                            <div className="col-sm-12 inline-flex top-pad-15">                                            
                                                {dotSvg}
                                                <div><label className="gray-label">Free shipping for the customer order to</label></div>
                                                <div>
                                                    <select id="thenRewardFreeShipping" onChange={this.rewardFreeShippingSelectChange}
                                                        className={this.state.errorRewardFreeShippingZoneFlag?"control-label select-type error":"control-label select-type"} name="thenRewardFreeShipping">
                                                        <option value="" >Please select a reward</option>
                                                        <option value="all_zones" >All zones</option>
                                                        <option value="selected_zones" >Selected zones</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                        :""

        // reward free shipping for selected zones                                        
        const rewardFreeShippingSelectedZoneHTML = this.state.rewardFreeShippingSelectedZoneFlag ?
                                                    <>
                                                        <div className="col-sm-12 inline-flex top-pad-15">                                            
                                                            {dotSvg}
                                                            <div><label className="gray-label">Including zones:</label></div>
                                                            <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                                    {searchIcon}
                                                                    <input type="text" autoComplete="off" name="rewardFreeShippingZoneSelectedSearchBar" id="rewardFreeShippingZoneSelectedSearchBar" value={this.state.rewardFreeShippingZoneSelectedLabels}
                                                                        className={this.state.errorRewardFreeShippingSelectedZoneFlag?"form-control error":"form-control"} placeholder="Search" onClick={this.modalPopupOpenZonesFreeShippingReward}/>
                                                            </div>
                                                        </div>
                                                    </> : ""

        // reward discount on order subtotal part1
        const rewardDiscountOnOrderSubtotalHTML1 = this.state.rewardDiscountOnOrderSubtotalFlag ?
                                                <>
                                                    <div className="reward-once-select-div">
                                                        <select id="rewardDiscountOnOrderSubtotalSelect"
                                                            className="control-label select-type" 
                                                            name="rewardDiscountOnOrderSubtotalSelect"
                                                            onChange={event=>this.setState({rewardDiscountOnOrderSubtotalOnceUnlimited:event.target.value})}>
                                                                <option value="once">Once</option>
                                                                <option value="unlimited_times">Unlimited times</option>                                                
                                                        </select>
                                                    </div>
                                                    <div><label className="gray-label break-sentance mid-gray-label">per cart</label></div> 
                                                </> : ""

        // reward discount on order subtotal part2
        const rewardDiscountOnOrderSubtotalHTML2 = this.state.rewardDiscountOnOrderSubtotalFlag ?
                                                <>
                                                    <div className="col-sm-12 inline-flex top-pad-15">                                            
                                                            {dotSvg}
                                                            <div><label className="gray-label">Discounting by a</label></div>
                                                            <div className="col-md-3 form-group">
                                                                <select id="rewardDiscountOnOrderSubtotalByValOrPerSelect"
                                                                    className="control-label select-type" 
                                                                    name="rewardDiscountOnOrderSubtotalByValOrPerSelect"
                                                                    onChange={this.rewardDiscountOnOrderSubtotalByValOrPerChange}>
                                                                        <option value="value">Value</option>
                                                                        <option value="percentage">Percentage</option>                                                
                                                                </select>                                                                
                                                            </div>                                                            
                                                            {this.state.rewardDiscountOnOrderSubtotalByValPersData === "value" ? 
                                                                <>
                                                                    <div className="col-sm-2 form-group input_container input-group auto-width">   
                                                                        <div className="input-group-prepend">
                                                                            <span className={this.state.errorRewardDiscountOnOrderSubtotalValPer?"form-control input-group-text currency-inputt-dollor-symbol error":"form-control input-group-text currency-inputt-dollor-symbol"} id="rewardDiscountOnOrderSubtotalByValDollorSymbol">$</span>
                                                                        </div>                      
                                                                        <input type="text"
                                                                            className={this.state.errorRewardDiscountOnOrderSubtotalValPer?"form-control currency-input amount-input error":"form-control currency-input"}
                                                                            id="rewardDiscountOnOrderSubtotalByVal" 
                                                                            name="rewardDiscountOnOrderSubtotalByVal" 
                                                                            placeholder="0"
                                                                            autoComplete="off"
                                                                            onChange={event=>this.setState({rewardDiscountOnOrderSubtotalVal:event.target.value})}
                                                                        />                                                                        
                                                                    </div>
                                                                </>                                                                     
                                                                :<>
                                                                    <div className="col-sm-2 form-group input_container input-group auto-width discount-Percent">                                                                                              
                                                                        <input type="text" 
                                                                            className={this.state.errorRewardDiscountOnOrderSubtotalValPer?"form-control currency-input percent-input error":"form-control currency-input percent-input"}
                                                                            id="rewardDiscountOnOrderSubtotalByPer" 
                                                                            name="rewardDiscountOnOrderSubtotalByPer" 
                                                                            placeholder="0"
                                                                            autoComplete="off"
                                                                            onChange={event=>this.setState({rewardDiscountOnOrderSubtotalPer:event.target.value})}
                                                                        /> 
                                                                        <div className="input-percent-apend">
                                                                            <span className={this.state.errorRewardDiscountOnOrderSubtotalValPer?"form-control input-group-text currency-input-percent-symbol error":"form-control input-group-text currency-input-percent-symbol"} id="rewardDiscountOnOrderSubtotalByValDollorSymbol">%</span>
                                                                        </div>                                                                       
                                                                    </div>
                                                                </>
                                                            }                                                            
                                                            <div><label className="gray-label">from the order sub-total</label></div>
                                                    </div>
                                                </> : ""

        // reward discount on product part1
        const rewardDiscountOnProductHTML1 = this.state.rewardDiscountOnProductsFlag ?
                                                <>
                                                    <div className="reward-once-select-div">
                                                        <select id="rewardDiscountOnProductOnceOrNTimeSelect"
                                                            className="control-label select-type" 
                                                            name="rewardDiscountOnProductOnceOrNTimeSelect"
                                                            onChange={event=>this.setState({rewardDiscountOnProductOnceOrNTimeSelectVal:event.target.value})}>
                                                                <option value="once">Once</option>
                                                                <option value="unlimited_times">Unlimited times</option>                                                
                                                        </select>
                                                    </div>
                                                    <div><label className="gray-label break-sentance mid-gray-label">per cart</label></div> 
                                                </> : ""
        // reward discount on product part2                                       
        const rewardDiscountOnProductHTML2 = this.state.rewardDiscountOnProductsFlag ?
                                                <>
                                                    <div className="col-sm-12 inline-flex top-pad-15">                                            
                                                        {dotSvg}
                                                        <div><label className="gray-label">By a</label></div>
                                                        <div className="col-md-3 form-group">
                                                            <select id="rewardDiscountOnProductsSelect"
                                                                className="control-label select-type" 
                                                                name="rewardDiscountOnProductsSelect"
                                                                onChange={this.rewardDiscountOnProductsSelectChange}>
                                                                    <option value="amount">Amount</option>
                                                                    <option value="percentage">Percentage</option>                                                
                                                            </select>                                                                
                                                        </div>
                                                        {
                                                            this.state.rewardDiscountOnProductsSelectData === 'amount' ?
                                                                <div className="col-sm-2 form-group input_container input-group auto-width">   
                                                                    <div className="input-group-prepend">
                                                                        <span className={this.state.errorRewardDiscountOnProductsSelectAmountPers?"form-control input-group-text currency-inputt-dollor-symbol error":"form-control input-group-text currency-inputt-dollor-symbol"} id="rewardDiscountOnProductsDollorSymbol">$</span>
                                                                    </div>                      
                                                                    <input type="text" 
                                                                        className={this.state.errorRewardDiscountOnProductsSelectAmountPers?"form-control currency-input input-curr-amount error":"form-control currency-input"}                                                                        
                                                                        id="rewardDiscountOnProductsByVal" 
                                                                        name="rewardDiscountOnProductsByVal" 
                                                                        placeholder="0"
                                                                        autoComplete="off"
                                                                        onChange={event=>this.setState({rewardDiscountOnProductsByVal:event.target.value})}
                                                                    />                                                                        
                                                                </div> 
                                                            :
                                                                <div className="col-sm-2 form-group input_container input-group auto-width discount-Percent">                                                                                              
                                                                    <input type="text"
                                                                        className={this.state.errorRewardDiscountOnProductsSelectAmountPers?"form-control currency-input percent-input input-cur-amt error":"form-control currency-input percent-input"}
                                                                        id="rewardDiscountOnProductsByPer" 
                                                                        name="rewardDiscountOnProductsByPer" 
                                                                        placeholder="0"
                                                                        autoComplete="off"
                                                                        onChange={event=>this.setState({rewardDiscountOnProductsByPer:event.target.value})}
                                                                    /> 
                                                                    <div className="input-percent-apend">
                                                                        <span className={this.state.errorRewardDiscountOnProductsSelectAmountPers?"form-control input-group-text currency-input-percent-symbol error":"form-control input-group-text currency-input-percent-symbol"} id="rewardDiscountOnProductsByValDollorSymbol">%</span>
                                                                    </div>                                                                       
                                                                </div>
                                                        }
                                                        <div><label className="gray-label">from</label></div>
                                                        <div className="col-md-3 form-group">
                                                            <select id="rewardDiscountOnProductsSelectEachTotal"
                                                                className="control-label select-type" 
                                                                name="rewardDiscountOnProductsSelectEachTotal"
                                                                onChange={event=>event.target.value==="eact_product_price"?this.setState({rewardAsTotal:false}):this.setState({rewardAsTotal:true})}>
                                                                    <option value="eact_product_price">Each product's price</option>
                                                                    <option value="total_price_of_included_products">Total price of included products</option>                                                
                                                            </select>                                                                
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 inline-flex">
                                                        {dotSvg}
                                                        <div><label className="gray-label">Applied on</label></div>
                                                        <div className="col-md-3 form-group">
                                                            <select id="rewardDiscountOnProductsAppliedOnSelect"
                                                                className="control-label select-type" 
                                                                name="rewardDiscountOnProductsAppliedOnSelect"
                                                                onChange={this.rewardDiscountOnProductsAppliedOnSelectChange}>
                                                                    <option value="all">All</option>
                                                                    <option value="upto">Up to</option>                                                
                                                            </select>                                                                
                                                        </div>
                                                        { this.state.rewardDiscountOnProductsAppliedOnSelectData === "upto" ? 
                                                            <div className={this.state.errorRewardDisOnProdQuntCount?"counter-day rule-counter upto-select counter-pad-margin error":"counter-day rule-counter upto-select counter-pad-margin"}>
                                                                <CounterInput
                                                                    min={1}
                                                                    max={100}
                                                                    onCountChange={count =>{this.setState({rewardDisOnProdQuntCount:count}); this.setState({errorRewardDisOnProdQuntCount:false})}} 
                                                                    count={this.state.rewardDisOnProdQuntCount}
                                                                />
                                                            </div> 
                                                        : ""
                                                        }
                                                        <div><label className="gray-label">Products</label></div>
                                                    </div> 
                                                    <div className="col-sm-12 inline-flex">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-6 inc-product-check">
                                                            <input type="checkbox"                                             
                                                                name="rewardDiscountOnProductsAppliedOnProductOnSale"                                          
                                                                id="rewardDiscountOnProductsAppliedOnProductOnSale"
                                                                defaultChecked={this.state.rewardExcludeItemsOnSale}
                                                                onChange={event=>this.setState({rewardExcludeItemsOnSale:event.currentTarget.checked})}
                                                            /> <label className="padding-left-8px">Including products already on sale</label>
                                                        </div>                                                    
                                                    </div>
                                                    <div className="col-sm-12 inline-flex">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-6 inc-product-check">
                                                            <input type="checkbox"                                             
                                                                name="rewardDiscountOnProductsAppliedOnProductSatisfyCandition"                                          
                                                                id="rewardDiscountOnProductsAppliedOnProductSatisfyCandition" 
                                                                onChange={event=>this.setState({rewardIncludeItemsConsideredByCondition:event.currentTarget.checked})}
                                                            /> <label className="padding-left-8px">Including products that satisfy the condition</label>
                                                        </div>                                                    
                                                    </div>     
                                                </> : ""                                                                                           

        // reward fixed price for # of product part1
        const rewardFixedPriceOfProductHTML1 = this.state.rewardFixedPriceForOfProductFlag ? 
                                                <>
                                                    <div className="reward-once-select-div">
                                                        <select id="rewardFixedPriceForOfProductSelect"
                                                            className="control-label select-type" 
                                                            name="rewardFixedPriceForOfProductSelect"
                                                            onChange={event=>this.setState({rewardFixedPriceForOfProductSelectVal:event.target.value})}>
                                                                <option value="once">Once</option>
                                                                <option value="unlimited_times">Unlimited times</option>                                                
                                                        </select>
                                                    </div>
                                                    <div><label className="gray-label break-sentance mid-gray-label">per cart</label></div> 
                                                </> : "" 
        
        // reward fixed price for # of product part1
        const rewardFixedPriceOfProductHTML2 = this.state.rewardFixedPriceForOfProductFlag ?
                                                <>
                                                    <div className="col-sm-12 inline-flex top-pad-15">                                            
                                                        {dotSvg}
                                                        <div><label className="gray-label">Set the sub-total price to</label></div>
                                                        <div className="col-sm-3 form-group input_container input-group auto-width">   
                                                            <div className="input-group-prepend rm-padding-left">
                                                                <span className={this.state.errorRewardFixedPriceProductsByVal?"form-control input-group-text currency-inputt-dollor-symbol error":"form-control input-group-text currency-inputt-dollor-symbol"} id="rewardFixedPricesDollorSymbol">$</span>
                                                            </div>                      
                                                            <input type="text" 
                                                                className={this.state.errorRewardFixedPriceProductsByVal?"form-control currency-input error":"form-control currency-input"}
                                                                id="rewardFixedPriceProductsByVal" 
                                                                name="rewardFixedPriceProductsByVal" 
                                                                placeholder="0"
                                                                autoComplete="off"
                                                                onChange={event=>{this.setState({rewardFixedPriceProductsByVal:event.target.value});this.setState({errorRewardFixedPriceProductsByVal:false})}}
                                                            />                                                                                                                    
                                                        </div>
                                                        <div><label className="gray-label">for</label></div> 
                                                        <div className={this.state.errorRewardFixedProdQuntCount?"counter-day rule-counter upto-select error":"counter-day rule-counter upto-select"}>
                                                            <CounterInput
                                                                min={1}
                                                                max={100}
                                                                onCountChange={count =>{this.setState({rewardFixedProdQuntCount:count}); this.setState({errorRewardFixedProdQuntCount:false})}} 
                                                                count={this.state.rewardFixedProdQuntCount}
                                                            />
                                                        </div>
                                                        <div><label className="gray-label">products</label></div>                                                        
                                                    </div>
                                                    <div className="col-sm-12 inline-flex">                                            
                                                        {dotSvg}
                                                        <div><label className="gray-label">Applied to the</label></div>
                                                        <div className="col-sm-3 form-group input_container input-group auto-width">   
                                                             <select id="rewardFixedPriceForOfProductAppliedToSelect"
                                                                className="control-label select-type" 
                                                                name="rewardFixedPriceForOfProductAppliedToSelect"
                                                                onChange={event=>this.setState({rewardFixedProdStrategy:event.target.value})}>
                                                                    <option value="LEAST_EXPENSIVE">Least expensive</option>
                                                                    <option value="MOST_EXPENSIVE">Most expensive</option>                                                
                                                            </select>                                                                                                
                                                        </div>                                                        
                                                        <div><label className="gray-label">products in the cart</label></div>                                                        
                                                    </div>
                                                    <div className="col-sm-12 inline-flex">
                                                        <div className="col-sm-1"></div>
                                                        <div className="col-sm-6 inc-product-check">
                                                            <input type="checkbox"                                             
                                                                name="rewardFixedPriceProductsIncProduct"                                          
                                                                id="rewardFixedPriceProductsIncProduct" 
                                                                defaultChecked={this.state.rewardFixedPriceProductsIncProduct}
                                                                onChange={event=>this.setState({rewardFixedPriceProductsIncProduct:event.currentTarget.checked})}                                                               
                                                            /> <label className="padding-left-8px">Including products already on sale</label>
                                                        </div>                                                    
                                                    </div> 
                                                </> : "" 
        
        //Reward individual product modal popup
        const individualProductModalPopReward = this.state.popupOpenIndividualProductRewardFlag ? 
                                                    <div className={this.state.popupOpenIndividualProductRewardFlag ? "showModal" : "hideModal"}>
                                                        <IndividualProductModalPopup  
                                                            showModal={this.state.popupOpenIndividualProductRewardFlag}                               
                                                            title="Model Popup Individual Products" 
                                                            applyClick={this.modalPopupApplyIndividualProductReward} 
                                                            cancelClick={this.modalPopupCloseIndividualProductReward}
                                                            preSelectedIds={this.state.individualProductSelectedRewardIds}
                                                            preSelectedLabels={this.state.individualProductSelectedRewardLabels}>
                                                        </IndividualProductModalPopup>
                                                    </div> : ""; 
        
        //Reward category modal popup
        const allCategoryModalPopReward = this.state.popupOpenAllCategoryRewardFlag ? 
                                            <div className={this.state.popupOpenAllCategoryRewardFlag ? "showModal" : "hideModal"}>
                                                <AllCategoryModalPopup  
                                                    showModal={this.state.popupOpenAllCategoryRewardFlag}                               
                                                    title="Model Popup All Brand" 
                                                    applyClick={this.modalPopupApplyAllCategoryReward} 
                                                    cancelClick={this.modalPopupCloseAllCategoryReward}
                                                    preSelectedIds={this.state.allCategorySelectedRewardIds}
                                                    preSelectedLabels={this.state.allCategorySelectedRewardLabels}>
                                                </AllCategoryModalPopup>
                                            </div> : "";

        //Reward brand modal popup
        const allBrandModalPopReward = this.state.popupOpenAllBrandRewardFlag ? 
                                            <div className={this.state.popupOpenAllBrandRewardFlag ? "showModal" : "hideModal"}>
                                                <AllBrandModalPopup  
                                                    showModal={this.state.popupOpenAllBrandRewardFlag}                               
                                                    title="Model Popup All Category" 
                                                    applyClick={this.modalPopupApplyAllBrandReward} 
                                                    cancelClick={this.modalPopupCloseAllBrandReward}
                                                    preSelectedIds={this.state.allBrandSelectedRewardIds}
                                                    preSelectedLabels={this.state.allBrandSelectedRewardLabels}>
                                                </AllBrandModalPopup>
                                            </div> : "";



        //Reward search bar html for individual product
        const searchBarRewardIndividualProduct = this.state.rewardIndividualProductSearchBoxFlag?                            
                                                    <div className="form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="rewardIndividualProductSearchBar" id="rewardIndividualProductSearchBar" 
                                                            value={this.state.individualProductSelectedRewardLabels}
                                                            className={this.state.errorIndividualProductSelectedRewardFlag?"form-control error":"form-control"} 
                                                            placeholder="Search" onClick={this.modalPopupOpenIndividualProductReward}/>
                                                    </div> : "";

        //Reward search bar html for category
        const searchBarRewardAllCategory = this.state.rewardAllCategorySearchBoxFlag?                            
                                                <div className="form-group has-search div-left-pad indivisual-search-box">
                                                    {searchIcon}
                                                    <input type="text" autoComplete="off" name="rewardAllCategorySearchBar" id="rewardAllCategorySearchBar" 
                                                        value={this.state.allCategorySelectedRewardLabels}
                                                        className={this.state.errorAllCategorySelectedRewardFlag?"form-control error":"form-control"} 
                                                        placeholder="Search" onClick={this.modalPopupOpenAllCategoryReward}/>
                                                </div> : "";

        //Reward search bar html for brand
        const searchBarRewardAllBrand = this.state.rewardAllBrandSearchBoxFlag?                            
                                            <div className="form-group has-search div-left-pad indivisual-search-box">
                                                {searchIcon}
                                                <input type="text" autoComplete="off" name="rewardAllBrandSearchBar" id="rewardAllBrandSearchBar" 
                                                    value={this.state.allBrandSelectedRewardLabels}
                                                    className={this.state.errorAllBrandSelectedRewardFlag?"form-control error":"form-control"}
                                                    placeholder="Search" onClick={this.modalPopupOpenAllBrandReward}/>
                                            </div> : "";

       
        //Reward another inclution category modal popup
        const allCategoryAnotherInclutionRewardModalPop = this.state.popupOpenAllCategoryAnotherInclutionReward ? 
                                                    <div className={this.state.popupOpenAllCategoryAnotherInclutionReward ? "showModal" : "hideModal"}>
                                                        <AllCategoryModalPopup  
                                                            showModal={this.state.popupOpenAllCategoryAnotherInclutionReward}                               
                                                            title="Model Popup All Brand Another Inclution" 
                                                            applyClick={this.modalPopupApplyAllCategoryAnotherInclutionReward} 
                                                            cancelClick={this.modalPopupCloseAllCategoryAnotherInclutionReward}
                                                            preSelectedIds={this.state.allCategoryAnotherInclutionRewardSelectedIds}
                                                            preSelectedLabels={this.state.allCategoryAnotherInclutionRewardSelectedLabels}>
                                                        </AllCategoryModalPopup>
                                                    </div> : "";

        //another inclution brand modal popup
        const allBrandAnotherInclutionRewardModalPop = this.state.popupOpenAllBrandAnotherInclutionReward ? 
                                                    <div className={this.state.popupOpenAllBrandAnotherInclutionReward ? "showModal" : "hideModal"}>
                                                        <AllBrandModalPopup  
                                                            showModal={this.state.popupOpenAllBrandAnotherInclutionReward}                               
                                                            title="Model Popup All Category Another Inclution" 
                                                            applyClick={this.modalPopupApplyAllBrandAnotherInclutionReward} 
                                                            cancelClick={this.modalPopupCloseAllBrandAnotherInclutionReward}
                                                            preSelectedIds={this.state.allBrandAnotherInclutionRewardSelectedIds}
                                                            preSelectedLabels={this.state.allBrandAnotherInclutionRewardSelectedLabels}>
                                                        </AllBrandModalPopup>
                                                    </div> : ""; 

        // Reward another inclution search bar html for brand
        const searchBarAnotherInclutionAllCategoryReward = this.state.allCategorySearchBoxAnotherInclutionRewardFlag?                            
                                                        <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                            {searchIcon}
                                                            <input type="text" autoComplete="off" name="allCategorySearchBarAnotherInclutionReward" id="allCategorySearchBarAnotherInclutionReward" 
                                                                value={this.state.allCategoryAnotherInclutionRewardSelectedLabels}
                                                                className={this.state.errorAllCategoryAnotherInclutionRewardSelectedFlag?"form-control error":"form-control"}
                                                                placeholder="Search" onClick={this.modalPopupOpenAllCategoryAnotherInclutionReward}/>
                                                        </div> : <div className="col-md-5"></div>;
        
        // Reward another inclution search bar html for brand
        const searchBarAnotherInclutionAllBrandReward = this.state.allBrandSearchBoxAnotherInclutionRewardFlag?                            
                                                    <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="allBrandSearchBarAnotherInclutionReward" id="allBrandSearchBarAnotherInclutionReward" 
                                                            value={this.state.allBrandAnotherInclutionRewardSelectedLabels}
                                                            className={this.state.errorAllBrandAnotherInclutionRewardSelectedFlag?"form-control error":"form-control"}
                                                            placeholder="Search" onClick={this.modalPopupOpenAllBrandAnotherInclutionReward}/>
                                                    </div> : <div className="col-md-5"></div>;    
                                                    
        
        //reward exclude individual product modal popup
        const rewardIndividualProductModalPopExclude = this.state.rewardPopupOpenIndividualProductExcludeFlag ? 
                                                    <div className={this.state.rewardPopupOpenIndividualProductExcludeFlag ? "showModal" : "hideModal"}>
                                                        <IndividualProductModalPopup  
                                                            showModal={this.state.rewardPopupOpenIndividualProductExcludeFlag}                               
                                                            title="Model Popup Individual Products" 
                                                            applyClick={this.rewardModalPopupApplyIndividualProductExclude} 
                                                            cancelClick={this.rewardModalPopupCloseIndividualProductExclude}
                                                            preSelectedIds={this.state.rewardIndividualProductSelectedExcludeIds}
                                                            preSelectedLabels={this.state.rewardIndividualProductSelectedExcludeLabels}>
                                                        </IndividualProductModalPopup>
                                                    </div> : ""; 
        
        //reward exclude category modal popup
        const rewardAllCategoryModalPopExclude = this.state.rewardPopupOpenAllCategoryExcludeFlag ? 
                                            <div className={this.state.rewardPopupOpenAllCategoryExcludeFlag ? "showModal" : "hideModal"}>
                                                <AllCategoryModalPopup  
                                                    showModal={this.state.rewardPopupOpenAllCategoryExcludeFlag}                               
                                                    title="Model Popup All Brand" 
                                                    applyClick={this.rewardModalPopupApplyAllCategoryExclude} 
                                                    cancelClick={this.rewardModalPopupCloseAllCategoryExclude}
                                                    preSelectedIds={this.state.rewardAllCategorySelectedExcludeIds}
                                                    preSelectedLabels={this.state.rewardAllCategorySelectedExcludeLabels}>
                                                </AllCategoryModalPopup>
                                            </div> : "";

        //reward exclude brand modal popup
        const rewardAllBrandModalPopExclude = this.state.rewardPopupOpenAllBrandExcludeFlag ? 
                                            <div className={this.state.rewardPopupOpenAllBrandExcludeFlag ? "showModal" : "hideModal"}>
                                                <AllBrandModalPopup  
                                                    showModal={this.state.rewardPopupOpenAllBrandExcludeFlag}                               
                                                    title="Model Popup All Category" 
                                                    applyClick={this.rewardModalPopupApplyAllBrandExclude} 
                                                    cancelClick={this.rewardModalPopupCloseAllBrandExclude}
                                                    preSelectedIds={this.state.rewardAllBrandSelectedExcludeIds}
                                                    preSelectedLabels={this.state.rewardAllBrandSelectedExcludeLabels}>
                                                </AllBrandModalPopup>
                                            </div> : "";

        //reward another exclution category modal popup
        const rewardAllCategoryAnotherExclutionModalPop = this.state.rewardPopupOpenAllCategoryAnotherExclutionFlag ? 
                                                    <div className={this.state.rewardPopupOpenAllCategoryAnotherExclutionFlag ? "showModal" : "hideModal"}>
                                                        <AllCategoryModalPopup  
                                                            showModal={this.state.rewardPopupOpenAllCategoryAnotherExclutionFlag}                               
                                                            title="Model Popup All Brand Another Exclution" 
                                                            applyClick={this.rewardModalPopupApplyAllCategoryAnotherExclution} 
                                                            cancelClick={this.rewardModalPopupCloseAllCategoryAnotherExclution}
                                                            preSelectedIds={this.state.rewardAllCategoryAnotherExclutionSelectedIds}
                                                            preSelectedLabels={this.state.rewardAllCategoryAnotherExclutionSelectedLabels}>
                                                        </AllCategoryModalPopup>
                                                    </div> : "";

        // reward another inclution brand modal popup
        const rewardAllBrandAnotherExclutionModalPop = this.state.rewardPopupOpenAllBrandAnotherExclutionFlag ? 
                                                    <div className={this.state.rewardPopupOpenAllBrandAnotherExclutionFlag ? "showModal" : "hideModal"}>
                                                        <AllBrandModalPopup  
                                                            showModal={this.state.rewardPopupOpenAllBrandAnotherExclutionFlag}                               
                                                            title="Model Popup All Category Another Exclution" 
                                                            applyClick={this.rewardModalPopupApplyAllBrandAnotherExclution} 
                                                            cancelClick={this.rewardModalPopupCloseAllBrandAnotherExclution}
                                                            preSelectedIds={this.state.rewardAllBrandAnotherExclutionSelectedIds}
                                                            preSelectedLabels={this.state.rewardAllBrandAnotherExclutionSelectedLabels}>
                                                        </AllBrandModalPopup>
                                                    </div> : "";                                             
     

        //reward exclude search bar html for individual product
        const rewardSearchBarIndividualProductExclude = this.state.rewardExcludingIndividualProductSearchBoxFlag?                            
                                                    <div className="form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="rewardExcludeIndividualProductSearchBar" id="rewardExcludeIndividualProductSearchBar" 
                                                            value={this.state.rewardIndividualProductSelectedExcludeLabels}
                                                            className={this.state.errorRewardIndividualProductSelectedExcludeFlag?"form-control error":"form-control"} 
                                                            placeholder="Search" onClick={this.rewardModalPopupOpenIndividualProductExclude}/>
                                                    </div> : "";

        //reward exclude search bar html for category
        const rewardSearchBarAllCategoryExclude = this.state.rewardExcludingAllCategorySearchBoxFlag?                            
                                                <div className="form-group has-search div-left-pad indivisual-search-box">
                                                    {searchIcon}
                                                    <input type="text" autoComplete="off" name="rewardExcludeAllCategorySearchBar" id="rewardExcludeAllCategorySearchBar"
                                                        value={this.state.rewardAllCategorySelectedExcludeLabels}
                                                        className={this.state.errorRewardAllCategorySelectedExcludeFlag?"form-control error":"form-control"} 
                                                        placeholder="Search" onClick={this.rewardModalPopupOpenAllCategoryExclude}/>
                                                </div> : "";

        //reward exclude search bar html for brand
        const rewardSearchBarAllBrandExclude = this.state.rewardExcludingAllBrandSearchBoxFlag?                            
                                            <div className="form-group has-search div-left-pad indivisual-search-box">
                                                {searchIcon}
                                                <input type="text" autoComplete="off" name="rewardExcludeAllBrandSearchBar" id="rewardExcludeAllBrandSearchBar" 
                                                    value={this.state.rewardAllBrandSelectedExcludeLabels}
                                                    className={this.state.errorRewardAllBrandSelectedExcludeFlag?"form-control error":"form-control"}
                                                    placeholder="Search" 
                                                    onClick={this.rewardModalPopupOpenAllBrandExclude}/>
                                            </div> : "";

        //reward another Exclution search bar html for brand
        const rewardSearchBarAnotherExclutionAllBrand = this.state.rewardAllBrandSearchBoxAnotherExclutionFlag?                            
                                                    <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="rewardAllBrandSearchBarAnotherExclution" id="rewardAllBrandSearchBarAnotherExclution" 
                                                            value={this.state.rewardAllBrandAnotherExclutionSelectedLabels}
                                                            className={this.state.errorRewardAllBrandAnotherExclutionSelectedFlag?"form-control error":"form-control"}
                                                            placeholder="Search" onClick={this.rewardModalPopupOpenAllBrandAnotherExclution}/>
                                                    </div> : <div className="col-md-5"></div>;
        
        //reward another Exclution search bar html for Category
        const rewardSearchBarAnotherExclutionAllCategory = this.state.rewardAllCategorySearchBoxAnotherExclutionFlag?                            
                                                    <div className="col-md-5 form-group has-search div-left-pad indivisual-search-box">
                                                        {searchIcon}
                                                        <input type="text" autoComplete="off" name="rewardAllCategorySearchBarAnotherExclution" id="rewardAllCategorySearchBarAnotherExclution" 
                                                            value={this.state.rewardAllCategoryAnotherExclutionSelectedLabels}
                                                            className={this.state.errorRewardAllCategoryAnotherExclutionSelectedFlag?"form-control error":"form-control"} 
                                                            placeholder="Search" onClick={this.rewardModalPopupOpenAllCategoryAnotherExclution}/>
                                                    </div> : <div className="col-md-5"></div>;


        // reward exclude first select box
        const rewardAddExclutionRuleSelectHTML = this.state.rewardAddExclutionRuleSelectFlag ?
                                            <>
                                                <div className="col-sm-12 inline-flex top-pad-12 wrap-inc-prod">
                                                    {dotSvg}
                                                    <div><label className="gray-label break-sentance">Excluding products</label></div>
                                                    <div>
                                                        <select id="rewardIfTheCustomerExcludingProduct"
                                                            className={this.state.errorRewardIfTheCustomerExcludingProductFlag?"control-label select-type error":"control-label select-type"}
                                                            name="rewardIfTheCustomerExcludingProduct" onChange={this.rewardHandleIfTheCustomerExcludingProductsChange}>
                                                                <option value="">Please select a value</option>
                                                                <option value="individual_products">Individual Products</option>
                                                                <option value="all_category">In category</option>  
                                                                <option value="in_brand">In brand</option>                                   
                                                        </select> 
                                                    </div>
                                                    <div className="col-md-5">
                                                        {rewardSearchBarIndividualProductExclude || rewardSearchBarAllCategoryExclude || rewardSearchBarAllBrandExclude}
                                                    </div>                                                    
                                                    <div className="col-sm-2 delete-row-section">
                                                        <span className="delete-row-wrap">
                                                            <button className="delete-btn-icon btn" onClick={this.rewardHandleAddExclutionRuleRemoveClick}>
                                                                <span className="delete-icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    (rewardSearchBarAllCategoryExclude !=="" || rewardSearchBarAllBrandExclude !=="") &&  
                                                    this.state.rewardAddAnotherExclutionRuleBtnFlag &&  
                                                        <div className="col-sm-12 inline-flex another-inclution">
                                                            <div className="btn-group top-pad-15 top-bottom-5 margin-50-left">
                                                                <button className="btn btn-light" onClick={this.rewardHandleAddAnotherExclutionRuleBtnClick}>
                                                                    <span className="click-text"><svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" color="primary" className="base__StyledIcon-a9u0e1-0 hduNwj"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg> Add another exclution rule</span>
                                                                </button>
                                                            </div>
                                                        </div>                                                                      
                                                }
                                                {
                                                    rewardSearchBarAllCategoryExclude !=="" && this.state.rewardAddAnotherExclutionRuleSelectFlag && 
                                                        <div className="col-sm-12 inline-flex another-inclution">
                                                            <div className="col-sm-1"></div>
                                                            <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                            <div>
                                                                <select id="rewardIfTheCustomerExcludingBrand"
                                                                    className="control-label select-type" 
                                                                    name="rewardIfTheCustomerExcludingBrand" 
                                                                    onChange={this.rewardHandleAddAnotherExclutionCategoryChange}>
                                                                        <option value="">Please select a value</option>                                                                             
                                                                        <option value="in_brand">In brand</option>                                   
                                                                </select>
                                                            </div>
                                                            {rewardSearchBarAnotherExclutionAllBrand}
                                                            <div className="col-sm-2 delete-row-section">
                                                                <span className="delete-row-wrap">
                                                                    <button className="delete-btn-icon btn" onClick={this.rewardHandleAddAnotherExclutionRuleRemoveClick}>
                                                                        <span className="delete-icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                        </span>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                    rewardSearchBarAllBrandExclude !=="" && this.state.rewardAddAnotherExclutionRuleSelectFlag && 
                                                        <div className="col-sm-12 inline-flex another-inclution">
                                                            <div className="col-sm-1"></div>
                                                            <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                            <div>
                                                                <select id="rewardIfTheCustomerExcludingBrand"
                                                                    className="control-label select-type" 
                                                                    name="rewardIfTheCustomerExcludingBrand" 
                                                                    onChange={this.rewardHandleAddAnotherExclutionBrandChange}>
                                                                        <option value="">Please select a value</option>                                                                             
                                                                        <option value="in_category">In category</option>                                   
                                                                </select>
                                                            </div>
                                                            {rewardSearchBarAnotherExclutionAllCategory}
                                                            <div className="col-sm-2 delete-row-section">
                                                                <span className="delete-row-wrap">
                                                                    <button className="delete-btn-icon btn" onClick={this.rewardHandleAddAnotherExclutionRuleRemoveClick}>
                                                                        <span className="delete-icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                        </span>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                             :""


        // reward product category brand include exclude html - for both reward type DiscountOnProducts & FixedPriceForOfProduct
        const rewardProductCategoryBrandIncExlHTML = this.state.rewardFixedPriceForOfProductFlag || this.state.rewardDiscountOnProductsFlag ?
                                                    <>
                                                        <div className="col-sm-12 inline-flex top-pad-12 wrap-inc-prod">
                                                            {dotSvg}
                                                            <div><label className="gray-label break-sentance">Including products</label></div>
                                                            <div>
                                                                <select id="rewardIncludingProduct"
                                                                    className={this.state.errorRewardIncludingProductValFlag?"control-label select-type error":"control-label select-type"}
                                                                    name="rewardIncludingProduct" onChange={this.handleRewardIncludingProCatBrandChange}>
                                                                        <option value="">Please select a value</option>
                                                                        <option value="individual_products">Individual Products</option>
                                                                        <option value="all_products">All Products</option>  
                                                                        <option value="all_category">In category</option>  
                                                                        <option value="in_brand">In brand</option>                                   
                                                                </select> 
                                                            </div>
                                                            <div className="col-md-5">
                                                                {searchBarRewardIndividualProduct || searchBarRewardAllCategory || searchBarRewardAllBrand}
                                                            </div>                                                            
                                                        </div>
                                                        {
                                                            (searchBarRewardAllCategory !=="" || searchBarRewardAllBrand !=="") &&  this.state.addAnotherInclutionRuleRewardBtnFlag
                                                                &&  
                                                                <div className="col-sm-12 inline-flex top-pad-12 wrap-inc-prod">
                                                                    <div className="btn-group top-pad-15 top-bottom-5 margin-50-left">
                                                                        <button className="btn btn-light" onClick={this.handleAddAnotherInclutionRuleRewardBtnClick}>
                                                                            <span className="click-text"><svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" color="primary" className="base__StyledIcon-a9u0e1-0 hduNwj"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg> Add another inclusion rule</span>
                                                                        </button>
                                                                    </div> 
                                                                </div>                                                                     
                                                        }
                                                        {searchBarRewardAllCategory !=="" && this.state.addAnotherInclutionRuleRewardSelectFlag && 
                                                            <div className="col-sm-12 inline-flex another-inclution">
                                                                <div className="col-sm-1"></div>
                                                                <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                                <div>
                                                                    <select id="rewardIncludingBrandAnotherInc"
                                                                        className="control-label select-type" 
                                                                        name="rewardIncludingBrandAnotherInc" 
                                                                        onChange={this.handleAddAnotherInclutionCategoryRewardChange}>
                                                                            <option value="">Please select a value</option>                                                                             
                                                                            <option value="in_brand">In brand</option>                                   
                                                                    </select>
                                                                </div>
                                                                {searchBarAnotherInclutionAllBrandReward}
                                                                <div className="col-sm-2 delete-row-section">
                                                                    <span className="delete-row-wrap">
                                                                        <button className="delete-btn-icon btn" onClick={this.handleAddAnotherInclutionRuleRewardRemoveClick}>
                                                                            <span className="delete-icon">
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                            </span>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {searchBarRewardAllBrand !=="" && this.state.addAnotherInclutionRuleRewardSelectFlag && 
                                                            <div className="col-sm-12 inline-flex another-inclution">
                                                                <div className="col-sm-1"></div>
                                                                <div className="gray-and"><label className="gray-label break-sentance">And</label></div>
                                                                <div>
                                                                    <select id="rewardIncludingCategoryAnotherInc"
                                                                        className="control-label select-type" 
                                                                        name="rewardIncludingCategoryAnotherInc" 
                                                                        onChange={this.handleAddAnotherInclutionBrandRewardChange}>
                                                                            <option value="">Please select a value</option>                                                                             
                                                                            <option value="in_category">In category</option>                                   
                                                                    </select>
                                                                </div>
                                                                {searchBarAnotherInclutionAllCategoryReward}
                                                                <div className="col-sm-2 delete-row-section">
                                                                    <span className="delete-row-wrap">
                                                                        <button className="delete-btn-icon btn" onClick={this.handleAddAnotherInclutionRuleRewardRemoveClick}>
                                                                            <span className="delete-icon">
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" aria-labelledby="bd-icon-153" color="primary" className=""><title id="bd-icon-153">delete</title><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path></svg>
                                                                            </span>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.rewardAddExclutionRuleBtnFlag ? <div className="col-sm-12 inline-flex top-pad-12 wrap-inc-prod"><div className="btn-group top-pad-15 top-bottom-5"><button className="btn btn-light" onClick={this.rewardHandleAddExclutionRuleClick}><span className="click-text"><svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" color="primary" className="base__StyledIcon-a9u0e1-0 hduNwj"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg> Add exclusion rule</span></button></div></div> : ""}
                                                        {rewardAddExclutionRuleSelectHTML}
                                                    </> : ""
                                        
        return( 
            <div>
                {cancelModalPop}
                {this.state.showAlert ? (
                    <Alert type={this.state.type}
                        onClosePopup={this.closeAlert}
                        message={this.state.alertMessage}
                    />
                    ) : null}
                { individualProductModalPop || allCategoryModalPop || allBrandModalPop
                    || allBrandAnotherInclutionModalPop || allCategoryAnotherInclutionModalPop 
                    || individualProductModalPopExclude || allCategoryModalPopExclude || allBrandModalPopExclude
                    || allCategoryAnotherExclutionModalPop || allBrandAnotherExclutionModalPop
                    || rewardGiftCartInidividualProductModalPop || rewardFreeShippingZoneModalPop
                    || individualProductModalPopReward || allCategoryModalPopReward || allBrandModalPopReward
                    || allCategoryAnotherInclutionRewardModalPop || allBrandAnotherInclutionRewardModalPop
                    || rewardIndividualProductModalPopExclude || rewardAllCategoryModalPopExclude
                    || rewardAllBrandModalPopExclude || rewardAllCategoryAnotherExclutionModalPop
                    || rewardAllBrandAnotherExclutionModalPop
                }
                
                <form className="form-horizontal col-sm-12">        
                    <div className="container">
                        <div className="row">                
                            <div className="col-sm-12">                    
                                <div className="col-sm-6">
                                    <h1>{constants.CREATE_PROMOTION_TITLE}</h1>                            
                                </div>                        
                            </div>                
                        </div>
                        <div className="row body-container">
                            <div className="form-group col-sm-12">
                                <div className="col-sm-12">
                                    <h3>Rules</h3>
                                    <hr className="hr-line" />
                                    <div className="col-sm-12 inline-flex bottom-pad-15">
                                        <div className="">
                                            <span className="date-label">If the customer</span>
                                        </div>
                                        <div className={this.state.errorConditionFlag ? "rule-select-div error" : "rule-select-div"}>
                                            <select id="ifTheCustomer"
                                                className="control-label select-type" 
                                                name="ifTheCustomer" onChange={this.handleIfTheCustomerChange}>
                                                    <option value="" >Please select a condition</option>
                                                {
                                                    constants.IF_THE_CUSTOMER
                                                    && constants.IF_THE_CUSTOMER.map((element, key) => {
                                                        return <option key={"ifTheCustomer_"+key} value={element.value} >{element.label}</option>;
                                                    })
                                                }
                                            </select>                                   
                                        </div>
                                    </div>
                                    {this.state.ifCustomerConditionHTML || reachesAnOrderSubTotalHTML || buysProductsHTML}  
                                    {this.state.addExclutionRuleBtnFlag ? <div className="btn-group top-pad-15 top-bottom-5"><button className="btn btn-light" onClick={this.handleAddExclutionRuleClick}><span className="click-text"><svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor" strokeWidth="0" color="primary" className="base__StyledIcon-a9u0e1-0 hduNwj"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg> Add exclusion rule</span></button></div> : ""}
                                    {addExclutionRuleSelectHTML}
                                    
                                    {/* starting reward section*/}
                                    <div className="col-sm-12 inline-flex top-pad-15">
                                        <div className="">
                                            <span className="date-label">Then reward</span>
                                        </div>
                                        <div className={this.state.errorActionFlag ? "rule-select-div error" : "rule-select-div"}>
                                            <select id="thenReward" onChange={this.handleThenReward}
                                                className="control-label select-type" 
                                                name="thenReward">
                                                    <option value="" >Please select a reward</option>
                                                {
                                                    constants.THEN_REWARD
                                                    && constants.THEN_REWARD.map((element, key) => {
                                                        return <option key={"thenReward_"+key} value={element.value} >{element.label}</option>;
                                                    })
                                                }
                                            </select>                                   
                                        </div>
                                        {rewardGiftInTheCartHTML1 || rewardDiscountOnProductHTML1 || rewardDiscountOnOrderSubtotalHTML1 || rewardFixedPriceOfProductHTML1}
                                    </div>
                                    {rewardGiftInTheCartHTML2 || rewardFreeShippingHTML || rewardDiscountOnProductHTML2 || rewardDiscountOnOrderSubtotalHTML2 || rewardFixedPriceOfProductHTML2}
                                    {rewardFreeShippingSelectedZoneHTML || rewardProductCategoryBrandIncExlHTML}
                                    <hr className="hr-line" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                        <div className="container">
                            <div className="field f-right">
                                <div className="field-group">
                                    <ul className="field-action">
                                        <li>
                                            <button className="btn btn-primary btn-light" name="buttonButton1" onClick={this.showAlertCancel}>Back to promotion</button>
                                        </li>
                                        <li>
                                            <button type="submit" className="btn btn-primary create-btn" name="SubmitButton1" onClick={this.handleAddToPromotion}>Add to promotion</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>                
                    </footer>                            
                </form>
            </div>
        );
    }
  
}