import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ApiServices from '../../services/promotion-service';

export default class IndividualProductModalPopup extends React.Component {
    
    constructor(props) {
        super(props); 
        
        let tmpArr = [];
        if(this.props.preSelectedLabels.length > 0 && this.props.preSelectedLabels.length > 0)
            this.props.preSelectedIds.map((elm, index)=>{tmpArr.push(`${elm}_${this.props.preSelectedLabels.split(",|| ")[index]}`)})
        
        this.state = {
            productList: {},
            loading: false,
            selectedProductIds: this.props.preSelectedIds || [],
            selectedProductLabels: tmpArr
        } 
    }

    applyData = () =>{
        const label = this.state.selectedProductLabels.length > 0 ? 
                        this.state.selectedProductLabels.map((item)=>{
                            return item.split("_")[1]
                        }):[];

        const labelStr = label.length > 0 ? label.join(",|| ") : "";            
        if(labelStr != undefined)
            this.props.applyClick(this.state.selectedProductIds, labelStr);
    }

    checkProduct = (event, product) => {
        if(product.id==="" || product.id=== 0 || product.id===undefined) return;
        
        let label = `${product.id}_${product.name}`;
        if (event.target.checked) {
            //append to array
            this.setState({
                selectedProductIds: this.state.selectedProductIds.concat([product.id]),
                selectedProductLabels : this.state.selectedProductLabels.concat([label])
            })
        } 
        else {
            //remove from array
            this.setState({
                selectedProductIds : this.state.selectedProductIds.filter((val) => {return val!==product.id}),
                selectedProductLabels : this.state.selectedProductLabels.filter((val) => {return val!==label})
            })
        }        
    }
    
    async componentDidMount() {
        if(Object.keys(this.state.productList).length < 1) {
            this.setState({loading: true});
            let allProducts = await ApiServices.getProducts();
            this.setState({productList: allProducts});
        }

        this.setState({loading: false});  
        
        $("#searchKey").on("keyup", function(){
            var value = $(this).val().toLowerCase();
            $("#individualProductTable tr").filter(function(){
              $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });        
    }
    
    render() {

        const selectProduct = this.state.selectedProductLabels.length > 0 ?
                                <ul className="selected-lebel">
                                {
                                    this.state.selectedProductLabels.map((item, index)=>
                                        <li className="li-item" key={index}>{item.split("_")[1]}</li>
                                    )
                                }
                                </ul>
                                : "";

        const searchIcon = <FontAwesomeIcon className="form-control-feedback" icon={faSearch} color="blue"/>;
        
        const loader = this.state.loading ? <div className="loader-wraper"><span className="spinner-border spinner-pos spinner-border-lg"></span></div> : "" 

        const productListTbody =  Object.keys(this.state.productList).length > 0 ?
                                    <tbody id="individualProductTable">                                        
                                        {   
                                            Object.entries(this.state.productList).map((item, index) =>                                                
                                                <tr key={index}>
                                                    <td className="width-10">
                                                        <input type="checkbox" 
                                                            key={index}  
                                                            value={item[1].name} 
                                                            onChange={event=>this.checkProduct(event, item[1])}
                                                            defaultChecked={
                                                                this.props.preSelectedIds &&
                                                                    this.props.preSelectedIds.some(
                                                                        elm => elm == item[1].id
                                                                    )
                                                            }
                                                        />
                                                        </td>
                                                    <td className="width-50">{item[1].name}</td>
                                                    <td className="width-25">{item[1].sku}</td>
                                                    <td className="width-15">$ {item[1].price}</td>
                                                </tr>                                                   
                                            )
                                        }                                        
                                    </tbody>
                                    : ""

        return (
            <div id="individualProductModalPopup" className={this.props.showModal ? "modal fade show" : "modal fade hide"} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content individual-prod-alert-model-content">                    
                        <div className="modal-body individual-prod-alert-model-body">
                            <h3>Select Products</h3>                            
                            <div className="individual-prod-alert-model-text">
                                {selectProduct}
                                <div className="col-md-12 form-group has-search div-left-pad div-right-pad indivisual-search-box">
                                    {searchIcon}
                                    <input type="text" name="searchKey" id="searchKey" className="form-control" placeholder="Search by product name or sku"  autoComplete="off"/>
                                    
                                    <table className="table width-100">
                                        <thead>
                                            <tr>
                                                <th className="width-10"></th>
                                                <th className="width-50">Name</th>
                                                <th className="width-25">SKU</th>
                                                <th className="width-15">Price</th>
                                            </tr>
                                        </thead>
                                        {productListTbody}                                        
                                    </table>                                    
                                </div>
                                {loader}  
                            </div>
                            <div className="model-myfooter"> 
                                <button type="button" className="btn btn-light" onClick={this.props.cancelClick}> Cancel </button>
                                <button type="button" className="btn btn-secondary custom-btn-width" onClick={this.applyData} data-dismiss="modal">Apply</button>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div> 
        )
    }
}

IndividualProductModalPopup.propTypes = {
    showModal: PropTypes.bool, 
    title: PropTypes.string,
    applyClick: PropTypes.func,
    cancelClick: PropTypes.func,
    preSelectedIds: PropTypes.array,
    preSelectedLabels: PropTypes.string
}

