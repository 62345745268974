import * as constants from '../constants';

var brandList = {}
var categoryList = {}
var productList = {}
var zoneList = {}
var countryList = {}
var customerGroupList = {}


async function getCustomerGroup(page_no){
    //checking for existing data
    if(Object.keys(customerGroupList).length > 0)
        return customerGroupList;
    let storeHash = constants.STROE_HASH;
    //perform a fetch to api
    let url = constants.API_URL + storeHash + "/customergroup/0" + constants.API_VERSION;

    try{
        const response = await fetch(url, {
            method:'GET'
        });
        customerGroupList = await response.json();
        return customerGroupList;
    }catch{
         console.log("no customer group found!");
    }
}

// countrylist
async function getCountryList(){
    //checking for existing data
    if(Object.keys(countryList).length > 0)
        return countryList;
    let storeHash = constants.STROE_HASH;
    //perform a fetch to api
    let url = constants.API_URL + storeHash + "/countrylist/0" + constants.API_VERSION;

    try{
        const response = await fetch(url, {
            method:'GET'
        });
        countryList = await response.json();
        return countryList;
    }catch{
        console.log("no country found!");
    }
}

async function getProducts(){
    //checking for existing data
    if(Object.keys(productList).length > 0)
        return productList;
    let storeHash = constants.STROE_HASH;
    //perform a fetch to api for product list
    let url = constants.API_URL + storeHash + constants.PRODUCT_LIST_ENDPOINT_STRING + "/0" + constants.API_VERSION
    try{
        const response = await fetch(url, {
            method:'GET'
        });
        productList = await response.json();        
        return productList;
    }catch{
        console.log("no product found!");
    }
}

// async function createPromotion
async function createPromotion(payload){

    try{
        const response = await fetch(constants.CREATE_PROMOTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if(response.status === 200){
            const res = await response.json();
            return res;
        }else{
            return "error";
        }
    }catch(error){
        return error;   
    }
}

async function createCoupon(payload){

    try{
        const response = await fetch(constants.CREATE_COUPON_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        if(response.status === 200){
            const res = await response.json();
            return res;
        }else{
            return "error";
        }
    }catch(error){
        return error;   
    }
}

async function getCategories(){
    //checking for existing data
    if(Object.keys(categoryList).length > 0)
        return categoryList; 
    let storeHash = constants.STROE_HASH;
    //perform a fetch to api for product list
    let url = constants.API_URL + storeHash + constants.CATAGORY_LIST_ENDPOINT_STRING + "/0" + constants.API_VERSION
    try{
        const response = await fetch(url, {
            method:'GET'
        });
        categoryList = await response.json();
        return categoryList;
    }catch{
        console.log("no category found!");
    }
}

async function getBrands(){
    //checking for existing data
    if(Object.keys(brandList).length > 0)
        return brandList; 
    let storeHash = constants.STROE_HASH;
    //perform a fetch to api for product list
    let url = constants.API_URL + storeHash + constants.BRAND_LIST_ENDPOINT_STRING + "/0" + constants.API_VERSION
    try{
        const response = await fetch(url, {
            method:'GET'
        });
        brandList = await response.json();
        return brandList;
    }catch{
        console.log("no brand found!");
    }
}
async function getPromotionList(page_no){
    let storeHash = constants.STROE_HASH;
    //perform a fetch to api
    let url = constants.API_URL + storeHash + constants.PROMOTION_LIST_ENDPOINT_STRING + "/" + page_no + constants.API_VERSION

    try{
        const response = await fetch(url, {
            method:'GET'
        });
        const data = await response.json();
        return data;
    }catch{
        console.log("no promotion found!");
    }
}

async function getZones(){
    //checking for existing data
    if(Object.keys(zoneList).length > 0)
        return zoneList; 
    let storeHash = constants.STROE_HASH;
    //perform a fetch to api for product list
    let url = constants.API_URL + storeHash + constants.ZONE_LIST_ENDPOINT_STRING + "/0" + constants.API_VERSION
    try{
        const response = await fetch(url, {
            method:'GET'
        });
        zoneList = await response.json();
        return zoneList;
    }catch{
        console.log("no zone found!");
    }
}
async function getCouponDQuantity (promotionId) {
    let storeHash = constants.STROE_HASH;
    let url = constants.API_URL + storeHash + constants.COUPON_COUNT_STRING  + promotionId + constants.API_VERSION;
    try{
        const response = await fetch(url, {
            method:'GET'
        });
        const data = await response.json();

        return data;
    }catch{
        console.log("no coupon code found!");
    }

}

export default{
    getCustomerGroup, getCountryList,
    getProducts, createPromotion, createCoupon,
    getCategories,
    getBrands,
    getPromotionList,
    getZones,
    getCouponDQuantity
}