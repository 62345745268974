import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import BlockUi from "react-block-ui";
import "../styles/block-ui.css";
import moment from "moment";
import axios from "axios";
import * as constants from "../constants";
import "../styles/promotion-style.scss";
import PromotionService from "../services/promotion-service";
import Alert from "./common/alert";
// import ModalPopup from '../modal-popup/modalPopup';

class PromotionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      total_page_count: 1,
      blocking: true,
      page_no: 1,
      page_count: 10,
      deleteItem: [],
      showModal: false,
      error: false,
      error_message: "",
      type: "",
      showAlert: false,
      alertMessage: "",
      showLoadingStatus: false
    };
  }

  async componentDidMount() {
    this.loadData();
  }
  async loadData() {
    const promotionList = await PromotionService.getPromotionList(
      this.state.page_no
    );
    if (!promotionList?.data) {
      this.setState({
        type: "danger",
        showAlert: true,
        alertMessage: "Something went wrong, please try again!",
      });
      return;
    } else {
      this.setState({
        showAlert: false,
      });
    }

    // console.log(promotionList);
    let total_count =
      promotionList.meta.pagination.total > 10
        ? Math.ceil(promotionList.meta.pagination.total / 10)
        : 1;
    this.setState({
      data: promotionList.data,
      total_page_count: total_count,
      blocking: false,
    });
    this.getCouponQuantityDetail(promotionList);
  }
  getCouponQuantityDetail = async (promotionList) => {
    this.setState({showLoadingStatus: true})
    let newData = promotionList.data.map(async (eachPromotion) => {
      return new Promise(async (resolve, reject) => {
        let data = await PromotionService.getCouponDQuantity(eachPromotion.id);
        eachPromotion.couponQuantity = data && data.count ? data.count : null;
        resolve(eachPromotion);
      });
    });

    // console.log(newData, "newdata===");
    //   console.log("Promise.all(newData)==", Promise.all(newData));
    Promise.all(newData).then((data) => {
      data.forEach((eachData) => {
        // console.log("eachData.couponQuantity===", eachData.couponQuantity);
        promotionList.data.forEach((eachPromotion) => {
          if (eachData.id === eachPromotion.id) {
            if (typeof eachData.couponQuantity !== "object") {
              eachPromotion.couponQuantity = eachData.couponQuantity;
            } else {
              eachPromotion.couponQuantity = null;
            }
          }
        });
      });
      this.setState({ data: promotionList.data, showLoadingStatus: false });
      // console.log("promotionList.data===", promotionList.data);
      // console.log("this.state.data===", this.state.data);
    });
  };
  onLeftArrowClick = async (event) => {
    event.preventDefault();
    if (this.state.page_no !== 1) {
      this.setState({ blocking: true });
      const updatedData = await PromotionService.getPromotionList(
        this.state.page_no - 1
      );
      this.props.location.success = null;
      if (updatedData.data.length) {
        this.setState((state) => {
          return {
            page_no: state.page_no - 1,
            data: updatedData.data,
            blocking: false,
          };
        });
        this.getCouponQuantityDetail(updatedData);
      }
    }
  };

  handleCreatePromotionClick = (event) => {
    event.preventDefault();
    this.props.history.push({ pathname: "/create-promotion" });
  };

  onRightArrowClick = async (event) => {
    event.preventDefault();
    if (this.state.page_no !== this.state.total_page_count) {
      this.setState({ blocking: true });
      const updatedData = await PromotionService.getPromotionList(
        this.state.page_no + 1
      );
      this.props.location.success = null;
      if (updatedData.data.length) {
        this.setState((state) => {
          return {
            page_no: state.page_no + 1,
            data: updatedData.data,
            blocking: false,
          };
        });
        this.getCouponQuantityDetail(updatedData);
      }
    }
  };

  /*
   * clearing unwanted bank children
   * to build tree structure
   */
  downloadCSVFromJson = (filename, arrayOfJson) => {
    // convert JSON to CSV
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");

    // Create link and download
    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  downloadCSV = async (promotionId) => {
    let storeHash = constants.STROE_HASH;
    this.setState({ blocking: true });
    let url =
      constants.API_URL +
      storeHash +
      constants.COUPON_LIST_ENDPOINT_STRING +
      promotionId +
      constants.API_VERSION;
    let headers = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios.get(url, headers).then(async (res) => {
      let couponData = null;
      if (res && res.data) {
        if (typeof res.data == "string") {
          couponData = eval(res.data);
        } else {
          couponData = res.data;
        }
        let csvFineName = "CouponList_" + promotionId + ".csv";
        let couponIdArr = couponData.map((eachCoupon) => {
          return { "Coupon Code": eachCoupon.code };
        });
        this.downloadCSVFromJson(csvFineName, couponIdArr);
        this.setState({ blocking: false });
      } else {
        // alert("No Coupons for this promotion");
        this.setState({
          blocking: false,
          showAlert: true,
          type: "danger",
          alertMessage: "No Coupons for this promotion",
        });
      }
    });
  };

  closePopup = () => {
    this.setState({ type: "", alertMessage: "", showAlert: false });
  };
  render() {
    console.log(this.state.data, "data state=== in render");
    const successMessage = this.props.location && this.props.location.success && (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            className="success-color"
          ></FontAwesomeIcon>
        </span>
        <span className="success_message">
          Promotion {this.props.location.promotionName} was created
          successfully!
        </span>
      </div>
    );

    // let sortedData = this.state.data && this.state.data.length ? this.state.data.sort((a,b) => b.date_created.localeCompare(a.date_created)) : []
    const tableData =
      this.state.data &&
      this.state.data.map((item, index) => (
        <tr
          key={item.id}
          className={
            !index && this.props.location && this.props.location.success
              ? "corner-rbbn"
              : ""
          }
        >
          <td className="left-aligment sku-cell-padding">
            {item.name.length > constants.PROMOTION_NAME_TRIM_CHAR ? (
              <OverlayTrigger
                placement="bottom"
                className="category-tooltip"
                overlay={
                  <Tooltip
                    id={"tooltip-sku_" + index}
                    className="inner-cat-tooltip"
                  >
                    <span>{item.name}</span>
                  </Tooltip>
                }
              >
                <span>
                  {item.name.slice(0, constants.PROMOTION_NAME_TRIM_CHAR)}
                  ...
                </span>
              </OverlayTrigger>
            ) : (
              item.name
            )}
          </td>
          <td className="left-aligment">
            {item.start_date
              ? moment(item.start_date).utc().format("MMMM Do YYYY")
              : ""}
          </td>
          <td className="left-aligment">
            {item.end_date
              ? moment(item.end_date).utc().format("MMMM Do YYYY")
              : "N/A"}
          </td>
          <td className="left-aligment">
            {
              (this.state.showLoadingStatus) ? "Loading..." : ((item.couponQuantity && item.status === "ENABLED") ? "Completed" : "Pending...")
              }
          </td>
          <td className="left-aligment">
          {item.created_from && item.created_from.toLowerCase()==="api"?
            <a
              href="javascript:void(0)"
              onClick={(e) => this.downloadCSV(item.id)}
            >
              {item.couponQuantity && item.status === "ENABLED"
                ? "Download CSV"
                : ""}
            </a>
            :""}
          </td>

          <td className="right-aligment padding-right-25">
            <span class="couponQty">{item.couponQuantity || ""}</span>
          </td>
          {/*<td className="center-aligment">
            <span class="createdFrom">{item.created_from && item.created_from.toLowerCase()==="api" ? "App" : "BigC"}</span>
          </td>*/}
          {/* <td><button className="table-update-btn" disabled onClick = {() => this.updateVbItem(item)}> Update</button></td> 
                <td>
                <a className="table-delete-btn" onClick={() => this.deletevbItem(item)}><FontAwesomeIcon icon={faTrash} size="lg" ></FontAwesomeIcon></a>
                </td> */}
        </tr>
      ));

    // const disableRightArrow = this.state.page_no === this.state.total_page_count
    // const disableLeftArrow = this.state.page_no === 1

    const viewData = (
      <div className="table-responsive">
        <table className="table table-bordered vb-table">
          <thead>
            <tr>
              <th className="left-aligment sku-cell-padding th-sku-width">
                Promotion Name
              </th>
              <th className="left-aligment th-name-width width16">
                Start Date
              </th>
              <th className="left-aligment th-category-width width16">
                End Date
              </th>
              <th className="left-aligment th-category-width width16">
                Coupon Status
              </th>
              <th className="left-aligment th-category-width width16"> </th>
              <th className="right-aligment th-category-width width16 padding-right-25">Quantity</th>
              {/* <th className="revert-aligment th-category-width">Created From</th> */}
              {/* <th className="delete-th-width"></th>
                        <th className="delete-th-width"></th> */}
            </tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
        <div className="icon-container">
          <span className="icon-left">
            <a href="" onClick={(event) => this.onLeftArrowClick(event)}>
              <FontAwesomeIcon icon={faCaretLeft} size="lg"></FontAwesomeIcon>
            </a>
          </span>
          <a href="" onClick={(event) => this.onRightArrowClick(event)}>
            <FontAwesomeIcon icon={faCaretRight} size="lg"></FontAwesomeIcon>
          </a>
          <span>
            {" "}
            Page {this.state.page_no} of {this.state.total_page_count}
          </span>
        </div>
      </div>
    );

    // const modalPopup = this.state.showModal ? <div className={this.state.showModal ? "showModal" : "hideModal"}><ModalPopup item={this.state.deleteItem} showModal={this.state.showModal} title={constants.DELETE_CONFIRMATION} yesClick={this.confirmDelete} noClick={this.deleteModalClose}></ModalPopup></div> : null;

    return (
      <div>
        <BlockUi
          tag="form"
          blocking={this.state.blocking}
          loader={<Spinner animation="border" />}
        >
          {/* {modalPopup} */}
          <div className="header-container">
            {this.state.showAlert ? (
              <Alert
                type={this.state.type}
                onClosePopup={this.closePopup}
                message={this.state.alertMessage}
              />
            ) : null}
            <div className="message-container">{successMessage}</div>
            {/* {this.state.error ? <div className="error-message-container">{this.state.error_message}</div> : null} */}
            <div className="header-create-promotion-sec">
            <button
              className="create-bundle-btn"
              onClick={(event) => this.handleCreatePromotionClick(event)}
            >
              {constants.CREATE_PROMOTION_BUTTON}
            </button>
            </div>
            <div className="visual-bundle-container">{viewData}</div>
          </div>
          {/* <div className="modal-backdrop"></div> */}
        </BlockUi>
      </div>
    );
  }
}

// PromotionList.propTypes = {
//     location: PropTypes.object,
//     history: PropTypes.array,
//     push: PropTypes.func,
//     success: PropTypes.string,
//     bundleProductCode: PropTypes.string
// }

export default withRouter(PromotionList);
