import React from 'react';
import PropTypes from 'prop-types';

export const CancelModalPopup = (props) => {
        return (
            <div id="deleteModal" className={props.showModal ? "modal fade show" : "modal fade hide"} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content cancel-alert-model-content">                    
                        <div className="modal-body cancel-alert-model-body">
                            <h3>Are sure you want to leave?</h3>                            
                            <div className="cancel-alert-model-text">If you leave this page you will lose your progress. Continue?</div>
                            <div className="model-myfooter"> 
                                <button type="button" className="btn btn-light" onClick={props.noClick}> Cancel </button>
                                <button type="button" className="btn btn-secondary custom-btn-width" onClick={ props.yesClick} data-dismiss="modal">Yes</button>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div> 
        )
}

CancelModalPopup.propTypes = {
    showModal: PropTypes.bool, 
    title: PropTypes.string,
    noClick: PropTypes.func,
    yesClick: PropTypes.func,
}

export default CancelModalPopup;