import React from "react";
import { Switch, Route } from "react-router-dom";

import "./App.css";
import "./styles/globals.css";

import CreatePromotion from "./components/create-promotion";
import AddRule from "./components/add-rule";
import PromotionList from "./components/promotion-list";

function App() {
  return (
    <div className="App container">
      <Switch>
        <Route path="/" exact component={PromotionList}></Route>
        <Route
          path="/create-promotion"
          exact
          component={CreatePromotion}
        ></Route>
        <Route path="/add-rule" exact component={AddRule}></Route>
      </Switch>
    </div>
  );
}

export default App;
