import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
export const InfoAlert = (props) => {
  const { message, onClosePopup, type } = props;

  return (
    <div className="alertContainer">
      <div>
        <Alert variant={type} bsPrefix="alert">
          <div className="closeAlertButton" onClick={onClosePopup}>
            x
          </div>
          <div>
            <p style={{ margin: "5px 20px", fontSize: "17px" }}>{message}</p>
          </div>
        </Alert>
      </div>
    </div>
  );
};

InfoAlert.propTypes = {
  message: PropTypes.string,
};

export default InfoAlert;
