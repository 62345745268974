import React from "react";
import * as constants from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ModalPopup from "./modal-popup/allCountryModalPopup";
import Alert from "./common/alert";
import Spinner from "react-bootstrap/Spinner";
import CounterInput from "react-counter-input";
import { Multiselect } from "multiselect-react-dropdown";
import PromotionService from "../services/promotion-service";
import BlockUi from "react-block-ui";

import moment from "moment";
import "../styles/block-ui.css";

export default class CreatePromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curDate: "",
      blocking: true,
      loader: true,
      showAlert: false,
      showInfoAlert: false,
      alertInfoMessage: "",
      alertMessage: "",
      removeEndDate: false,
      showBtnEndDate:
        props.location.createPromotionFormData?.showBtnEndDate === false
          ? false
          : true,
      showBtnWeekDays:
        props.location.createPromotionFormData?.showBtnWeekDays === false
          ? false
          : true,
      endDateContent:
        props.location.createPromotionFormData?.endDateContent || "",
      weeksDaysContent:
        props.location.createPromotionFormData?.weeksDaysContent || "",
      customerGroup:
        props.location.createPromotionFormData?.customerGroup || "",
      customerGroup2nd:
        props.location.createPromotionFormData?.customerGroup2nd || "",
      fields: props.location.createPromotionFormData?.fields || [],
      modalPopup: { showModal: false },
      customerGroupList:
        props.location.createPromotionFormData?.customerGroupList || [],
      multiselectOptions: [],
      createPromotionData: {},
      addedRuleSets:
        props.location.createPromotionFormData?.addedRuleSets || [],
      weeksMandatoryError: false,
      promotionName:
        props.location.createPromotionFormData?.promotionName || "",
      scheduleStartTime:
        props.location.createPromotionFormData?.scheduleStartTime ||
        constants.HOURS[0],
      scheduleStartDate:
        props.location.createPromotionFormData?.scheduleStartDate ||
        new Date().toISOString().slice(0, 10),
      scheduleEndDate:
        props.location.createPromotionFormData?.scheduleEndDate || "",
      scheduleEndTime:
        props.location.createPromotionFormData?.scheduleEndTime || "",
      scheduleAvailablility:
        props.location.createPromotionFormData?.scheduleAvailablility || 1,
      scheduleAvailablilityStartTime:
        props.location.createPromotionFormData
          ?.scheduleAvailablilityStartTime || "12:00 AM",
      scheduleAvailablilityEndTime:
        props.location.createPromotionFormData?.scheduleAvailablilityEndTime ||
        "11:59 PM",
      scheduleAvailablilityWeekDay:
        props.location.createPromotionFormData?.scheduleAvailablilityWeekDay ||
        [],
      couponQuantity:
        props.location.createPromotionFormData?.couponQuantity || "",
      couponPrefix: props.location.createPromotionFormData?.couponPrefix || "",
      couponFormat:
        props.location.createPromotionFormData?.couponFormat || "Alphanumeric",
      targetingCurrency:
        props.location.createPromotionFormData?.targetingCurrency || "USD",
      selectedCountries:
        props.location.createPromotionFormData?.selectedCountries || "",
      selectedCountriesLabel:
        props.location.createPromotionFormData?.selectedCountriesLabel || "",
      shippingDestinationIs:
        props.location.createPromotionFormData?.shippingDestinationIs || "is",
      selectedCountryNames:
        props.location.createPromotionFormData?.selectedCountryNames || [],
      selectedCustomergroup:
        props.location.createPromotionFormData?.selectedCustomergroup || "",
      totalUsageOfPromotion:
        props.location.createPromotionFormData?.totalUsageOfPromotion ||
        "NoLimit",
      totalUsageOfCouponCode:
        props.location.createPromotionFormData?.totalUsageOfCouponCode ||
        "Limit",
      usageWithOtherPromotion:
        props.location.createPromotionFormData?.usageWithOtherPromotion || true,
      selectedCountryCodes:
        props.location.createPromotionFormData?.selectedCountryCodes || [],
      addtargetingRuleIndex:
        props.location.createPromotionFormData?.addtargetingRuleIndex || -1,
      addtargetingRuleIndex1st:
        props.location.createPromotionFormData?.addtargetingRuleIndex1st || -1,
      addtargetingRuleIndex2nd:
        props.location.createPromotionFormData?.addtargetingRuleIndex2nd || -1,
      errorMessages: {},
    };
  }

  getCurDate = () => {
    let today = new Date();
    let strYear = today.getFullYear();
    let strMonth = today.getMonth() + 1;
    let strDay = today.getDate();
    if (strMonth < 10) {
      strMonth = "0" + strMonth.toString();
    }
    if (strDay < 10) {
      strDay = "0" + strDay.toString();
    }
    let curDate = strYear + "-" + strMonth + "-" + strDay;
    this.setState({ curDate: curDate });
  };

  selectedCountries = [];
  updatePromotionName = (event) => {
    document.getElementById("discountName").style.borderColor = "";
    this.setState({ promotionName: event.target.value });
  };
  updateScheduleStartDate = (event) => {
    this.setState({ scheduleStartDate: event.target.value });
  };
  updateScheduleEndDate = (event) => {
    this.setState({ scheduleEndDate: event.target.value });
  };
  onScheduleStartTimeChange = (event) => {
    this.setState({ scheduleStartTime: event.target.value });
  };
  updateScheduleEndTime = (event) => {
    this.setState({ scheduleEndTime: event.target.value });
  };
  updateScheduleAvailablility = (count) => {
    this.setState({ scheduleAvailablility: count });
  };
  updateSelectedWeekDays = (event) => {
    this.setState({
      scheduleAvailablilityWeekDay: event.target.value,
    });
  };
  startTimeSelectAvailFrom = (event) => {
    this.setState({ scheduleAvailablilityStartTime: event.target.value });
  };
  startTimeSelectAvailTo = (event) => {
    this.setState({ scheduleAvailablilityEndTime: event.target.value });
  };
  updateCouponQuantity = (event) => {
    if (event.target.value.length < 10) {
      this.setState({ couponQuantity: event.target.value });
    }
  };
  updateCouponPrefix = (event) => {
    if (event.target.value.length < 13) {
      this.setState({ couponPrefix: event.target.value.toUpperCase() });
    }
  };
  updateCouponFormat = (event) => {
    this.setState({ couponFormat: event.target.value });
  };
  updateTargetingCurrency = (event) => {
    this.setState({ targetingCurrency: event.target.value });
  };
  updateTotalUsageValue = (event) => {
    this.setState({ totalUsageOfPromotion: event.target.value });
  };
  updateTotalUsageCouponCode = (event) => {
    this.setState({ totalUsageOfCouponCode: event.target.value });
  };
  shippingDestinationIsChange = (event) => {
    this.setState({ shippingDestinationIs: event.target.value });
  };
  getSelectedCountries = (list) => {
    this.setState({ selectedCountries: list });
    let countryNames = list.map((eachCountry) => {
      return eachCountry.name;
    });
    let selectedCountryCodes = list.map((eachCountry) => {
      return { iso2_country_code: eachCountry.iso2_country_code };
    });
    this.setState({ selectedCountryNames: countryNames });
    this.setState({ selectedCountryCodes: selectedCountryCodes });
  };
  getSelectedCustomerGroup = (event) => {
    this.setState({ selectedCustomergroup: event.target.value });
  };

  loadCustomerGroupList = async () => {
    this.setState({ blocking: true });
    let customerGroupData = await PromotionService.getCustomerGroup(1);
    this.setState({ customerGroupList: customerGroupData });
    this.setState({ blocking: false });
  };

  loadCountryList = () => {
    this.setState({ loader: true });

    PromotionService.getCountryList().then((res, err) => {
      let countryListArray = res.map((eachCountryList) => {
        return eachCountryList;
      });
      this.setState({ multiselectOptions: countryListArray });
      this.setState({ loader: false });
    });
  };

  async componentDidMount() {
    this.setState({ blocking: false });
    let rulesData = [...this.state.addedRuleSets];
    if (this.props.location?.ruleRewardData) {
      rulesData.push(this.props.location.ruleRewardData);
      this.setState({ addedRuleSets: rulesData });
    }
    if (this.props.location.createPromotionFormData?.endDateContent) {
      this.addEndDate();
    }
    if (this.props.location.createPromotionFormData?.weeksDaysContent) {
      this.addLimitAvailWeeksDays();
    }

    this.getCurDate();
    //loading all apis data after page getting loaded and using these apis data through out the app
    PromotionService.getProducts();
    PromotionService.getCategories();
    PromotionService.getBrands();
    PromotionService.getZones();
    PromotionService.getCountryList();
    PromotionService.getCustomerGroup();
  }
  toggleBlocking() {
    this.setState({ blocking: !this.state.blocking });
  }

  /*
   * onclick adding html content for end date row
   */
  addEndDate = (event) => {
    event?.preventDefault();
    if (!this.state.scheduleEndDate && !this.state.scheduleEndTime) {
      this.setState({
        scheduleEndDate: new Date().toISOString().slice(0, 10),
        scheduleEndTime: constants.HOURS[1],
      });
    }
    this.setState(
      {
        showBtnEndDate: false,
      }
    );
  };

  /*
   * onclick remove html content for end date row
   */
  removeEndDate = (event) => {
    event.preventDefault();
    this.setState({ endDateContent: "" });
    this.setState({ showBtnEndDate: true });
    this.setState({ scheduleEndDate: "" });
    this.setState({ scheduleEndTime: "" });
  };

  /*
   * onclick adding html content for limit-avail-weeks-Days row
   */
  addLimitAvailWeeksDays = (event) => {
    event?.preventDefault();
    this.setState({ showBtnWeekDays: false });
  };

  /*
   * onclick remove html content for limit-avail-weeks-Days row
   */
  removeLimitAvailWeeksDays = (event) => {
    event.preventDefault();
    this.setState({ weeksDaysContent: "" });
    this.setState({ showBtnWeekDays: true });
    this.setState({ weeksMandatoryError: false });
    this.setState({
      scheduleAvailablilityEndTime: "11:59 PM",
      scheduleAvailablilityStartTime: "12:00 AM",
      scheduleAvailablilityWeekDay: [],
      scheduleAvailablility: 1,
    });
  };

  /*
   * multi select default method on select
   */
  onSelect = (selectedList, selectedItem) => {
    let weekArr = selectedList.map((eachDay) => {
      return eachDay;
    });
    this.setState({
      scheduleAvailablilityWeekDay: weekArr,
      weeksMandatoryError: false,
    });
  };

  /*
   * multi select default method on remove option
   */
  onRemove = (selectedList, selectedItem) => {
    let weekArr = selectedList.map((eachDay) => {
      return eachDay;
    });
    this.setState({ scheduleAvailablilityWeekDay: weekArr });
  };

  handleChangeTargeting = (event, index) => {
    const values = [...this.state.fields];
    if (event.target.value === "Customer Group is") {
      if (
        this.state.customerGroupList &&
        this.state.customerGroupList.length <= 0
      ) {
        this.loadCustomerGroupList();
      }
    }

    values[index].value = event.target.value;
    this.setState({ fields: values });
  };

  handleAddTargeting = () => {
    const values = [...this.state.fields];
    values.push({ value: null });
    this.setState({ fields: values });
  };

  handleRemoveTargeting = (i) => {
    const values = [...this.state.fields];
    let dataFound = values.find((p) => p.value === "Shipping Destination");
    if (dataFound) {
      this.setState({
        selectedCountries: [],
        selectedCountryNames: [],
        selectedCountryCodes: [],
      });
    }
    values.splice(i, 1);
    this.setState({ fields: values });
    i === 0 &&
      this.setState({ addtargetingRuleIndex1st: -1, customerGroup: "" });
    i === 1 &&
      this.setState({ addtargetingRuleIndex2nd: -1, customerGroup2nd: "" });
  };

  onChangeCustomerGroup = (event, idx) => {
    this.setState({ customerGroup: event.target.value });
    this.setState({ addtargetingRuleIndex: idx });
    idx === 0
      ? this.setState({ addtargetingRuleIndex1st: 1 })
      : this.setState({ addtargetingRuleIndex1st: -1 });
  };

  onChangeCustomerGroup2nd = (event, idx) => {
    this.setState({ customerGroup2nd: event.target.value });
    this.setState({ addtargetingRuleIndex: idx });
    idx === 1
      ? this.setState({ addtargetingRuleIndex2nd: 2 })
      : this.setState({ addtargetingRuleIndex2nd: -1 });
  };
  modalPopupOpen = () => {
    this.setState({ modalPopup: { showModal: true } });
  };

  modalPopupClose = () => {
    this.setState({ modalPopup: { showModal: false } });
  };

  handleAddRule = () => {
    this.props.history.push({ pathname: "/add-rule", pageData: this.state });
  };
  handleCreatePromotion = () => {
    this.setState({ showAlert: false });

    let error = false;
    let storeHash = constants.STROE_HASH;
    if (!this.state.promotionName) {
      document.getElementById("discountName").style.borderColor = "red";
      error = true;
      return;
    }

    let q = new Date();
    let m = q.getMonth();
    let d = q.getDate();
    let y = q.getFullYear();
    let q1 = this.state.scheduleStartDate.split("-");
    let m1 = q1[1]-1;
    let d1 = q1[2];
    let y1 = q1[0];
    
    if (new Date(y1, m1, d1) < new Date(y, m, d)) {
      document.getElementById("startDate").style.borderColor = "red";
      error = true;
      return;
    } else {
      document.getElementById("startDate").style.borderColor = "#ced4da";
      error = false;
    }

    if (
      !this.state.showBtnWeekDays &&
      this.state.scheduleAvailablilityWeekDay.length < 1
    ) {
      this.setState({
        weeksMandatoryError: !this.state.weeksMandatoryError,
        weeksDaysContent: "",
      });
      error = true;
      return;
    }

    if (!this.state.showBtnWeekDays) {
      let schStartTime = this.state.scheduleAvailablilityStartTime;
      let schEndTime = this.state.scheduleAvailablilityEndTime;
      if (schStartTime == "12:00 AM") {
        schStartTime = "00:00 AM";
      }
      if (schEndTime == "12:00 AM") {
        schEndTime = "00:00 AM";
      }
      let startTime = schStartTime.split(":");
      let endTime = schEndTime.split(":");
      let ampmStartVal = startTime[1].split(" ");
      let ampmEndVal = endTime[1].split(" ");

      let startSec =
        ampmStartVal[1] == "AM"
          ? parseInt(startTime[0]) * 3600 + parseInt(ampmStartVal[0]) * 60
          : (parseInt(startTime[0]) + 12) * 3600 +
            parseInt(ampmStartVal[0]) * 60;

      let endSec =
        ampmEndVal[1] == "AM"
          ? parseInt(endTime[0]) * 3600 + parseInt(ampmEndVal[0]) * 60
          : (parseInt(endTime[0]) + 12) * 3600 + parseInt(ampmEndVal[0]) * 60;

      if (startSec >= endSec) {
        document.getElementById("startTimeSelectAvailTo").style.borderColor =
          "red";
        this.setState({
          type: "danger",
          showAlert: true,
          alertMessage: "Available end time should be greater than start time",
        });
        error = true;
        return;
      } else {
        document.getElementById("startTimeSelectAvailTo").style.borderColor =
          "#ced4da";
        error = false;
      }
    }
    if (this.state.showBtnEndDate === false) {
      let startDate = new Date(this.state.scheduleStartDate);
      let endDate = new Date(this.state.scheduleEndDate);

      let schStartTime = this.state.scheduleStartTime;
      let schEndTime = this.state.scheduleEndTime;
      if (schStartTime == "12:00 AM") {
        schStartTime = "00:00 AM";
      }
      if (schEndTime == "12:00 AM") {
        schEndTime = "00:00 AM";
      }

      let startTime = schStartTime.split(":");
      let endTime = schEndTime.split(":");
      let ampmStartVal = startTime[1].split(" ");
      let ampmEndVal = endTime[1].split(" ");

      let startSec =
        ampmStartVal[1] == "AM"
          ? parseInt(startTime[0]) * 3600 + parseInt(ampmStartVal[0]) * 60
          : (parseInt(startTime[0]) + 12) * 3600 +
            parseInt(ampmStartVal[0]) * 60;

      let endSec =
        ampmEndVal[1] == "AM"
          ? parseInt(endTime[0]) * 3600 + parseInt(ampmEndVal[0]) * 60
          : (parseInt(endTime[0]) + 12) * 3600 + parseInt(ampmEndVal[0]) * 60;

      if (
        startDate.getTime() > endDate.getTime() ||
        (startDate.getTime() == endDate.getTime() && startSec >= endSec)
      ) {
        document.getElementById("endDate").style.borderColor = "red";
        this.setState({
          type: "danger",
          showAlert: true,
          alertMessage: "Schedule end date should be greater than start date!",
        });
        error = true;
        return;
      } else {
        document.getElementById("endDate").style.borderColor = "#ced4da";
        error = false;
      }
    }

    if (
      !this.state.couponQuantity ||
      Number(this.state.couponQuantity) <= 1 ||
      isNaN(Number(this.state.couponQuantity)) ||
      Number(this.state.couponQuantity) === undefined ||
      this.state.couponQuantity === ""
    ) {
      document.getElementById("couponQty").style.borderColor = "red";
      error = true;
      return;
    } else {
      document.getElementById("couponQty").style.borderColor = "#ced4da";
      error = false;
    }
    if (!this.state.couponPrefix) {
      document.getElementById("prefixCoupon").style.borderColor = "red";
      error = true;
      return;
    } else {
      document.getElementById("prefixCoupon").style.borderColor = "#ced4da";
      error = false;
    }
    if (this.state.addedRuleSets.length < 1) {
      this.setState({
        type: "danger",
        showAlert: true,
        alertMessage: "Please select some rules",
      });
      error = true;
      return;
    }
    if (!error) {
      this.setState({ blocking: true });
      let startTime = this.state.scheduleStartTime
        ? moment(this.state.scheduleStartTime, ["h:mm A"]).format("HH:mm:ss")
        : null;
      let endTime = this.state.scheduleEndTime
        ? moment(this.state.scheduleEndTime, ["h:mm A"]).format("HH:mm:ss")
        : null;
      let startTimeformated = this.state.scheduleAvailablilityStartTime
        ? moment(this.state.scheduleAvailablilityStartTime, ["h:mm A"]).format(
            "HH:mm:ss"
          )
        : null;
      let endTimeformated = this.state.scheduleAvailablilityEndTime
        ? moment(this.state.scheduleAvailablilityEndTime, ["h:mm A"]).format(
            "HH:mm:ss"
          )
        : null;
      let startDate = this.state.scheduleStartDate
        ? new Date(this.state.scheduleStartDate).toISOString()
        : null;
      let startDateFormatted =
        startDate.substr(0, startDate.length - 13) + `${startTime}` + "+00:00";
      let endDate = this.state.scheduleEndDate
        ? new Date(this.state.scheduleEndDate).toISOString()
        : null;
      let endDateFormatted = endDate
        ? endDate.substr(0, endDate.length - 13) + `${endTime}` + "+00:00"
        : null;
      let promotionObj = {};
      promotionObj.can_be_used_with_other_promotions = this.state.usageWithOtherPromotion;
      promotionObj.currency_code = this.state.targetingCurrency || "";
      promotionObj.current_uses = 0;
      promotionObj.customer = {};
      promotionObj.end_date = endDateFormatted;
      promotionObj.hash = storeHash;
      //promotionObj.max_uses = null;
      promotionObj.name = this.state.promotionName;
      promotionObj.redemption_type = "COUPON";
      if (this.state.selectedCustomergroup) {
        promotionObj.customer = {
          group_ids: [parseInt(this.state.selectedCustomergroup)],
          minimum_order_count: 0,
        };
      }
      promotionObj.rules =
        (this.state.addedRuleSets &&
          this.state.addedRuleSets.length &&
          this.state.addedRuleSets.map((ele) => ele.rule)) ||
        undefined;
      if (
        this.state.scheduleAvailablilityWeekDay.length > 0 &&
        this.state.scheduleAvailablility &&
        this.state.scheduleAvailablilityEndTime &&
        this.state.scheduleAvailablilityStartTime
      ) {
        promotionObj.schedule = {
          daily_end_time: endTimeformated,
          daily_start_time: startTimeformated,
          week_days:
            this.state.scheduleAvailablilityWeekDay.length > 0 &&
            this.state.scheduleAvailablilityWeekDay.map((ele) => ele.day),
          week_frequency: this.state.scheduleAvailablility,
        };
      } else {
        promotionObj.schedule = null;
      }
      promotionObj.shipping_address =
        this.state.selectedCountryCodes.length > 0
          ? this.state.shippingDestinationIs &&
            this.state.shippingDestinationIs === "not"
            ? {
                not: {
                  countries: this.state.selectedCountryCodes,
                },
              }
            : {
                countries: this.state.selectedCountryCodes,
              }
          : undefined;
      promotionObj.start_date = startDateFormatted;
      promotionObj.status = "ENABLED";
      promotionObj.stop = false;
      promotionObj.type = {};
      PromotionService.createPromotion(promotionObj).then((res) => {
        if (res && res.data) {
          let couponObj = {};
          couponObj.hash = storeHash;
          couponObj.id = res.data.id;
          couponObj.prefix = this.state.couponPrefix;
          couponObj.count = parseInt(this.state.couponQuantity);
          let promotionName = res.data.name;

          PromotionService.createCoupon(couponObj).then((res) => {
            this.setState({ blocking: false });
            this.setState(
              {
                showAlert: true,
                alertMessage: "Coupon creation is under process ",
                type: "info",
              },
              () => {
                this.props.history.push({
                  pathname: "/",
                  success: true,
                  promotionName: promotionName,
                  showInfoAlert: this.state.showAlert,
                  alertInfoMessage: this.state.alertMessage,
                  type: this.state.type,
                });
              }
            );
          });
        } else {
          this.setState({ blocking: false });
          this.setState({
            showAlert: true,
            alertMessage: "Unable to create promotion",
            type: "danger",
          });
        }
      });
    }
  };
  removeCountry = (value) => {
    let selectedCountriesArray = [...this.state.selectedCountries];
    let updatedData = selectedCountriesArray.filter((val) => {
      return val.id !== value.id;
    });
    let updatedDataIds = [...this.state.selectedCountryCodes];
    let updatedDatacodes = updatedDataIds.filter((val) => {
      return val.iso2_country_code !== value.iso2_country_code;
    });

    let updatedDataNames = [...this.state.selectedCountryNames];
    let updatedDataNamesArray = updatedDataNames.filter((val) => {
      return val !== value.name;
    });

    this.setState({ selectedCountries: updatedData });
    this.setState({ selectedCountryCodes: updatedDatacodes });
    this.setState({ selectedCountryNames: updatedDataNamesArray });
  };
  checkCategory = (event, category) => {
    if (event.target.checked) {
      let selectedCountriesArray = [...this.state.selectedCountries];
      selectedCountriesArray.push(category);
      let selectedCountryNamesArray = [...this.state.selectedCountryNames];
      selectedCountryNamesArray.push(category.name);
      let selectedCountryCodesArray = [...this.state.selectedCountryCodes];
      selectedCountryCodesArray.push({
        iso2_country_code: category.iso2_country_code,
      });
      this.setState({ selectedCountries: selectedCountriesArray });
      this.setState({ selectedCountryNames: selectedCountryNamesArray });
      this.setState({ selectedCountryCodes: selectedCountryCodesArray });
    } else {
      this.removeCountry(category);
    }
  };
  closeAlert = () => {
    this.setState({ showAlert: false, alertMessage: "", type: "" });
  };

  render() {
    const element = <FontAwesomeIcon icon={faTrash} color="blue" />;
    const searchIcon = (
      <FontAwesomeIcon
        className="form-control-feedback"
        icon={faSearch}
        color="blue"
      />
    );
    const modalPop = this.state.modalPopup.showModal ? (
      <div
        className={this.state.modalPopup.showModal ? "showModal" : "hideModal"}
      >
        <ModalPopup
          showModal={this.state.modalPopup.showModal}
          options={this.state.multiselectOptions}
          title="Model Popup"
          loader={this.state.loader}
          selectedCountries={this.state.selectedCountries}
          selectedCountriesLabel={this.state.selectedCountries}
          getSelectedCountries={this.getSelectedCountries}
          checkCategory={this.checkCategory}
          loadCountryList={this.loadCountryList}
          yesClick={this.modalPopupClose}
          noClick={this.modalPopupClose}
        ></ModalPopup>
      </div>
    ) : null;
    const customerGroupSelect = (
      <select
        className="form-select isIsNotSelect form-control"
        aria-label="Please select a rule"
        onChange={this.getSelectedCustomerGroup}
      >
        <option value="" disabled selected>
          Select Customer Group
        </option>
        {this.state.customerGroupList.map((customerGroup) => (
          <option value={customerGroup}>{customerGroup.name}</option>
        ))}
      </select>
    );
    const addTargetRule1 = (
      <li className="targetList">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 displayFlex">
              <div className="col-md-10 displayFlex">
                <div className="col-md-3 targetingCurrency">
                  <span className="input-group-btn inline-display">
                    <select
                      className="form-select form-control"
                      onChange={(e) => {
                        this.onChangeCustomerGroup(e, 0);
                        this.handleChangeTargeting(e, 0);
                      }}
                      value={this.state.customerGroup}
                    >
                      <option value="">Please select a rule</option>
                      <option value="Customer Group is">
                        Customer Group is
                      </option>
                      <option value="Shipping Destination">
                        Shipping Destination
                      </option>
                    </select>
                  </span>
                </div>
                {this.state.customerGroup === "Shipping Destination" &&
                this.state.addtargetingRuleIndex1st === 1 ? (
                  <>
                    <div className="col-md-3 isIsNotCon">
                      <select
                        className="form-select isIsNotSelect form-control"
                        onChange={(e) => {
                          this.shippingDestinationIsChange(e, 0);
                          this.handleChangeTargeting(e, 0);
                        }}
                        value={this.state.shippingDestinationIs}
                        aria-label="Please select a rule"
                      >
                        <option value="is">Is</option>
                        <option value="not">Is Not</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <div
                        className="width250"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: "1px solid #ced4da",
                          lineHeight: 2.2,
                          borderRadius: "5px",
                          padding: "0px 8px",
                          width: "180%",
                        }}
                        onClick={() => this.modalPopupOpen()}
                      >
                        <div style={{ width: "10%" }}>{searchIcon}</div>
                        <div style={{ width: "90%" }}>
                          {this.state.selectedCountryNames &&
                          this.state.selectedCountryNames.length > 0 ? (
                            <>
                              <span className="countryChip">
                                {this.state.selectedCountryNames[0]}
                              </span>
                              {this.state.selectedCountryNames.length > 1 ? (
                                <span className="countryChip">
                                  +
                                  {Number(
                                    this.state.selectedCountryNames.length
                                  ) - 1}
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            "Search"
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : this.state.customerGroup === "Customer Group is" &&
                  this.state.addtargetingRuleIndex1st === 1 ? (
                  <>
                    <div className="col-md-3 isIsNotCon">
                      <select
                        className="form-select isIsNotSelect form-control"
                        aria-label="Please select a rule"
                        onChange={this.getSelectedCustomerGroup}
                        value={this.state.selectedCustomergroup}
                      >
                        <option value="" disabled selected>
                          Select Customer Group
                        </option>
                        {this.state.customerGroupList.map((customerGroup) => (
                          <option value={customerGroup.id}>
                            {customerGroup.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : (
                  <div class="col-md-3 isIsNotCon">
                    <span></span>
                  </div>
                )}
              </div>
              <div className="col-md-2" style={{ display: "flex" }}>
                <div className="deleteIconDiv">
                  {this.state.fields.length === 1 && (
                    <button
                      type="button"
                      class="btn btn-light deleteBTN"
                      onClick={() => this.handleRemoveTargeting(0)}
                    >
                      <span className="deleteTargetingElement">{element}</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );

    const addTargetRule2 = (
      <>
        <li className="targetList">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 displayFlex">
                <div className="col-md-10 displayFlex">
                  <div className="col-md-3 targetingCurrency">
                    <span className="input-group-btn inline-display">
                      <select
                        className="form-select form-control"
                        onChange={(e) => {
                          this.onChangeCustomerGroup2nd(e, 1);
                          this.handleChangeTargeting(e, 1);
                        }}
                        value={this.state.customerGroup2nd}
                      >
                        <option value="">Please select a rule</option>
                        {this.state.customerGroup !== "Customer Group is" && (
                          <option value="Customer Group is">
                            Customer Group is
                          </option>
                        )}
                        {this.state.customerGroup !==
                          "Shipping Destination" && (
                          <option value="Shipping Destination">
                            Shipping Destination
                          </option>
                        )}
                      </select>
                    </span>
                  </div>
                  {this.state.customerGroup2nd === "Shipping Destination" &&
                  this.state.addtargetingRuleIndex2nd === 2 ? (
                    <>
                      <div className="col-md-3 isIsNotCon">
                        <select
                          className="form-select isIsNotSelect form-control "
                          onChange={(e) => {
                            this.shippingDestinationIsChange(e, 1);
                            this.handleChangeTargeting(e, 1);
                          }}
                          value={this.state.shippingDestinationIs}
                          aria-label="Please select a rule"
                        >
                          <option value="is">Is</option>
                          <option value="not">Is Not</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <div
                          class="width250"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "1px solid #ced4da",
                            lineHeight: 2.2,
                            borderRadius: "5px",
                            padding: "0px 8px",
                            width: "180%",
                          }}
                          onClick={(e) => this.modalPopupOpen(e)}
                        >
                          <div style={{ width: "10%" }}>{searchIcon}</div>
                          <div
                            style={{ width: "90%" }}
                            // onClick={(e) => this.modalPopupOpen(e)}
                          >
                            {this.state.selectedCountryNames &&
                            this.state.selectedCountryNames.length > 0 ? (
                              <>
                                <span className="countryChip">
                                  {this.state.selectedCountryNames[0]}
                                </span>
                                {this.state.selectedCountryNames.length > 1 ? (
                                  <span className="countryChip">
                                    +
                                    {Number(
                                      this.state.selectedCountryNames.length
                                    ) - 1}
                                  </span>
                                ) : null}
                              </>
                            ) : (
                              "Search"
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : this.state.customerGroup2nd === "Customer Group is" &&
                    this.state.addtargetingRuleIndex2nd === 2 ? (
                    <>
                      <div className="col-md-3 isIsNotCon">
                        <select
                          className="form-select isIsNotSelect form-control"
                          aria-label="Please select a rule"
                          onChange={this.getSelectedCustomerGroup}
                          value={this.state.selectedCustomergroup}
                        >
                          <option value="" disabled selected>
                            Select Customer Group
                          </option>
                          {this.state.customerGroupList.map((customerGroup) => (
                            <option value={customerGroup.id}>
                              {customerGroup.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  ) : (
                    <div class="col-md-3 isIsNotCon">
                      <span></span>
                    </div>
                  )}
                </div>
                <div className="col-md-3" style={{ display: "flex" }}>
                  <div className="deleteIconDiv">
                    <button
                      type="button"
                      class="btn btn-light deleteBTN"
                      onClick={() => this.handleRemoveTargeting(1)}
                    >
                      <span className="deleteTargetingElement">{element}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </>
    );
    const tableData =
      this.state.addedRuleSets &&
      this.state.addedRuleSets.length > 0 &&
      this.state.addedRuleSets.map((ele) => (
        <tr>
          <td>{ele.conditionName}</td>
          <td>{ele.rewardName}</td>
        </tr>
      ));
    let endDateHTMLContent =
      this.state.showBtnEndDate === false ? (
        <div className="col-sm-12 inline-flex date-div-space">
          <div className="col-sm-1 top-pad">
            <span className="date-label">Ending</span>
          </div>
          <div className="col-sm-3">
            <input
              type="date"
              className="form-control"
              placeholder="DD /MM /YYYY"
              id="endDate"
              name="endDate"
              min={this.state.curDate}
              value={this.state.scheduleEndDate}
              onChange={this.updateScheduleEndDate}
            />
          </div>
          <div className="col-sm-3 div-left-pad">
            <select
              id="endTimeSelect"
              className="control-label select-type"
              name="endTimeSelect"
              value={this.state.scheduleEndTime}
              onChange={this.updateScheduleEndTime}
            >
              {constants.HOURS &&
                constants.HOURS.map((element, key) => {
                  return (
                    <option key={"endtime_" + key} value={element}>
                      {element}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-sm-5 delete-row-section">
            <span className="delete-row-wrap">
              <button
                className="delete-btn-icon btn"
                onClick={this.removeEndDate}
              >
                <span className="delete-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    aria-labelledby="bd-icon-153"
                    color="primary"
                    className=""
                  >
                    <title id="bd-icon-153">delete</title>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                  </svg>
                </span>
              </button>
            </span>
          </div>
        </div>
      ) : (
        ""
      );

    let limitAvailWeeksDaysHTMLContent =
      this.state.showBtnWeekDays === false ? (
        <div className="col-sm-12 date-div-space">
          <div className="col-sm-12 inline-flex date-div-space">
            <div className="dot-svg">
              <svg
                fill="currentColor"
                height="18"
                stroke="currentColor"
                strokeWidth="0"
                viewBox="0 0 18 18"
                width="18"
                className="sc-bwzfXH gxVJAp"
              >
                <title></title>
                <rect
                  fill="#FFE180"
                  height="8"
                  rx="4"
                  transform="matrix(-1.45705e-08 1 1 1.31134e-07 0 0)"
                  width="8"
                ></rect>
              </svg>
            </div>
            <div>
              <label className="gray-label">Available every</label>
            </div>
            <div className="counter-day">
              <CounterInput
                min={1}
                max={100}
                count={this.state.scheduleAvailablility}
                onCountChange={(count) =>
                  this.updateScheduleAvailablility(count)
                }
              />
            </div>
            <div className="">
              <label className="gray-label">Weeks, on</label>
            </div>
            <div className="col-sm-4">
              {process.browser && (
                <Multiselect
                  options={constants.WEEK_DAY_LIST}
                  selectedValues={this.state.scheduleAvailablilityWeekDay}
                  style={{
                    searchBox: {
                      borderColor: this.state.weeksMandatoryError
                        ? "red"
                        : "lightgray",
                    },
                  }}
                  onSelect={this.onSelect}
                  onRemove={this.onRemove}
                  displayValue="day"
                />
              )}
            </div>
          </div>
          <div className="col-sm-12 inline-flex date-div-space">
            <div className="col-sm-7 inline-flex">
              <div className="dot-svg">
                <svg
                  fill="currentColor"
                  height="18"
                  stroke="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 18 18"
                  width="18"
                  className="sc-bwzfXH gxVJAp"
                >
                  <title></title>
                  <rect
                    fill="#fff"
                    height="8"
                    rx="4"
                    transform="matrix(-1.45705e-08 1 1 1.31134e-07 0 0)"
                    width="8"
                  ></rect>
                </svg>
              </div>
              <div>
                <label className="gray-label">Between</label>
              </div>
              <div>
                <select
                  id="startTimeSelectAvailFrom"
                  className="control-label select-type"
                  name="startTimeSelect"
                  onChange={this.startTimeSelectAvailFrom}
                  value={this.state.scheduleAvailablilityStartTime}
                >
                  {constants.HOURS &&
                    constants.HOURS.map((element, key) => {
                      return (
                        <option
                          key={"starttimeavailfrom_" + key}
                          value={element}
                        >
                          {element}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <label className="gray-label">and</label>
              </div>
              <div>
                <select
                  id="startTimeSelectAvailTo"
                  className="control-label select-type"
                  name="startTimeSelect"
                  onChange={this.startTimeSelectAvailTo}
                  value={this.state.scheduleAvailablilityEndTime}
                >
                  {constants.HOURS &&
                    constants.HOURS.map((element, key) => {
                      return (
                        <option key={"starttimeavailto_" + key} value={element}>
                          {element}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-sm-5 delete-row-section">
              <span className="delete-row-wrap">
                <button
                  className="delete-btn-icon btn"
                  onClick={this.removeLimitAvailWeeksDays}
                >
                  <span className="delete-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      aria-labelledby="bd-icon-153"
                      color="primary"
                      className=""
                    >
                      <title id="bd-icon-153">delete</title>
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      );
    return (
      <div>
        <BlockUi
          tag="form"
          blocking={this.state.blocking}
          loader={<Spinner animation="border" />}
        >
          {modalPop}
          {this.state.showAlert ? (
            <Alert
              type={this.state.type}
              onClosePopup={this.closeAlert}
              message={this.state.alertMessage}
            />
          ) : null}

          <form className="form-horizontal col-sm-12">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="col-sm-6">
                    <h1>{constants.CREATE_PROMOTION_TITLE}</h1>
                  </div>
                </div>
              </div>
              <div className="row body-container">
                {/* summary section */}
                <div className="form-group col-sm-12">
                  <div className="col-sm-12">
                    <h3>Summary</h3>
                  </div>
                  <div className="col-sm-12">
                    <div>
                      <label
                        htmlFor="discountName"
                        className="control-label col-sm-6 adv-form-label"
                      >
                        Name your promotion
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="discountName"
                          name="discountName"
                          value={this.state.promotionName}
                          placeholder="Enter the name for your promotion here"
                          autoComplete="off"
                          onChange={this.updatePromotionName}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="discountName"
                        className="control-label col-sm-2 adv-form-label-bold"
                      >
                        Schedule
                      </label>
                      <div className="col-sm-12 inline-flex date-div-space">
                        <div className="col-sm-1 top-pad">
                          <span className="date-label">Starting</span>
                        </div>
                        <div className="col-sm-3">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="DD /MM /YYYY"
                            id="startDate"
                            name="startDate"
                            min={this.state.curDate}
                            value={this.state.scheduleStartDate}
                            onChange={this.updateScheduleStartDate}
                          />
                        </div>
                        <div className="col-sm-3 div-left-pad">
                          <select
                            id="startTimeSelect"
                            className="control-label select-type"
                            name="startTimeSelect"
                            value={this.state.scheduleStartTime}
                            onChange={this.onScheduleStartTimeChange}
                          >
                            {constants.HOURS &&
                              constants.HOURS.map((element, key) => {
                                return (
                                  <option
                                    key={"starttime_" + key}
                                    value={element}
                                  >
                                    {element}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      {!this.state.showBtnWeekDays &&
                        limitAvailWeeksDaysHTMLContent}
                      {!this.state.showBtnEndDate && endDateHTMLContent}
                    </div>
                    <div className="btn-group-light">
                      <button
                        className={
                          this.state.showBtnEndDate ? "btn btn-light" : "hideme"
                        }
                        onClick={this.addEndDate}
                      >
                        <span className="click-text">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            color="primary"
                            className="base__StyledIcon-a9u0e1-0 hduNwj"
                          >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"></path>
                          </svg>{" "}
                          Add end date and time
                        </span>
                      </button>
                      <button
                        className={
                          this.state.showBtnWeekDays
                            ? "btn btn-light"
                            : "hideme"
                        }
                        onClick={this.addLimitAvailWeeksDays}
                      >
                        <span className="click-text">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            color="primary"
                            className="base__StyledIcon-a9u0e1-0 hduNwj"
                          >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M11 18h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1zm4 6h10c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1z"></path>
                          </svg>{" "}
                          Limit availablity to particular weeks/days
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row body-container">
                <div className="form-group col-sm-12">
                  <div className="col-sm-12">
                    <h3>Coupon Details</h3>
                  </div>
                  <div className="col-sm-12">
                    <div>
                      <label
                        htmlFor="couponQty"
                        className="control-label col-sm-6 adv-form-label"
                      >
                        Coupon Quantity
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="number"
                          className="form-control"
                          id="couponQty"
                          name="couponQty"
                          placeholder="Number of coupons"
                          autoComplete="off"
                          value={this.state.couponQuantity}
                          onChange={this.updateCouponQuantity}
                          min="2"
                          max="999999999"
                        />
                        <span className="prefixSpan">
                          Please enter coupon quantity greater than 1.
                        </span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="prefixCoupon"
                        className="control-label col-sm-6 adv-form-label"
                      >
                        Prefix
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control prefixCoupon"
                          id="prefixCoupon"
                          name="prefixCoupon"
                          placeholder="Coupon prefix"
                          autoComplete="off"
                          value={this.state.couponPrefix}
                          onChange={this.updateCouponPrefix}
                          autoCapitalize
                        />
                        <span className="prefixSpan">
                          Please ensure the Prefix is unique to this promotion.
                        </span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="couponFormat"
                        className="control-label col-sm-6 adv-form-label"
                      >
                        Format
                      </label>
                      <div className="col-sm-3">
                        <select
                          id="couponFormat"
                          className="control-label select-type"
                          name="couponFormat"
                          disabled
                          value={this.state.couponFormat}
                          onChange={this.updateCouponFormat}
                        >
                          {constants.COUPON_FORMAT &&
                            constants.COUPON_FORMAT.map((element, key) => {
                              return (
                                <option
                                  key={"couponFormatFrom_" + key}
                                  value={element}
                                >
                                  {element}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row body-container">
                <div className="form-group col-sm-12">
                  <div className="col-sm-12">
                    <h3>{constants.CREATE_PROMOTION_TARGETING_TITLE}</h3>
                    <span>{constants.CREATE_PROMOTION_TARGETING_SPAN}</span>
                  </div>
                  <div className="col-sm-12" id="targetingDIVPAdding">
                    <span id="customerTarget">
                      {constants.CREATE_PROMOTION_CUSTOMER_TARGET}
                    </span>
                    <ul className="targetList">
                      <li className="targetList">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="input-group targetingCurrency">
                              <span class="input-group-btn inline-display">
                                <span id="currencyIs">
                                  {constants.CREATE_PROMOTION_CURRENCY_TITLE}
                                </span>
                                <select
                                  className="form-select form-control marginTB"
                                  onChange={this.updateTargetingCurrency}
                                  value={this.state.targetingCurrency}
                                >
                                  <option value="USD">US Dollar</option>
                                  {/*<option value="*">
                                    Any Currency
                                  </option>*/}
                                </select>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>

                      {this.state.fields.length > 0 && addTargetRule1}
                      {this.state.fields.length > 1 && addTargetRule2}
                    </ul>
                    {this.state.fields.length <= 1 && (
                      <button
                        type="button"
                        class="btn btn-light"
                        onClick={() => this.handleAddTargeting()}
                      >
                        <span class="addTargetRile">+ Add targeting rule</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="row body-container">
                <div className="form-group col-sm-12">
                  <div className="col-sm-12">
                    <h3>Rules</h3>
                  </div>
                  <div className="col-sm-12">
                    <span>What rewards can customers receive?</span>
                  </div>
                  <div className="col-sm-6">
                    {this.state.addedRuleSets &&
                    this.state.addedRuleSets.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table rules-table">
                          <thead>
                            <th>Condition</th>
                            <th>Reward</th>
                          </thead>
                          <tbody>{tableData}</tbody>
                        </table>
                      </div>
                    ) : (
                      <span className="addRule">
                        No rules have been created yet. Click "Add rule" to get
                        started.
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12">
                    <button
                      type="button"
                      class="btn btn-light"
                      onClick={() => this.handleAddRule()}
                    >
                      <span class="addTargetRile">+ Add Rule</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row body-container last">
                <div className="form-group col-sm-12">
                  <div className="col-sm-12">
                    <h3>Usage limits</h3>
                  </div>
                  <div className="col-sm-12 marginBottom25">
                    <span className="usageLimitSpan">
                      Limit total usage of this promotion
                    </span>
                    <div className="marginLeft10">
                      <div class="custom-control custom-radio">
                        <label class="custom-control-label">
                          <input
                            type="radio"
                            class="custom-control-input radioLabel"
                            disabled
                            value="NoLimit"
                            checked={
                              this.state.totalUsageOfPromotion == "NoLimit"
                            }
                            onChange={this.updateTotalUsageValue}
                          />
                          No limit for total usage of promotion
                        </label>
                      </div>
                      <div class="custom-control custom-radio">
                        <label class="custom-control-label">
                          <input
                            type="radio"
                            class="custom-control-input radioLabel"
                            disabled
                            value="Limit"
                            checked={
                              this.state.totalUsageOfPromotion == "Limit"
                            }
                            onChange={this.updateTotalUsageValue}
                          />
                          Limit for total usage of promotion
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 marginBottom25">
                    <span className="usageLimitSpan">
                      Limit usage of coupon code per customer
                    </span>
                    <div className="marginLeft10">
                      <div class="custom-control custom-radio">
                        <label class="custom-control-label">
                          <input
                            type="radio"
                            class="custom-control-input radioLabel"
                            value="NoLimit"
                            disabled
                            checked={
                              this.state.totalUsageOfCouponCode == "NoLimit"
                            }
                            onChange={this.updateTotalUsageCouponCode}
                          />
                          No usage limit for individual customers
                        </label>
                      </div>
                      <div class="custom-control custom-radio">
                        <label class="custom-control-label">
                          <input
                            type="radio"
                            class="custom-control-input radioLabel"
                            value="Limit"
                            disabled
                            checked={
                              this.state.totalUsageOfCouponCode == "Limit"
                            }
                            onChange={this.updateTotalUsageCouponCode}
                          />
                          Limit usage for individual customers
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <span className="usageLimitSpan">
                      Allow usage with other promotions
                    </span>
                    <div class="form-check">
                      <input
                        class="form-check-input checkboxSize"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={this.onTotalUsageLimitPromotionChange}
                        checked={this.state.usageWithOtherPromotion}
                      />
                      <label
                        class="form-check-label checkboxLabel"
                        for="flexCheckDefault"
                      >
                        This promotion can be used in combination with other
                        promotions
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer">
              <div className="container">
                <div className="field f-right">
                  <div className="field-group">
                    <ul className="field-action">
                      <li>
                        <Link to="/" className="cancel">
                          Cancel
                        </Link>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="btn btn-primary create-btn"
                          name="SubmitButton1"
                          onClick={this.handleCreatePromotion}
                        >
                          Create promotion
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
          </form>
        </BlockUi>
      </div>
    );
  }
}
