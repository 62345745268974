export const API_URL =
  "https://prod-56.eastus2.logic.azure.com/workflows/549c63c1bdb8424c96517a3d27dcdddd/triggers/manual/paths/invoke/";
export const API_VERSION =
  "?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=l_rwUgLCdtMVsT7xQcgAbV7cI6zaeZcvDBZS_luHO3o";
export const PRODUCT_LIST_ENDPOINT_STRING = "/productlist";
export const CATAGORY_LIST_ENDPOINT_STRING = "/categorylist";
export const BRAND_LIST_ENDPOINT_STRING = "/brandlist";
export const ZONE_LIST_ENDPOINT_STRING = "/zonelist";
export const PROMOTION_LIST_ENDPOINT_STRING = "/promotionslist";
export const COUPON_LIST_ENDPOINT_STRING = "/couponslist/";
export const COUPON_COUNT_STRING = "/couponscount/";
export const CREATE_PROMOTION_BUTTON = "Create Promotion";
export const CREATE_PROMOTION_URL =
  "https://prod-10.eastus2.logic.azure.com:443/workflows/25d769ddfea44ef9a564c6cc7b0ad6f5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_hZNHHMajLbPiunSUkYHp6G48fxpMwHvYHMz-gTeUEg";
export const CREATE_COUPON_URL =
  "https://prod-42.eastus2.logic.azure.com:443/workflows/b9958853f107415c96231c5c7d6ec683/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=i1jKIxsrpvqmyZkIoD0adZpktaxdr10Ln2ITXEKRcFs";
export const CURRENCY_SELECT = {
  currency_select: [{ value: "USD", label: "USD - US Dollar", default: true }],
};

export const DISCOUNT_DISPLAY_GROUP = {
  discount_display_group: [
    {
      value: "display-group-shippingdiscount",
      label: "Shipping discount",
      default: true,
      children: [
        {
          value: "rule_buyxgetfreeshipping",
          label:
            "Order at least X units of product Y, get free shipping to specific shipping zones",
          default: true,
        },
        {
          value: "rule_freeshippingwhenoverx",
          label:
            "Orders totalling more than amount, get free shipping to specific shipping zones",
          default: false,
        },
      ],
    },
    {
      value: "display-group-productdiscount",
      label: "Product discount",
      default: true,
      children: [
        {
          value: "rule_buyxgetx",
          label: "Buy one get one free",
          default: true,
        },
        {
          value: "rule_buyxgety",
          label: "Buy one get something else free",
          default: false,
        },
        {
          value: "rule_buyxgetamountoffy",
          label:
            "Buy (X units) of Product A, get (Y units) of Product B for amount or % off per unit",
          default: false,
        },
        {
          value: "rule_amountoffy",
          label: "Apply an amount or % off to Product X",
          default: false,
        },
      ],
    },
    {
      value: "display-group-branddiscount",
      label: "Brand discount",
      default: true,
      children: [
        {
          value: "rule_xoffitemsinybrands",
          label:
            "Apply an amount or % discount to each item in one or more brands",
          default: true,
        },
      ],
    },
    {
      value: "display-group-categorydiscount",
      label: "Category discount",
      default: false,
      children: [
        {
          value: "rule_percentoffitemsincat",
          label:
            "Apply an amount or % discount to each item in one or more categories",
          default: true,
        },
        {
          value: "rule_buyxgetysamecategory",
          label:
            "Buy X units in (Category A), get Y additional units in the same category free (must be of equal or lesser value)",
          default: false,
        },
        {
          value: "rule_xoffitemsinycategoriestiered",
          label:
            "Apply a tiered discount to applicable products based on the quantity of items ordered within one or more categories",
          default: false,
        },
      ],
    },
    {
      value: "display-group-orderdiscount",
      label: "Order discount",
      default: false,
      children: [
        {
          value: "rule_xpercentoffsubtotal",
          label: "Apply a % discount to the order subtotal",
          default: true,
        },
        {
          value: "rule_xamountoffwhenxormore",
          label:
            "Spend at least the specified amount, apply an amount or % discount to the order subtotal",
          default: false,
        },
        {
          value: "rule_ordersofatleastxgety",
          label:
            "Spend at least the specified amount, get one unit of Product Y for an amount or % off",
          default: false,
        },
        {
          value: "rule_ordersofatleastxgetyoffleastormostexpensiveproduct",
          label:
            "Spend at least the specified amount, get Y% off (one or all) unit(s) of the (least or most) expensive item(s) in the cart",
          default: false,
        },
      ],
    },
    {
      value: "display-group-customerdiscount",
      label: "Customer discount",
      default: false,
      children: [
        {
          value: "rule_xoffforrepeatcustomers",
          label: "Discount amount for repeat customers",
          default: true,
        },
        {
          value: "rule_xpercentoffforrepeatcustomers",
          label: "X% discount for repeat customers",
          default: false,
        },
      ],
    },
  ],
};

export const CUSTOMER_GROUP = {
  customer_group: [
    { value: "0", label: "All customers", default: true },
    {
      value: "0",
      label: "Only customers within the following customer groups:",
      default: false,
      message:
        "You have not created a Customer Group for guest shoppers (i.e. visitors browsing your store who are not logged in). As such, by default, guest shoppers will not receive this discount. If you do want to provide discounts to guest shoppers, please create a Guest Shoppers customer group first. Then you can select this customer group to receive this discount.",
    },
  ],
};

export const BANNER_APPLIED_LOCATION = {
  banner_applied_location: [
    {
      value: "CART_PAGE",
      label: "Show this message on cart page",
      default: true,
    },
  ],
};

export const BANNER_ELIGIBLE_LOCATION = {
  banner_eligible_location: [
    {
      value: "CART_PAGE",
      label: "Show this message on cart page",
      default: true,
    },
  ],
};

export const BANNER_UPSELL_LOCATION = {
  banner_upsell_location: [
    {
      value: "HOME_PAGE",
      label: "Show this message on home page",
      default: false,
    },
    {
      value: "PRODUCT_PAGE",
      label: "Show this message on product page",
      default: false,
    },
    {
      value: "CART_PAGE",
      label: "Show this message on cart page",
      default: true,
    },
    {
      value: "CHECKOUT_PAGE",
      label: "Show this message on cart page",
      default: false,
    },
  ],
};

export const BANNER_PROMOTION_LOCATION = {
  banner_promotion_location: [
    {
      value: "HOME_PAGE",
      label: "Show this message on home page",
      default: false,
    },
    {
      value: "PRODUCT_PAGE",
      label: "Show this message on product page",
      default: false,
    },
    {
      value: "CART_PAGE",
      label: "Show this message on cart page",
      default: true,
    },
    {
      value: "CHECKOUT_PAGE",
      label: "Show this message on cart page",
      default: false,
    },
  ],
};

export const CREATE_AUTO_PROMOTION_TITLE = "Create Automatic Promotion";
export const CREATE_PROMOTION_SUBTITLE = "Promotion Details";
export const CREATE_PROMOTION_TITLE = "Create Promotion";
export const CREATE_PROMOTION_TARGETING_TITLE = "Targeting";
export const CREATE_PROMOTION_TARGETING_SPAN =
  "Determine which customers have access to this promotion. By default, includes all customers.";
export const CREATE_PROMOTION_CUSTOMER_TARGET = "Target customers if...";
export const CREATE_PROMOTION_CURRENCY_TITLE = "Currency is";
export const HOURS = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
  "11:59 PM",
];
export const WEEK_DAY_LIST = [
  { day: "Monday" },
  { day: "Tuesday" },
  { day: "Wednesday" },
  { day: "Thursday" },
  { day: "Friday" },
  { day: "Saturday" },
  { day: "Sunday" },
];

export const COUPON_FORMAT = ["Alphanumeric", "Numeric"];
export const IF_THE_CUSTOMER = [
  { label: "Buys Products", value: "buys_products" },
  { label: "Reaches an order sub-total", value: "reaches_an_order_sub_total" },
  { label: "No conditions", value: "no_conditions" },
];
export const THEN_REWARD = [
  { label: "A gift in their cart", value: "a_gift_in_their_cart" },
  { label: "Free Shipping", value: "free_shipping" },
  { label: "Discount on products", value: "discount_on_products" },
  { label: "Discount on order subtotal", value: "discount_on_order_subtotal" },
  {
    label: "Fixed price for # of products",
    value: "fixed_price_for_of_products",
  },
];

export const PROMOTION_NAME_TRIM_CHAR = 20;

let url = (window.location && window.location.ancestorOrigins )? window.location.ancestorOrigins[0] : "";
if(navigator.userAgent.indexOf("Firefox") != -1)
  url = (window.location != window.parent.location)? document.referrer : document.location.href;
const hash = url.includes("store-")? url.split("store-")[1].split(".mybigcommerce.com")[0] : "";
export const STROE_HASH = hash;