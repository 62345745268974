import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ApiServices from "../../services/promotion-service";

export default class AllCountryModalPopup extends React.Component {
  async componentDidMount() {
    if (this.props.options.length < 1) {
      this.props.loadCountryList();
    }
    $("#searchKey").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#categoryTable tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  }

  render() {
    const selectCategory =
      this.props.options.length > 0 ? (
        <ul className="selected-lebel">
          {this.props.selectedCountries.length > 0 &&
            this.props.selectedCountries.map((item, index) => (
              <li className="li-item" key={index}>
                {item.name}
              </li>
            ))}
        </ul>
      ) : (
        ""
      );

    const searchIcon = (
      <FontAwesomeIcon
        className="form-control-feedback"
        icon={faSearch}
        color="blue"
      />
    );

    const loader = this.props.loader ? (
      <div className="loader-wraper">
        <span className="spinner-border spinner-pos spinner-border-lg"></span>
      </div>
    ) : (
      ""
    );
    
    const categoryListTbody =
      this.props.options.length > 0 ? (
        <tbody id="categoryTable">
          {this.props.options.map((item, index) => (
            <tr key={index}>
              <td className="width-10">
                <input
                  type="checkbox"
                  key={index}
                  // value={item.name}
                  value={
                    this.props.selectedCountries.length &&
                    this.props.selectedCountries.some(
                      (ele) => ele.name === item.name
                    )
                      ? item.name
                      : ""
                  }
                  checked={
                    this.props.selectedCountries.length &&
                    this.props.selectedCountries.some(
                      (ele) => ele.name === item.name
                    )
                  }
                  onChange={(event) => this.props.checkCategory(event, item)}
                />
              </td>
              <td className="width-50">{item.name}</td>
            </tr>
          ))}
        </tbody>
      ) : (
        ""
      );

    return (
      <div
        id="allCategoryModalPopup"
        className={this.props.showModal ? "modal fade show" : "modal fade hide"}
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content all-category-alert-model-content">
            <div className="modal-body all-category-alert-model-body">
              <h3>Select Countries</h3>
              <div className="all-category-alert-model-text">
                {selectCategory}
                <div className="col-md-12 form-group has-search div-left-pad div-right-pad indivisual-search-box">
                  {searchIcon}
                  <input
                    type="text"
                    name="searchKey"
                    id="searchKey"
                    className="form-control"
                    placeholder="Search by Country name"
                    autoComplete="off"
                  />

                  <table className="table width-100">
                    <thead>
                      <tr>
                        <th className="width-10"></th>
                        <th className="width-50">Name</th>
                      </tr>
                    </thead>
                    {categoryListTbody}
                  </table>
                </div>
                {loader}
              </div>
              <div className="model-myfooter">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.props.yesClick}
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary custom-btn-width"
                  onClick={this.props.noClick}
                  data-dismiss="modal"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AllCountryModalPopup.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  noClick: PropTypes.func,
  yesClick: PropTypes.func,
  item: PropTypes.array,
  options: PropTypes.array,
  selectedCountries: PropTypes.array,
};
